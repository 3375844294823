import { useContext } from 'react';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';

const resource = '/users';

/**
 * Hook to access all 'users' resources.
 */
export default function useUsersService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /users/:id/agendas request.
     * @param id User ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getUsersAgendas<T>(id: number, params: Record<string, unknown>) {
        return request<T>('GET', `${resource}/${id}/agendas`, params);
    }

    return {
        ...services,
        getUsersAgendas,
    };
}
