import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import DeleteIcon from '@material-ui/icons/Delete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AppContext, SttTable, SttFormSelect, SttButton } from '../all';
import useGroupsService from '../../services/GroupsService';
import { FAMILY_BENEFICIARIES, GROUP_MEMBERS } from '../../lib/queryKeys';
import { SttSelectUser } from '../selectors/SttSelectUser';

/**
 *	Beneficiaries lists
 *	============
 *  List of all the beneficiaries
 *	Props:
 *  member owner of the abono
 *
 */

const useStyles = makeStyles({
    deleteButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function SttBeneficiariesList({ member }) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const groupsService = useGroupsService();
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [user, setUser] = useState(null);
    const [relation, setRelation] = useState('OFFSPRING');
    const classes = useStyles();

    const beneficiariesQuery = useQuery([FAMILY_BENEFICIARIES], () =>
        groupsService
            .getBeneficiaries(member.id)
            .then((response) => response.rows),
    );

    const addBeneficiary = useMutation(() =>
        groupsService
            .addBeneficiary(member.id, { idUser: user.id, relation })
            .then((response) => {
                setBeneficiaries((beneficiarios) => [
                    ...beneficiarios,
                    response.groupMember,
                ]);
                queryClient.invalidateQueries(GROUP_MEMBERS);
            })
            .catch(({ error }) => {
                if (error) {
                    cxt.showMessage('E', error?.msg);
                }
            }),
    );

    const deleteBeneficiary = useMutation((beneficiaryId) =>
        groupsService
            .deleteBeneficiary(beneficiaryId)
            .then(() => {
                setBeneficiaries(
                    beneficiaries.filter(
                        (element) => element.id !== beneficiaryId,
                    ),
                );
                queryClient.invalidateQueries(GROUP_MEMBERS);
            })
            .catch(({ error }) => {
                if (error) {
                    cxt.showMessage('E', error?.msg);
                }
            }),
    );

    useEffect(() => {
        if (beneficiariesQuery.status === 'success') {
            setBeneficiaries(beneficiariesQuery.data);
            beneficiariesQuery.data = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiariesQuery.data]);

    function addUserToBeneficiariesList() {
        if (user) {
            addBeneficiary.mutate();
            setUser(null);
        }
    }

    // -----| Render |-----
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <SttSelectUser
                        controlDisabled={false}
                        name="user"
                        caption={cxt.t('Name')}
                        user={user}
                        onSelect={(usuario) => setUser(usuario)}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SttFormSelect
                        name="Relacion"
                        caption={cxt.t('relation')}
                        defVal={relation}
                        options={[
                            {
                                caption: cxt.t('offspring'),
                                value: 'OFFSPRING',
                            },
                            {
                                caption: cxt.t('partner'),
                                value: 'PARTNER',
                            },
                            {
                                caption: cxt.t('groups.beneficiaries.other'),
                                value: 'OTHER',
                            },
                        ]}
                        onChange={(select) => setRelation(select.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SttButton
                        variant="contained"
                        color="primary"
                        caption={cxt.t('Add')}
                        onClick={() => addUserToBeneficiariesList()}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SttTable
                    data={{ rows: beneficiaries }}
                    autoload={false}
                    localPagination
                    perPage={20}
                    columns={[
                        {
                            title: cxt.t('User'),
                            name: 'user',
                            type: 'user',
                            value: (row) => row.name,
                        },
                        {
                            title: cxt.t('Created'),
                            name: 'createdOn',
                            type: 'dmyhm',
                            value: (row) => row.ini,
                        },
                        {
                            title: cxt.t('Relation'),
                            name: 'relation',
                            type: 'text',
                            value: (row) => cxt.t(row.holderRelation),
                        },
                        {
                            title: '',
                            value: (row) => (
                                <DeleteIcon
                                    className={classes.deleteButton}
                                    onClick={() => {
                                        deleteBeneficiary.mutate(row.id);
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </Grid>
        </>
    );
}
