import makeStyles from '@material-ui/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

type ConsumptionProps = {
    caption: string;
    amount: number;
    consumed: number;
};

export default function Consumption({
    caption,
    amount,
    consumed,
}: ConsumptionProps) {
    const useStyles = makeStyles(() => ({
        container: {
            width: '100%',
            height: 24,
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'row-reverse',
        },
        item: {
            marginLeft: 2,
            flex: 1,
        },
        first: {
            margin: 0,
        },
        consumed: {
            backgroundColor: '#c2c2d6',
        },
        available: {
            backgroundColor: '#7AB97A',
        },
    }));

    const classes = useStyles();

    const items = [];
    for (let i = 0; i < amount; i++) {
        items.push(
            <Box
                key={i}
                className={clsx({
                    [classes.item]: true,
                    [classes.first]: i === amount - 1, // 'first' en realidad es el último elemento de la iteración, ya que los estamos pintando en 'row-reverse'
                    [classes.consumed]: i < consumed,
                    [classes.available]: i >= consumed,
                })}
            />,
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {caption && (
                <Box mb={1}>
                    <Typography variant="body2">{caption}</Typography>
                </Box>
            )}
            <Box className={classes.container}>{items}</Box>
        </div>
    );
}
