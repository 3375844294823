import React, { useEffect, useContext, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useCrud } from '../../../lib/hooks';
import {
    AppContext,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonSave,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttLabelForm,
    SttSelectFacility,
    SttSelectUser,
    SttBookingScheduleSlotList,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';
import BookingScheduleSlotDialog from './BookingScheduleSlotDialog';
import BookingScheduleDeleteDialog from './BookingScheduleDeleteDialog';

/**
 * BookingSchedule : Alquiler fijo
 * Object match : navigation params match
 */
export default function BookingScheduleDetails({
    id,
    onSaveSlot,
    onSaveBookingSchedule,
    bookingScheduleInit,
}) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [
        bookingSchedule,
        setBookingSchedule,
        setProperty,
        Get,
        Post,
        Put,
        Delete,
    ] = useCrud(bookingScheduleInit);
    const [slots, setSlots] = useState([]);
    const [viewSlot, setViewSlot] = useState(null);
    const [savingSlots, setSavingSlots] = useState(false);
    const [openDeleteResponseDialog, setOpenDeleteResponseDialog] =
        useState(false);

    // Load schedule slots
    function loadSlots() {
        Get(`/bookings/schedules/${id}/slots`).then((response) => {
            if (response.rows) {
                setSlots(response.rows);
            }
        });
    }

    useEffect(() => {
        setBookingSchedule(bookingScheduleInit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingScheduleInit?.id]);

    // Initialize (load by id or create)
    useEffect(() => {
        if (id === 'create') {
            setBookingSchedule({
                name: '',
                dateini: moment().format('YYYY-MM-DD'),
                dateend: moment().format('YYYY-12-31'),
                status: 'ACTIVE',
            });
            cxt.setHeader({
                title: cxt.t('newBookingSchedule'),
                urlBack: getPath('bookingSchedules'),
            });
        } else {
            loadSlots();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Save. Put if 'id' is defined, POST otherwise.
    function saveBookingSchedule() {
        if (bookingSchedule.id) {
            Put(`/bookings/schedules/${bookingSchedule.id}`, {
                status: bookingSchedule?.status,
                name: bookingSchedule?.name,
            }).then((response) => {
                if (response.bookingSchedule) {
                    onSaveBookingSchedule(response.bookingSchedule);
                }
            });
        } else {
            const params = {
                name: bookingSchedule.name,
                idUser: bookingSchedule.user?.id,
                idFacility: bookingSchedule.facility?.id,
                dateini: bookingSchedule.dateini,
                dateend: bookingSchedule.dateend,
                weekdays: bookingSchedule.weekdays,
                status: bookingSchedule.status || 'ACTIVE',
            };

            Post(`/scs/${cxt.sc.id}/bookings/schedules`, params).then(
                (response) => {
                    if (response.bookingSchedule) {
                        onSaveBookingSchedule(response.bookingSchedule);
                    }
                },
            );
        }
    }

    // Delete with API
    function deleteBookingSchedule(userResponse) {
        Delete(`/bookings/schedules/${bookingSchedule.id}`, {
            deleteAllBookings: userResponse,
        }).then(() => {
            history.replace(getPath('bookingSchedules'));
        });
    }

    // Recover with API
    function recoverBookingSchedule() {
        Put(`/bookings/schedules/${bookingSchedule.id}/recover`).then(() => {
            setBookingSchedule({
                ...bookingSchedule,
                trash: 0,
            });
        });
    }

    if (!bookingSchedule) {
        return false;
    }

    // Save Slot: POST only since these can't be edited
    function saveSlot(slot) {
        if (slot.id) {
            return;
        }

        setSavingSlots(true);

        cxt.api('POST', `/bookings/schedules/${bookingSchedule.id}/slots`, {
            params: {
                ...slot,
            },
            success: (response) => {
                if (response.bookingScheduleSlot) {
                    setSlots([...slots, response.bookingScheduleSlot]);
                    if (onSaveSlot) onSaveSlot();
                }

                if (response.bookingScheduleSlot?.count > 0) {
                    cxt.showMessage('S', cxt.t(`Created`));
                }

                setViewSlot(null);

                setSavingSlots(false);
            },
            error: (response) => {
                if (response.error) {
                    cxt.showMessage('E', response.error.msg);
                }
                setSavingSlots(false);
            },
        });
    }

    // Delete slot
    function deleteSlot(slot) {
        if (!slot.id) {
            return;
        }

        Delete(`/bookings/schedules/slots/${slot.id}`).then((response) => {
            if (response.bookingScheduleSlot) {
                setSlots(slots.filter((slt) => slt.id !== slot.id));
            }
            setViewSlot(null);
        });
    }

    return (
        <>
            <Grid container spacing={3}>
                {bookingSchedule.id && (
                    <Grid item xs={12}>
                        <SttLabelForm
                            title={cxt.t('Created')}
                            text={moment(bookingSchedule.created).format(
                                'DD MMMM YYYY',
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SttFormText
                        name="name"
                        caption={cxt.t('Name')}
                        defVal={bookingSchedule.name}
                        onChange={setProperty}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SttFormSelect
                        name="status"
                        caption={cxt.t('Status')}
                        defVal={bookingSchedule.status}
                        options={cxt.constants.scheduleStatuses}
                        onChange={setProperty}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SttFormDate
                        disabled={!!bookingSchedule.id}
                        caption={cxt.t('dateIni')}
                        name="dateini"
                        defVal={bookingSchedule.dateini}
                        onChange={setProperty}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SttFormDate
                        disabled={!!bookingSchedule.id}
                        caption={cxt.t('dateEnd')}
                        name="dateend"
                        defVal={bookingSchedule.dateend}
                        onChange={setProperty}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SttSelectUser
                        controlDisabled={!!bookingSchedule.id}
                        caption={cxt.t('User')}
                        name="user"
                        user={bookingSchedule.user}
                        onSelect={(mship) => {
                            setProperty({
                                name: 'user',
                                value: {
                                    fullName: mship.fullName,
                                    id: mship.user.id,
                                },
                            });
                        }}
                        returnMship
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SttSelectFacility
                        disabled={!!bookingSchedule.id}
                        name="facility"
                        caption={cxt.t('Facility')}
                        defVal={
                            bookingSchedule.facility &&
                            bookingSchedule.facility.id
                        }
                        onChange={(facility) => {
                            setProperty({ name: 'facility', value: facility });
                        }}
                    />
                </Grid>
                {bookingSchedule.id && (
                    <Grid item xs={12}>
                        <SttBookingScheduleSlotList
                            slots={slots}
                            onAdd={() => {
                                setViewSlot({
                                    weekdays: [],
                                });
                            }}
                            onSelect={setViewSlot}
                        />
                    </Grid>
                )}
            </Grid>
            <Box mt={3}>
                <Grid mt={3} container spacing={3}>
                    {!bookingSchedule.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonSave
                                fullWidth
                                onClick={saveBookingSchedule}
                            />
                        </Grid>
                    )}

                    {!bookingSchedule.id && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonCancel
                                fullWidth
                                onClick={history.goBack}
                            />
                        </Grid>
                    )}

                    {bookingSchedule.id && !bookingSchedule.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonDelete
                                fullWidth
                                onClick={() => {
                                    setOpenDeleteResponseDialog(true);
                                }}
                            />
                        </Grid>
                    )}

                    {bookingSchedule.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonRecover
                                fullWidth
                                onClick={recoverBookingSchedule}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <BookingScheduleSlotDialog
                item={viewSlot}
                onSave={saveSlot}
                onDelete={deleteSlot}
                onClose={() => {
                    setViewSlot(null);
                }}
                loading={savingSlots}
            />
            <BookingScheduleDeleteDialog
                open={openDeleteResponseDialog}
                onClose={() => {
                    setOpenDeleteResponseDialog(false);
                }}
                onResponse={(userResponse) => {
                    deleteBookingSchedule(userResponse);
                }}
            />
        </>
    );
}
