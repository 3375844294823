const translations = {
    generic: {
        discounts: 'discounts',
        reject: 'reject',
        accept: 'accept',
        name: 'name',
        text: 'text',
        textShort: 'textShort',
        saved: 'saved',
        deleted: 'deleted',
        upload: 'upload',
        consumed: 'consumed',
        email: 'email',
        mobile: 'mobile',
        address: 'address',
        postalCode: 'postalCode',
        city: 'city',
        via: 'via',
        street: 'vt.street',
        avenue: 'vt.avenue',
        square: 'vt.square',
        buildingType: 'buildingType',
        flat: 'bt.flat',
        house: 'bt.house',
        block: 'bt.block',
        number: 'number',
        letter: 'letter',
        notes: 'notes',
        tutor: 'tutor',
        birthday: 'birthday',
        dni: 'dni',
        passport: 'passport',
        nie: 'nie',
        cif: 'CIF',
        certified: 'certified',
        pupils: 'pupils',
        surname1: 'surname1',
        surname2: 'surname2',
        identificationType: 'identificationType',
        recovered: 'recovered',
        recover: 'recover',
        detail: 'detail',
        purse: 'purse',
        bookings: 'bookings',
        privileges: 'privileges',
        documents: 'documents',
        ticket: 'ticket',
        invoice: 'invoice',
        collect: 'collect',
        delete: 'delete',
        children: 'children',
        openParent: 'openParent',
        refund: 'refund',
        error: 'error',
        download: 'download',
        user: 'user',
        collector: 'collector',
        concept: 'concept',
        price: 'price',
        period: 'period',
        receipts: 'receipts',
        created: 'created',
        years: 'years',
        total: 'total',
        amount: 'amount',
        print: 'print',
        nameAndDescription: 'nameAndDescription',
        type: 'type',
        completed: 'completed',
        individual: 'individual',
        duration: 'duration',
        position: 'position',
        bank: 'bank',
        all: 'all',
        mode: 'mode',
        history: 'history',
        inscription: 'inscription',
        free: 'free',
        mandatory: 'mandatory',
        fee: 'fee',
        noFees: 'noFees',
        signMeUp: 'signMeUp',
        added: 'added',
        users: 'users',
        fees: 'fees',
        devices: 'devices',
        maxUsers: 'maxUsers',
        beneficiaries: 'beneficiaries',
        parentGroup: 'parentGroup',
        description: 'description',
        modify: 'modify',
        date: 'date',
        generateFeesEvery: 'generateFeesEvery',
        feePrice: 'feePrice',
        anyGroup: 'anyGroup',
        mandatoryGroup: 'mandatoryGroup',
        search: 'search',
        expiration: 'expiration',
        active: 'active',
        inactive: 'inactive',
        year: 'year',
        creator: 'creator',
        facilities: 'facilities',
        periods: 'periods',
        status: 'status',
        lastVisit: 'app.account.lastVisit',
        rates: 'rates',
        rate: 'rate',
        newRate: 'newRate',
        createRate: 'createRate',
        booking: 'booking',
        events: 'events',
        activities: 'activities',
        consents: 'consents',
        unlockers: 'unlockers',
        sports: 'sports',
        ticketClosed: 'ticketClosed',
        bono: 'bono',
        bonos: 'bonos',
        group: 'group',
        groups: 'groups',
        assistences: 'assistences',
        month: 'month',
        participant: 'participant',
        form: 'form',
        OperationSuccessful: 'OperationSuccessful',
        student: 'student',
        paymentForm: 'paymentForm',
        refresh: 'refresh',
        loading: 'loading',
        iHaveReadAndAccept: 'generic.IHaveReadAndAccept',
        youMustAcceptPrivacyPolicy: 'generic.youMustAcceptPrivacyPolicies',
        privacyPolicy: 'privacy.title',
        current: 'InForce',
        signedUsers: 'signedUsers',
        select: 'Select',
        generateUnlockers: 'generateUnlockers',
        noUnlockers: 'noUnlockers',
    },
    deviceSpace: {
        maximunCapacity: 'maximunCapacity',
        currentCapacity: 'currentCapacity',
        devicesCapacityMonitor: 'deviceCapacityMonitor',
    },
    group: {
        discountDeleted: 'web.groups.discountDeleted',
        discountAdded: 'web.groups.discountAdded',
        typeNormal: 'GT.NORMAL',
        typeAbonados: 'GT.ABONADOS',
        typeSport: 'GT.SPORT',
        typeFamily: 'GT.FAMILY',
    },
    country: {
        anyCountry: 'front.chooseCountry',
    },
    mship: {
        ibanEmptyOrValid: 'webapp.ibanEmptyOrValid',
        webcamCapture: 'page.user.webcam.capture',
        mshipDeleted: 'popup.mship.deleted',
    },
    payment: {
        payment: 'payment',
        payments: 'payments',
        paymentFormCash: 'PF.CASH',
        paymentFormPurse: 'PF.PURSE',
        paymentFormTpv: 'PF.TPV',
        paymentFormLater: 'PF.LATER',
        paymentFormDataphone: 'PF.DATAFONO',
        paymentFormDataphoneConnected: 'PF.DATAFONO_CONNECTED',
        paymentFormBank: 'PF.BANK',
        paymentFormFree: 'PF.FREE',
        paymentFormReceipt: 'PF.RECEIPT',
        paymentFormZitycard: 'PF.ZITYCARD',
        statusPaid: 'PAID',
        deleted: 'popup.payment.deleted',
        refunded: 'popup.payment.refunded',
        isChild: 'popup.payment.isChild',
        paymentForm: 'paymentForm',
        payWithTpv: 'payWithTpv',
        payWithPurse: 'payWithPurse',
        payWithZitycard: 'payWithZitycard',
    },
    module: {
        renting: 'M.RENTING',
        event: 'M.EVENT',
        class: 'M.CLASS',
        game: 'M.GAME',
        abonados: 'M.ABONADOS',
        purse: 'M.PURSE',
    },
    sporttiaControlPanel: {
        title: 'web.sporttiaControlPanel',
    },
    report: {
        updated: 'reportUpdated',
    },
    activity: {
        uploadDocuments: 'uploadActivityDocuments',
        currentNameToChange: 'currentNameToChange',
        duplicateActivities: 'duplicateActivities',
        newActivityName: 'newActivityName',
        inscriptionFrom: 'activityInscriptionFrom',
        inscriptionUntil: 'activityInscriptionUntil',
        durationFrom: 'activityDurationFrom',
        durationUntil: 'activityDurationUntil',
        selectActivityPoster: 'selectActivityPoster',
    },
    tpv: {
        errorPurseRefund: 'tpvErrorPurseRefund',
    },
    teams: {
        teamName: 'teamName',
    },
    unlockers: {
        QR: 'QR',
        PIN: 'PIN',
    },
    bono: {
        refund: 'bono.refund',
        consumption: 'bono.consumption',
        movement: 'bono.movement',
    },
} as const;

export default translations;
