import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { AppContext, SttTable } from '../../../sporttia/all';
import SalePoint from '../salePoint/SalePoint';

export default function SCTickets() {
    const cxt = useContext(AppContext);
    const [tickets, setTickets] = useState();
    const [openedTicket, setOpenedTicket] = useState(null);

    /**
     * Load tickets
     */
    const loadTickets = (p = { page: 1 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/products/tickets`, {
            params: {
                page: p.page,
                rows: 20,
            },
            success: (r) => {
                setTickets(r);

                cxt.setHeader({
                    title: cxt.t('Tickets'),
                });
            },
        });
    };

    /**
     * SC changes
     */
    useEffect(() => {
        if (cxt.sc.id) loadTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.sc.id]);

    // Render
    return (
        <Paper>
            <SttTable
                data={tickets}
                autoload={false}
                onClickRow={setOpenedTicket}
                columns={[
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        type: 'dmy',
                    },
                    {
                        title: cxt.t('Status'),
                        field: 'status',
                        type: 'binaryStatus',
                        value: (row) => row.status === 'OPEN',
                    },
                    {
                        title: cxt.t('Name'),
                        field: 'name',
                        align: 'left',
                        value: (row) => row.user && row.user.name,
                    },
                    {
                        align: 'right',
                        title: cxt.t('Total'),
                        field: 'totalPaid',
                        type: 'price',
                    },
                ]}
                onFetch={loadTickets}
            />

            <SalePoint
                open={openedTicket != null}
                idTicket={openedTicket && openedTicket.id}
                onClose={() => {
                    setOpenedTicket();
                    loadTickets();
                }}
            />
        </Paper>
    );
}
