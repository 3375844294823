import React from 'react';
import { Grid, GridProps, TextField, TextFieldProps } from '@material-ui/core';

type SttFormTextareaProps = {
    grid?: boolean;
    caption: string;
    onChange?: (event: { name: string; value: string }) => void;
    defVal?: string;
    name: string;
    rows?: number;
    placeholder?: boolean;
} & Omit<TextFieldProps, 'placeholder' | 'onChange'> &
    Omit<GridProps, 'placeholder' | 'onChange'>;

export function SttFormTextarea({
    xs = 12,
    grid = false,
    caption,
    onChange,
    defVal,
    name,
    rows,
    placeholder = false,
    ...rest
}: SttFormTextareaProps) {
    const content = (
        <TextField
            fullWidth
            multiline
            rows={rows || 3}
            variant="outlined"
            label={!placeholder ? caption : undefined}
            size="small"
            value={defVal || ''}
            onChange={(e) => onChange?.({ name, value: e.target.value })}
            inputProps={placeholder ? { placeholder: caption } : {}}
            {...rest}
        />
    );

    return grid ? (
        <Grid item xs={xs} {...rest}>
            {content}
        </Grid>
    ) : (
        content
    );
}
