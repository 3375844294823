import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
    Button,
    Avatar,
    Toolbar,
    Grid,
    IconButton,
    Checkbox,
    Chip,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    Check as CheckIcon,
    CheckCircle,
    CheckCircleOutline,
    Cancel,
    Close,
    Add,
    Remove,
    HighlightOff,
    SubdirectoryArrowRight,
} from '@material-ui/icons';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useTheme } from '@material-ui/styles';
import {
    updateElementInArray,
    deleteElementFromArray,
    commaSeparated,
    formatPriceByLocale,
} from '../lib/utils';
import {
    AppContext,
    SttCell,
    SttChipPForm,
    SttChipModule,
    SttLabelPeriod,
    SttFormSearch,
    SttLabelDuration,
    SttMenu,
    SttLabelWeekdays,
} from './all';
/**
 *	TABLE
 *	=====
 *
 * Flexible table to display data rows with a variety of field types and additional mechanics.
 *
 * Props:
 *  * bool autoload: true to have the component call its onFetch function automatically
 *  * array columns: array of objects to specify the columns. Each object's 'type' should match one of the supported types.
 *      By default these objects that the form {caption, field, type, value}. NOTE: objects in the columns prop can contain other, type-specific, properties.
 *
 *		* caption: the heading of the column
 *		* field: the row field to get the data from. Optional if 'value' is provided
 *		* value: Optional callback that takes the row as parameter and returns a value to render in the correspondig cell.
 *		* type: Optional column type. If none is provided, the raw value will be used. Allowed types:
 *
 *			* id
 *			* dmy: dd-mm-yyyy date, {checkOld} to print the date red if it's before today
 *			* dmyhm: dd-mm-yyyy hh:mm date, {checkOld} to print the date red if it's before today
 *			* dmyRange dd-mm-yyyy - dd-mm-yyyy date
 *			* hm: hh:mm time
 *			* hmRange hh:mm - hh:mm time
 *			* price: either flat value or {price, paid} tuple. If {price, paid} is provided, it'll render black or red depending on 'paid'
 *			* debt: renders red if above 0, black otherwise
 *			* rate: renders {price, duration} like [duration]m / [price] €
 *			* period: {ini, end} both are dates. Uses SttLabelPeriod, meaning it will show red if older than today
 *			* periodPlain: {ini, end}. Doesn't care about today's date
 *			* periodTime: Similar to periodPlain, but for hours
 *			* link: renders typical link text, accepts column.{to, onClick}, as href and callback
 *			* active: check icon if true
 *			* pf: any of PURSE, DATAFONO, CASH, TPV, BANK or FREE
 *			* counter: plain value, just with specific width and alignment
 *			* avatar: {src} for the image url
 *			* module: any of RENTING, TOURNAMENT, EVENT, GAME, CLASS, SALE_POINT, PURSE, ABONADOS, BONO
 *			* enrollment: {enrollment, fee, interval}, accepts enrollment, fee + interval, or enrollment and fee + interval
 *			* nonZero: a non-zero value or nothing at all
 *			* weekdays: array of days of the week. 0 = Sunday.
 *			* duration: turn kind of 0000-01-00 into "One month"
 *
 *  * Object data: object like {rows, count}
 *  * Function onFetch: provided loader function
 *  * bool loading: activate or not the load spinner.
 *  * Function onClickRow: if provided, entire rows will be clickable and call this function passing the row to it
 *  * bool childrenEnabled:
 *  * Object totals - object in the shape {column:'colName'} that contain 'total' values for a certain column. Ignored if undefined.
 *  * bool hidePagination - Hide pagination components (rare). Defaults to false.
 *
 *  * object selection: if it's set we can select more than one row:
 *  	* array actions:
 *  		- string caption
 *  		- node icon
 *  		- function onClick
 *	* array selectRows: array with the objects to be selected.
 *
 *  * bool forceUpdateSwitch: if this prop changes, we must re-render the table regardless of content. Needed for some fringe cases.
 *  * bool localPagination: if true, all rows will be loaded (1,000,000 precisely) and then paginated locally, requires perPage > 0
 *  * int perPage: can be used to forcefully establish how may rows per page are displayed, mandatory for localPagination to work
 *  * function onChangePagination: funcion para mantener un registro de la pagina actual en el componente padre.
 */

/*
TODO: Este componente se repinta innecesariamente varias veces por ciclo. Eso debe corregirse en algún momento.
Problema:
1. Si cambia el prop 'data' el componente se repinta.
2. Al cambiar 'data', cambia el state 'internalData' (usado para la lógica de visibilidad de los hijos), así que se repinta de nuevo
3. Al cambiar el state 'page' el componente se repinta.
*/

// TODO: Este componente debería aceptar un handler externo para la paginación (para poder llevar cuenta desde fuera y, por ejemplo, poder recargar los datos manteniendo la página actual)

const useStyles = makeStyles((theme) => ({
    root: { flexShrink: 0, marginLeft: theme.spacing(2.5) },
    showHideWrap: {
        width: 24,
        height: 24,
        marginLeft: 8,
        position: 'relative',
    },
    showHideIcon: {
        width: 18,
        height: 18,
        fontSize: 16,
        color: '#7b7b7b',
        position: 'absolute',
        left: 0,
        top: 4,
    },
    subRowIcon: { marginRight: 8, fontSize: 16, color: '#7b7b7b' },
}));

/*
 * Note: for some internal state updates, SttTable now uses the internalData value which is just copied from the data prop.
 * */
function SttTable({
    autoload = true,
    columns,
    data,
    onFetch,
    onChangePagination = null,
    loading = false,
    loadingValue,
    onClickRow,
    childrenEnabled = false,
    totals,
    hidePagination = false,
    selection,
    selectRows,
    setSelectRows,
    forceUpdateSwitch,
    localPagination = false,
    perPage = 20,
}) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [internalData, setInternalData] = useState(data);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAllRows, setSelectedAllRows] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const hasIdColumn = columns && columns[0] && columns[0].title === '#';
    // Init. Run autoload onFetch if necessary
    useEffect(() => {
        if (autoload && onFetch) {
            if (localPagination) {
                onFetch({ page: 1, rows: 1000000 });
            } else {
                onFetch();
            }
        }
    }, []);

    useEffect(() => {
        if (loadingValue === true || loadingValue === false) {
            setLoadingData(loadingValue);
        }
    }, [loadingValue]);

    /**
     * Input data changes (count,rows)
     */
    useEffect(() => {
        if (data && data.rows) {
            if (loading) {
                setLoadingData(false);
            }

            setChildVisibility(data.rows, null, false);
            setInternalData(data);

            // """auto-detect""" rows per page if it wasn't specified
            if (rowsPerPage === 0) {
                setRowsPerPage(data.rows.length);
            }
        }
    }, [data]);

    /**
     * Select rows data changes.
     */
    useEffect(() => {
        if (selectRows) setSelectedRows(selectRows);
    }, [selectRows]);

    useEffect(() => {
        if (setSelectRows) setSelectRows(selectedRows);
    }, [selectedRows]);

    // Set child visibility on all data rows
    function setVisibility(row, visibility) {
        const { rows } = internalData;

        setChildVisibility(rows, row, visibility);

        setInternalData({ ...internalData, rows });
    }

    // Recursively set child visibility for a certain row
    function setChildVisibility(rows, row, visibility) {
        if (!childrenEnabled) {
            return;
        }

        const toggleAll = !row;

        rows.forEach((item) => {
            // Ignore childless rows
            if (
                !item ||
                !item.children ||
                !item.children.rows ||
                item.children.rows.count == 0
            ) {
                return;
            }
            item.childrenVisible =
                toggleAll || item === row
                    ? visibility
                    : item.childrenVisible || false;

            if (item.children) {
                setChildVisibility(item.children.rows, row, visibility);
            }
        });
    }

    // Specific column widths depending on type
    function getColWidth(col) {
        let w;
        switch (col.type) {
            case 'id':
                w = 90;
                break;
            case 'dmy':
                w = 110;
                break;
            case 'dmyhm':
                w = 130;
                break;
            case 'payment.price':
                w = 90;
                break;
            case 'price':
                w = 100;
                break;
            case 'period':
                w = 200;
                break;
            case 'periodPlain':
                w = 215;
                break;
            case 'periodTime':
                w = 130;
                break;
            case 'active':
                w = 20;
                break;
            case 'pf':
                w = 100;
                break;
            case 'counter':
                w = 50;
                break;
            case 'avatar':
                w = 45;
                break;
            case 'user.login':
                w = 110;
                break;
            case 'module':
                w = 100;
                break;
            case 'enrollment':
                w = 180;
                break;
            case 'tooltip':
                w = 20;
                break;
            default:
                w = '';
        }
        return col.width || w;
    }

    /**
     * Render column header
     */
    const renderHeader = (col, idx) => {
        let align = 'center';
        const w = getColWidth(col);

        if (col.align) {
            align = col.align;
        }

        // TODO: Improve width handling because right now the table flickers like crazy when traversing its pages
        return (
            <TableCell
                key={idx}
                align={align}
                style={col.style || { minWidth: w }}
            >
                <b>{col.title}</b>
            </TableCell>
        );
    };

    // Render a cell, mostly depending on col.type
    const renderCell = (row, col, idx, depth = 0) => {
        let val;
        const cls = [];
        let content;
        let align = 'center';
        let old;

        // Set default value for "val"
        if (col.value && col.value instanceof Function) {
            val = col.value(row);
        } else {
            val = row[col.field];
        }

        switch (col.type) {
            // Id
            case 'id':
                align = 'center';
                cls.push('text-muted');
                break;

            // DMY
            case 'dmy':
                if (val) {
                    if (col.checkOld) {
                        if (new Date(val) < new Date()) {
                            old = true;
                        }
                    }
                    align = 'center';
                    content = (
                        <div style={old ? { color: 'red' } : {}}>
                            {moment(val).format('D MMM YYYY')}
                        </div>
                    );
                }
                break;

            // DMY HM
            case 'dmyhm':
                if (val) {
                    if (col.checkOld) {
                        if (new Date(val) < new Date()) {
                            old = true;
                        }
                    }
                    align = 'center';
                    content = (
                        <div style={old ? { color: 'red' } : {}}>
                            {moment(val).format('D MMM YYYY, H:mm')}
                        </div>
                    );
                }
                break;

            // D MMM YYYY - D MMM YYYY
            case 'dmyRange':
                align = 'center';
                content = (
                    <div>{`${moment(val.ini).format('D MMM YYYY')} - ${moment(
                        val.end,
                    ).format('D MMM YYYY')}`}</div>
                );
                break;

            // HH:MM
            case 'hm':
                align = 'center';
                content = <div>{moment(val).format('HH:mm')}</div>;
                break;

            // HH:MM - HH:MM
            case 'hmRange':
                align = 'center';
                content = (
                    <div>{`${moment(val.ini).format('HH:mm')} - ${moment(
                        val.end,
                    ).format('HH:mm')}`}</div>
                );
                break;

            // Price. Acepta o bien un valor plano o bien una tupla {paid, price} con la que se determina si debe pintarse en rojo o en negro
            case 'price':
                align = 'right';
                if (typeof val !== 'object') {
                    content = (
                        <div>
                            {typeof val !== null && typeof val !== 'undefined'
                                ? `${val}`
                                : ''}
                        </div>
                    );
                } else if (val && val.paid !== undefined) {
                    content = (
                        <div style={val.paid ? {} : { color: 'red' }}>
                            {val && val.price
                                ? `${formatPriceByLocale(
                                      val.price,
                                      val.priceShape?.currency,
                                      val.priceShape?.locale,
                                  )}`
                                : ''}
                        </div>
                    );
                }
                break;

            // debt: Always red
            case 'debt':
                align = 'right';
                content = (
                    <div style={{ color: 'red' }}>
                        {typeof val === 'number'
                            ? `${formatPriceByLocale(
                                  val,
                                  cxt.sc?.city?.country?.currency,
                              )}`
                            : ''}
                    </div>
                );
                break;

            // Rate [duration, price]
            case 'rate':
                align = 'center';
                content = val.duration ? (
                    <span>
                        <b>{`${val.duration}m`}</b> /{' '}
                        <span>{`${formatPriceByLocale(
                            val.price,
                            cxt.sc?.city?.country?.currency,
                        )}`}</span>
                    </span>
                ) : (
                    <span />
                ) /* Should be 'null', but there's a if !content further down that fucks up with that approach */;
                break;

            // Period that does care about the current date
            case 'period':
                content = <SttLabelPeriod ini={val.ini} end={val.end} />;
                break;

            // Period that doesn't care about the current date
            case 'periodPlain':
                if (val.ini !== val.end) {
                    content = `${moment(val.ini).format(
                        'D MMM YYYY',
                    )} - ${moment(val.end).format('D MMM YYYY')}`;
                } else if (val.ini) {
                    content = moment(val.ini).format('D MMM YYYY');
                } else {
                    content = <span />;
                }
                break;

            // Period of time: H:mm - H:mm, doesn't care about current date
            case 'periodTime':
                if (val.ini !== val.end) {
                    content = `${moment(val.ini).format('H:mm')} - ${moment(
                        val.end,
                    ).format('H:mm')}`;
                } else if (val.ini) {
                    content = moment(val.ini).format('H:mm');
                } else {
                    content = <span />;
                }
                break;

            // Link
            // Example:
            // 		to: row => (`/sc/group/${row.id}`),
            //		params: row => ({trash: row.trash})
            case 'link':
                if (col.to) {
                    let params = {};
                    if (col.params) {
                        params = col.params(row);
                    }

                    content = (
                        <Link
                            style={col.color ? { color: col.color } : {}}
                            to={{
                                pathname: col.to(row),
                                // Tenemos que usar a la fuerza el campo .search que provee react-router-dom
                                // Los parámetros que aquí se especifiquen pueden luego capturarse con el hook useQuery
                                search: Object.keys(params).reduce(
                                    (result, key, i) =>
                                        // Esto covierte {param1: valor1, param2: valor2, ...} en "?param1=valor1&param2=valor2..."
                                        `${
                                            result + (i > 0 ? '&' : '?') + key
                                        }=${params[key]}`,
                                    '',
                                ),
                            }}
                        >
                            {val}
                        </Link>
                    );
                }
                if (col.onClick) {
                    content = (
                        <a href="#" onClick={() => col.onClick(row)}>
                            {val}
                        </a>
                    );
                }
                align = 'left';
                break;

            // User login
            case 'user.login':
                content = val ? `@${val.login}` : '';
                align = 'center';
                break;

            // Active
            case 'active':
                if (val) {
                    content = <CheckIcon />;
                } else {
                    content = <span />;
                }

                align = 'center';
                break;

            // Payment method
            case 'pf':
                content = val ? <SttChipPForm pf={val} /> : '';
                align = 'center';
                break;

            // Counter (just a number)
            case 'counter':
                align = 'center';
                if (val == 0) val = '';
                break;

            // Avatar
            case 'avatar':
                align = 'center';
                content = (
                    <div>
                        <Avatar src={val} />
                    </div>
                );
                break;

            // Module
            case 'module':
                content = <SttChipModule module={val} />;
                align = 'center';
                break;

            // Enrollment: enrollment fee, periodic fee / interval
            case 'enrollment':
                const { enrollment, fee, interval } = val;

                if (enrollment) {
                    if (fee && interval) {
                        content = `${formatPriceByLocale(
                            enrollment,
                            cxt.sc?.city?.country?.currency,
                        )}, ${formatPriceByLocale(
                            fee,
                            cxt.sc?.city?.country?.currency,
                        )} / ${cxt.t(interval)}`;
                    } else {
                        content = `${formatPriceByLocale(
                            enrollment,
                            cxt.sc?.city?.country?.currency,
                        )}`;
                    }
                } else if (fee && interval) {
                    content = `${formatPriceByLocale(
                        fee,
                        cxt.sc?.city?.country?.currency,
                    )} / ${cxt.t(interval)}`;
                } else {
                    content = <span />; // Again, to avoid the if(!content) below
                }
                break;

            case 'nonZero':
                content = val > 0 ? val : <span />;
                break;

            // Array of days of the week [0 = Sunday, 1 = Monday, ...]
            case 'weekdays':
                content = <SttLabelWeekdays weekdays={val} />;
                break;

            // Duration
            case 'duration':
                content = <SttLabelDuration duration={val} />;
                break;

            // Binary status: yes/no, ok/error etc. If the field value evaluates to true, renders a green 'check' icon. Red 'cross' icon otherwise.
            case 'binaryStatus':
                content = val ? (
                    <CheckCircleOutline style={{ color: '#18b432' }} />
                ) : (
                    <HighlightOff style={{ color: '#df3525' }} />
                );
                break;

            case 'facility':
                content = val ? (
                    <Chip size="small" label={row.facility.name} />
                ) : (
                    <span />
                );
                break;

            case 'user':
                content = val ? <Chip size="small" label={val} /> : <span />;
                break;
            case 'text':
                content = val || <span />;
                break;

            case 'tooltip':
                content = (
                    <Tooltip
                        arrow
                        placement="top"
                        title={val.value}
                        style={{
                            cursor: 'pointer',
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 2,
                            marginBottom: 2,
                        }}
                    >
                        <span onClick={() => col.onClick(row)}>
                            {val.label}
                        </span>
                    </Tooltip>
                );
                break;
        }

        if (!content) {
            content = val;
        }

        // Custom classes
        if (col.className) {
            cls.push(col.className);
        }

        // Column align
        if (col.align) {
            align = col.align;
        }

        let wrappedContent = content;

        if (
            ((childrenEnabled && row.children) || depth > 0) &&
            idx == (hasIdColumn ? 1 : 0)
        ) {
            wrappedContent = (
                <Box pl={depth} display="flex" alignItems="center">
                    {
                        depth > 0 && (
                            <SubdirectoryArrowRight
                                className={classes.subRowIcon}
                            />
                        )
                        // <span style={{width: 4, height: 4, borderRadius: 8, backgroundColor: "#9f9f9f", }} />
                    }
                    {content}
                    {row.children &&
                        row.children.count > 0 &&
                        (row.childrenVisible ? (
                            <IconButton
                                className={classes.showHideWrap}
                                onClick={() => setVisibility(row, false)}
                            >
                                <Remove className={classes.showHideIcon} />
                            </IconButton>
                        ) : (
                            <IconButton
                                className={classes.showHideWrap}
                                onClick={() => setVisibility(row, true)}
                            >
                                <Add className={classes.showHideIcon} />
                            </IconButton>
                        ))}
                </Box>
            );
        }

        return (
            <TableCell
                key={idx}
                align={align}
                className={cls.join(' ')}
                style={col.style}
            >
                {wrappedContent}
            </TableCell>
        );
    };

    /**
     * Toggle selected row
     */
    const toggleSelectedRow = (row, checked) => {
        const foundItem = selectedRows.find((e) => e.id == row.id);
        if (foundItem)
            setSelectedRows(deleteElementFromArray(selectedRows, row));
        else setSelectedRows(updateElementInArray(selectedRows, row));
    };

    /**
     * Get data of a specific page
     */
    const onPageChange = (page) => {
        if (localPagination) {
            return onPageChangeLocally(page);
        }

        if (loading) {
            setLoadingData(true);
        }
        setPage(page);

        if (onChangePagination) {
            onChangePagination(page + 1);
        }
        // hace falta +1 porque en back-end está hardcodeado que la primera página es la 1 en vez de la 0
        onFetch({ page: page + 1, rows: rowsPerPage });
    };

    // Just set the page locally, no need to request new data
    const onPageChangeLocally = (page) => {
        setPage(page);
    };

    /**
     * Change rows per page
     */
    /*
	const handleChangeRowsPerPage = event => {
		const numRows = parseInt(event.target.value, 10);
		setRowsPerPage(numRows);
		setPage(1);
		onFetch({page:page, rows: numRows});
	}
	*/

    function TablePaginationActions({
        count,
        page,
        rowsPerPage,
        onPageChange,
    }) {
        const classes = useStyles();
        const theme = useTheme();

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(
                event,
                Math.max(0, Math.ceil(count / rowsPerPage) - 1),
            );
        };

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                >
                    {theme.direction === 'rtl' ? (
                        <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                >
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                    {theme.direction === 'rtl' ? (
                        <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                    )}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    /**
     * Snipped with pagination
     */
    const PaginationAdHoc = hidePagination ? null : (
        <TablePagination
            style={{ borderBottom: 0 }}
            // rowsPerPageOptions={[5, 10, 20, 25, 50, 100, 200, 500]}
            colSpan={100}
            // labelDisplayedRows=({from, to, count}) => ``
            labelRowsPerPage={`${cxt.t('RowsPerPage')}:`}
            rowsPerPageOptions={[rowsPerPage]}
            count={internalData ? internalData.count || 0 : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onPageChange={(ev, page) => onPageChange(page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );

    // Rows which contain children will render them underneath, recursively
    function RecursiveTableRow({ row, depth }) {
        const content = (
            <TableRow
                hover={Boolean(onClickRow)}
                onClick={() => (onClickRow ? onClickRow(row) : null)}
                style={{
                    backgroundColor: row.trash ? '#ffe9e9' : '',
                    cursor: onClickRow ? 'pointer' : null,
                }}
            >
                {selection && row.id && (
                    <TableCell>
                        <Checkbox
                            checked={selectedRows.find((r) => r.id === row.id)}
                            style={{ width: 42, height: 42 }}
                            onClick={(ev) => {
                                toggleSelectedRow(row, ev.target.checked);
                                ev.stopPropagation(); // stop event bubbling so the row's onClick callback isn't fired
                            }}
                        />
                    </TableCell>
                )}

                {columns &&
                    columns.map((col, index) => {
                        if (index === (hasIdColumn ? 1 : 0)) {
                            return renderCell(row, col, index, depth);
                        }
                        return renderCell(row, col, index);
                    })}
            </TableRow>
        );
        return (
            <>
                {content}
                {childrenEnabled &&
                    row.children &&
                    row.childrenVisible &&
                    row.children.rows.map((child, index) => (
                        <RecursiveTableRow
                            key={index}
                            depth={depth + 1}
                            row={child}
                        />
                    ))}
            </>
        );
    }

    /**
     * Render totals at the end of the list
     */
    function renderTotalsRow() {
        // Do we have totals ???
        if (!totals || !data || data.rows.length === 0) {
            return null;
        }

        let usedColSpan = 0;

        return (
            <TableRow>
                {totals.map((item, i) => {
                    const { value, index, adornment } = item;

                    if (!value) {
                        return null;
                    }

                    const span = index + 1 - usedColSpan;
                    usedColSpan += index + 1;

                    return (
                        <TableCell key={i} colSpan={span}>
                            <Box
                                flex={1}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                {value.toFixed(2) + (adornment || '')}
                            </Box>
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    let renderData = internalData;
    if (
        localPagination &&
        internalData &&
        internalData.rows &&
        internalData.rows.length > 0
    ) {
        renderData = {
            count: rowsPerPage,
            rows: internalData.rows.slice(
                page * rowsPerPage,
                (page + 1) * rowsPerPage,
            ),
        };
    }

    // -----| Render |-----
    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-end"
                borderBottom="1px solid rgba(224, 224, 224, 1)"
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            {selectedRows && selectedRows.length > 0 && (
                                <TableCell>
                                    <SttMenu
                                        size="small"
                                        type="button"
                                        caption={`${cxt.t('Selected')}: ${
                                            selectedRows.length
                                        }`}
                                        icon=<CallSplitIcon />
                                        items={
                                            selection.actions &&
                                            selection.actions.map((action) => ({
                                                caption: action.caption,
                                                icon: action.icon,
                                                onClick: () => {
                                                    action.onClick(
                                                        selectedRows,
                                                    );
                                                    setSelectedRows([]);
                                                    setSelectedAllRows(false);
                                                },
                                            }))
                                        }
                                    />
                                </TableCell>
                            )}

                            {PaginationAdHoc}
                        </TableRow>
                        {loading && loadingData && (
                            <TableRow>
                                <TableCell colSpan="100" style={{ padding: 0 }}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>

            <TableContainer>
                <Table size="small">
                    {/* We only render the header if there is at least one column with a 'title' prop */}
                    {/* We check this at render time because otherwise there'll be a visible 'snap' if columns props is changed */}
                    {columns &&
                        columns.reduce(
                            (res, col) => (col.title ? true : false || res),
                            false,
                        ) && (
                            <TableHead>
                                <TableRow>
                                    {selection && (
                                        <TableCell align="center">
                                            <Checkbox
                                                checked={selectedAllRows}
                                                style={{
                                                    width: 42,
                                                    height: 42,
                                                }}
                                                onClick={(ev) => {
                                                    setSelectedAllRows(
                                                        !selectedAllRows,
                                                    );
                                                    ev.target.checked
                                                        ? setSelectedRows(
                                                              renderData.rows,
                                                          )
                                                        : setSelectedRows([]);
                                                    ev.stopPropagation(); // stop event bubbling so the row's onClick callback isn't fired
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {columns &&
                                        columns.map((col, idxCol) =>
                                            renderHeader(col, idxCol),
                                        )}
                                </TableRow>
                            </TableHead>
                        )}

                    <TableBody>
                        {renderData &&
                            renderData.rows &&
                            renderData.rows.map((row, idxRow) => (
                                <RecursiveTableRow
                                    key={idxRow}
                                    row={row}
                                    depth={0}
                                />
                            ))}

                        {renderData && renderData.count === 0 && (
                            <TableRow>
                                <TableCell colSpan="100">
                                    <div
                                        className="text-center mt-4 mb-4"
                                        style={{ fontSize: 20, color: '#ccc' }}
                                    >
                                        {cxt.t('NoResults')}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {renderTotalsRow()}

                        <TableRow>{PaginationAdHoc}</TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

// Comparison function to determine if a change in props really means a change in renderable data (see React.memo below)
function areEqual(prevProps, nextProps) {
    // First time render, probably
    if (!prevProps || !nextProps) {
        return false;
    }

    // Compare data count
    if (
        prevProps.data &&
        nextProps.data &&
        prevProps.data.count !== nextProps.data.count
    ) {
        return false;
    }

    // Compare loading variable
    if (prevProps.loading !== nextProps.loading) {
        return false;
    }

    if (prevProps.loadingValue !== nextProps.loadingValue) {
        return false;
    }

    // forceUpdateSwitch is used as a toggle to force redraws
    if (prevProps.forceUpdateSwitch !== nextProps.forceUpdateSwitch) {
        return false;
    }

    // Absent or different columns in any way
    if (
        (!prevProps.columns && !nextProps.columns) ||
        !!prevProps.columns !== !!nextProps.columns ||
        prevProps.columns.length !== nextProps.columns.length ||
        commaSeparated(prevProps.columns, (column) => column.title) !==
            commaSeparated(nextProps.columns, (column) => column.title)
    ) {
        return false;
    }

    // No data or different types
    if (
        typeof prevProps.data !== typeof nextProps.data ||
        !prevProps.data ||
        !nextProps.data ||
        !prevProps.data.rows ||
        !nextProps.data.rows
    ) {
        return false;
    }

    // One of them empty or different # of rows
    if (
        (!prevProps.data.rows && nextProps.data.rows) ||
        (prevProps.data.rows && !nextProps.data.rows) ||
        prevProps.data.rows.length !== nextProps.data.rows.length
    ) {
        return false;
    }

    // Cheap check, compare all ids
    let sameIds = true;
    for (let i = 0; i < nextProps.data.rows.length; i++) {
        sameIds =
            sameIds && prevProps.data.rows[i].id === nextProps.data.rows[i].id;
    }

    if (!sameIds) {
        return false;
    }

    // Check if selectRows was updated.
    if (
        prevProps.selectRows &&
        nextProps.selectRows &&
        prevProps.selectRows.length !== nextProps.selectRows.length
    ) {
        return false;
    }

    // Final (and expensive) check, compare their JSON representation
    return (
        JSON.stringify(prevProps.data.rows) ===
        JSON.stringify(nextProps.data.rows)
    );
}

// React.memo is used to memoize the render result, using a comparison function to determine prop equality: https://reactjs.org/docs/react-api.html#reactmemo
export default React.memo(SttTable, areEqual);
