import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Grid,
    Card,
    CardActions,
    Button,
    Typography,
} from '@material-ui/core';
import { Publish, Videocam, FeaturedVideoOutlined } from '@material-ui/icons';
import {
    AppContext,
    SttFormText,
    SttFormDate,
    SttFormTextarea,
    SttButtonSave,
    SttButtonDelete,
    SttList,
    SttSelectGender,
    SttFormSwitch,
    SttSelectUser,
    SttFormSelect,
    SttSelectCity,
    SttSportChips,
    SttUnlockerChips,
    SttUploadDialog,
    SttButton,
} from '../../../sporttia/all';
import { subsetObject, getErrorMessage } from '../../../lib/utils';
import SttLinkList from './SttLinkList';
import { SttSelectConsentList } from '../../../sporttia/lists/SttSelectConsentList';
import userImage from '../../../images/user.svg';
import BoletoDialog from '../../../components/dialogs/BoletoDialog';
import { getPath } from '../../Pages';
import SttWebcamCaptureDialog from './SttWebcamCaptureDialog';
import MshipPupils from './MshipPupils';
import {
    useInteractionsFiles,
    useLoader,
    useTranslations,
} from '../../../lib/hooks';
import translations from '../../../translations';
import useMshipsService from '../../../services/MshipsService';

export default function MshipDetail({ mship, onChange, setMship, onClose }) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [boletos, setBoletos] = useState([]);
    const [isCertified, setIsCertified] = useState(
        mship.certified !== null && mship.certified !== undefined,
    );
    const [consents, setConsents] = useState([]);
    const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
    const [openedBoleto, setOpenedBoleto] = useState();
    const [openWebcamCapture, setOpenWebcamCapture] = useState(false);
    const [pupils, setPupils] = useState([]);
    const [validateForm, setValidateForm] = useState(false);
    const { translate } = useTranslations();
    const { downloadFile } = useInteractionsFiles();

    /**
     * Mship changes
     */
    useEffect(() => {
        if (mship.id) {
            // eslint-disable-next-line no-use-before-define
            loadBoletos();
            // eslint-disable-next-line no-use-before-define
            loadConsents();
            // eslint-disable-next-line no-use-before-define
            loadPupils();

            setIsCertified(
                mship.certified !== null && mship.certified !== undefined,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mship.id]);

    const mshipsService = useMshipsService();

    const updateMshipMutation = mshipsService.useUpdateMship({
        onSuccess: () => {
            cxt.showMessage('S', cxt.t(translations.generic.saved));
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const deleteMshipMutation = mshipsService.useDeleteMship({
        onSuccess: () => {
            history.push(getPath('mships'));
            cxt.showMessage('S', cxt.t(translations.generic.deleted));
            onClose();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    const handleOnSave = () => {
        const params = subsetObject(mship, [
            'name',
            'surname1',
            'surname2',
            'identificationType',
            'dni',
            'gender',
            'notes',
            'birthday',
            'email',
            'email2',
            'mobile',
            'mobile2',
            'address',
            'addrVia',
            'addrType',
            'addrNumber',
            'addrLetter',
            'iban',
            'postalCode',
        ]);
        params.idTutor = mship.tutor ? mship.tutor.id : null;
        params.idCity = mship.city ? mship.city.id : null;

        updateMshipMutation.mutate({ id: mship.id, params });
    };

    const handleOnRemove = () => {
        deleteMshipMutation.mutate({
            id: mship.id,
            params: {
                confirmation: true,
            },
        });
    };

    const handleForm = (p) => {
        onChange(p);
    };

    const loadBoletos = () => {
        cxt.api('GET', `/mships/${mship.id}/boletos`, {
            params: { page: 1, rows: 50 },
            success: (r) => {
                setBoletos(r.rows);
            },
        });
    };

    const loadConsents = () => {
        cxt.api('GET', `/mships/${mship.id}/consents`, {
            params: { rows: 1000 },
            success: (r) => {
                setConsents(r.rows);
            },
        });
    };

    const loadPupils = () => {
        cxt.api('GET', `/mships/${mship.id}/pupils`, {
            params: { rows: 1000 },
            success: (r) => {
                setPupils(r.rows);
            },
        });
    };

    const toggleCertification = () => {
        cxt.api('PUT', `/mship/${mship.id}/certifications/toggle`, {
            success: () => {
                setIsCertified(!isCertified);
            },
        });
    };

    function printUserCard() {
        /* openUrl( <----- esto no funciona ya con los requisitos de seguridad más estrictos que hay en v7
            `${cxt.getResource(
                `/mships/${mship.id}/card.pdf`,
            )}?width=88&height=55`,
        ); */

        downloadFile(
            `${cxt.getResource(
                `/mships/${mship.id}/card.pdf`,
            )}?width=88&height=55`,
            'application/pdf',
            {},
            'card.pdf',
        );
    }

    const [, loader] = useLoader([
        updateMshipMutation.status,
        deleteMshipMutation.status,
    ]);

    return (
        <>
            {loader}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Card>
                        {mship.img ? (
                            <img
                                alt={mship.name}
                                style={{ width: '100%' }}
                                src={mship.img.url}
                            />
                        ) : (
                            <Box p={2}>
                                <img
                                    alt="Default"
                                    style={{ width: '100%' }}
                                    src={userImage}
                                />
                            </Box>
                        )}
                        <CardActions
                            style={{
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button
                                style={{ flex: 1 }}
                                size="small"
                                color="primary"
                                startIcon={<Videocam />}
                                onClick={() => setOpenWebcamCapture(true)}
                            >
                                {cxt.t(translations.mship.webcamCapture)}
                            </Button>
                            <Button
                                style={{ flex: 1 }}
                                size="small"
                                color="primary"
                                startIcon={<Publish />}
                                onClick={() => setOpenUploadPhoto(true)}
                            >
                                {cxt.t(translations.generic.upload)}
                            </Button>
                        </CardActions>
                        {mship?.user?.lastVisit && (
                            <Box p={1} mt={2}>
                                <Typography variant="body1">
                                    {cxt.t(translations.generic.lastVisit)}
                                </Typography>
                                <Typography variant="body2">
                                    {mship.user.lastVisit}
                                </Typography>
                            </Box>
                        )}
                    </Card>

                    <Box mt={3} flex={1}>
                        <SttButton
                            fullWidth
                            caption="Imprimir carnet"
                            color="primary"
                            icon={<FeaturedVideoOutlined />}
                            onClick={printUserCard}
                        />
                    </Box>

                    {/* --- Certificated --- */}
                    <Box mt={3}>
                        <SttFormSwitch
                            caption={cxt.t(translations.generic.certified)}
                            checked={isCertified}
                            onChange={toggleCertification}
                        />
                    </Box>

                    {/* --- Links --- */}
                    {mship?.user && (
                        <SttLinkList
                            items={[
                                {
                                    caption: `${
                                        mship.user.sports
                                            ? mship.user.sports.length
                                            : 0
                                    } ${cxt.t(translations.generic.sports)}`,
                                    content: mship.user.sports && (
                                        <SttSportChips
                                            sports={mship.user.sports}
                                        />
                                    ),
                                },
                                {
                                    caption: `${
                                        mship.unlockers
                                            ? mship.unlockers.length
                                            : 0
                                    } ${cxt.t(translations.generic.unlockers)}`,
                                    content: (
                                        <SttUnlockerChips
                                            unlockers={mship.unlockers}
                                            showCreatingButton
                                            idUser={mship.user.id}
                                            idSC={mship.sc.id}
                                        />
                                    ),
                                },
                                {
                                    caption: `${boletos.length} ${cxt.t(
                                        translations.generic.bonos,
                                    )}`,
                                    content: (
                                        <SttList
                                            title={cxt.t(
                                                translations.generic.bonos,
                                            )}
                                            onSelect={setOpenedBoleto}
                                            data={boletos.map((bl) => ({
                                                caption: bl.bono.name,
                                                text: `${cxt.t(
                                                    translations.generic
                                                        .consumed,
                                                )}: ${bl.consumed} / ${
                                                    bl.amount
                                                }`,
                                                objToSelect: bl,
                                            }))}
                                        />
                                    ),
                                },
                                {
                                    caption: `${consents.length} ${cxt.t(
                                        translations.generic.consents,
                                    )}`,
                                    content: (
                                        <SttSelectConsentList
                                            user={mship.user}
                                            consents={consents}
                                            onSign={loadConsents}
                                        />
                                    ),
                                },
                                {
                                    caption: `${pupils.length} ${cxt.t(
                                        translations.generic.pupils,
                                    )}`,
                                    content: <MshipPupils mshipId={mship.id} />,
                                },
                            ]}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={3}>
                        {/* --- Name --- */}
                        <SttFormText
                            grid
                            md={12}
                            sm={12}
                            xs={12}
                            name="name"
                            caption={cxt.t(translations.generic.name)}
                            defVal={mship.name || ''}
                            helpertext={`Login: ${
                                mship.user && mship.user.login
                            }`}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={6}
                            sm={6}
                            xs={12}
                            name="surname1"
                            caption={cxt.t(translations.generic.surname1)}
                            defVal={mship.surname1 || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={6}
                            sm={6}
                            xs={12}
                            name="surname2"
                            caption={cxt.t(translations.generic.surname2)}
                            defVal={mship.surname2 || ''}
                            onChange={handleForm}
                        />

                        <SttFormSelect
                            grid
                            md={3}
                            sm={6}
                            xs={6}
                            name="identificationType"
                            caption={cxt.t(
                                translations.generic.identificationType,
                            )}
                            defVal={mship.identificationType || ''}
                            onChange={handleForm}
                            options={[
                                {
                                    caption: cxt.t(translations.generic.dni),
                                    value: 'DNI',
                                },
                                {
                                    caption: cxt.t(
                                        translations.generic.passport,
                                    ),
                                    value: 'Pasaporte',
                                },
                                {
                                    caption: cxt.t(translations.generic.nie),
                                    value: 'NIE',
                                },
                                {
                                    caption: translations.generic.cif,
                                    value: 'CIF',
                                },
                            ]}
                        />

                        <SttFormText
                            grid
                            md={3}
                            name="dni"
                            caption={cxt.t(translations.generic.dni)}
                            defVal={mship.dni || ''}
                            onChange={handleForm}
                        />

                        <SttSelectGender
                            grid
                            md={3}
                            name="gender"
                            defVal={mship.gender || ''}
                            onChange={handleForm}
                        />

                        {/* --- Birthday --- */}
                        <SttFormDate
                            grid
                            md={3}
                            name="birthday"
                            caption={cxt.t(translations.generic.birthday)}
                            defVal={mship.birthday}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={8}
                            name="email"
                            caption={`${cxt.t(translations.generic.email)} 1`}
                            defVal={mship.email || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={4}
                            name="mobile"
                            caption={`${cxt.t(translations.generic.mobile)} 1`}
                            defVal={mship.mobile || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={8}
                            name="email2"
                            caption={`${cxt.t(translations.generic.email)} 2`}
                            defVal={mship.email2 || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={4}
                            name="mobile2"
                            caption={`${cxt.t(translations.generic.mobile)} 2`}
                            defVal={mship.mobile2 || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            xs={12}
                            md={4}
                            name="address"
                            caption={cxt.t(translations.generic.address)}
                            defVal={mship.address || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={4}
                            name="postalCode"
                            caption={cxt.t(translations.generic.postalCode)}
                            defVal={mship.postalCode || ''}
                            onChange={handleForm}
                        />

                        <SttSelectCity
                            grid
                            md={4}
                            name="city"
                            caption={cxt.t(translations.generic.city)}
                            defVal={(mship.city && mship.city.name) || ''}
                            onSelect={handleForm}
                        />

                        <SttFormSelect
                            grid
                            md={4}
                            name="addrVia"
                            caption={cxt.t(translations.generic.via)}
                            defVal={mship.addrVia || ''}
                            onChange={handleForm}
                            options={[
                                {
                                    caption: cxt.t(translations.generic.street),
                                    value: 'STREET',
                                },
                                {
                                    caption: cxt.t(translations.generic.avenue),
                                    value: 'AVENUE',
                                },
                                {
                                    caption: cxt.t(translations.generic.square),
                                    value: 'SQUARE',
                                },
                            ]}
                        />

                        <SttFormSelect
                            grid
                            md={4}
                            name="addrType"
                            caption={cxt.t(translations.generic.buildingType)}
                            defVal={mship.addrType || ''}
                            onChange={handleForm}
                            options={[
                                {
                                    caption: cxt.t(translations.generic.flat),
                                    value: 'FLAT',
                                },
                                {
                                    caption: cxt.t(translations.generic.house),
                                    value: 'HOUSE',
                                },
                                {
                                    caption: cxt.t(translations.generic.block),
                                    value: 'BLOCK',
                                },
                            ]}
                        />

                        <SttFormText
                            grid
                            md={2}
                            name="addrNumber"
                            caption={cxt.t(translations.generic.number)}
                            defVal={mship.addrNumber || ''}
                            onChange={handleForm}
                        />

                        <SttFormText
                            grid
                            md={2}
                            name="addrLetter"
                            caption={cxt.t(translations.generic.letter)}
                            defVal={mship.addrLetter || ''}
                            onChange={handleForm}
                        />

                        <SttFormTextarea
                            grid
                            md={12}
                            name="notes"
                            caption={cxt.t(translations.generic.notes)}
                            defVal={mship.notes || ''}
                            onChange={handleForm}
                        />

                        <SttSelectUser
                            grid
                            xs={12}
                            md={7}
                            caption={cxt.t(translations.generic.tutor)}
                            user={{
                                ...mship.tutor,
                                fullName:
                                    mship.tutor?.mship?.fullName ||
                                    mship?.tutor?.fullName ||
                                    ' ',
                            }}
                            onSelect={(mshipSelected) => {
                                handleForm({
                                    name: 'tutor',
                                    value: mshipSelected
                                        ? {
                                              ...mshipSelected?.user,
                                              mship: {
                                                  fullName:
                                                      mshipSelected?.fullName,
                                              },
                                          }
                                        : null,
                                });
                            }}
                            returnMship
                        />

                        <SttFormText
                            grid
                            md={5}
                            name="iban"
                            caption="IBAN"
                            defVal={mship.iban || ''}
                            onChange={handleForm}
                            validation={[
                                {
                                    regex: /^$/,
                                    error: translate(
                                        translations.mship.ibanEmptyOrValid,
                                    ),
                                },
                                {
                                    regex: /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/,
                                    error: translate(
                                        translations.mship.ibanEmptyOrValid,
                                    ),
                                },
                            ]}
                            setValidationError={setValidateForm}
                        />
                    </Grid>

                    <Box my={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <SttButtonSave
                                    disabled={validateForm}
                                    onClick={() => handleOnSave()}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SttButtonDelete
                                    onClick={() => handleOnRemove()}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <SttUploadDialog
                open={openUploadPhoto}
                types={['USER_PHOTO']}
                onClose={() => setOpenUploadPhoto(false)}
                uploadParams={{
                    idMship: mship && mship.id,
                }}
                onUploaded={(r) => {
                    const { file } = r;
                    setOpenUploadPhoto(false);
                    setMship({
                        ...mship,
                        img: file,
                    });
                }}
            />

            <SttWebcamCaptureDialog
                mship={mship}
                open={openWebcamCapture}
                onClose={() => setOpenWebcamCapture(false)}
                onUploaded={(r) => {
                    const { file } = r;
                    setOpenWebcamCapture(false);
                    setMship({
                        ...mship,
                        img: file,
                    });
                }}
            />

            {openedBoleto && (
                <BoletoDialog
                    item={openedBoleto}
                    onClose={() => setOpenedBoleto(false)}
                />
            )}
        </>
    );
}
