import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Button, Dialog, DialogContent } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
    AppContext,
    SttDialog,
    SttButtonYears,
    SttFormText,
    SttFormTextarea,
    SttFormCheck,
} from '../all';
import { getPath } from '../../pages/Pages';
import { useInteractionsFiles } from '../../lib/hooks';

/**
 * Dialog file
 * ============
 *
 * Props
 *
 * 	* integer idFile
 * 	* function onClose
 * 	* function onUpdate
 */
export function SttFileDialog({ idFile, onClose, onUpdate }) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const history = useHistory();
    const [file, setFile] = useState(null);

    /**
     * Load file
     */
    const loadFile = () => {
        cxt.api('GET', `/files/${idFile}`, {
            success: (r) => {
                setFile(r.file);
            },
        });
    };

    /**
     * Save file
     */
    const saveFile = () => {
        cxt.api('PUT', `/files/${file.id}`, {
            params: file,
            success: (r) => {
                setFile(r.file);
                cxt.showMessage('S', cxt.t('Saved'));
                if (onUpdate) onUpdate(r.file);
            },
        });
    };

    /**
     * Delete file
     */
    const delFile = () => {
        cxt.api('DELETE', `/files/${file.id}`, {
            confirmation: true,
            success: (r) => {
                cxt.showMessage('S', cxt.t('Deleted'));
                if (onClose) onClose();
                if (onUpdate) onUpdate(r.file);
            },
        });
    };

    /**
     * File id changes
     */
    useEffect(() => {
        if (idFile) loadFile();
    }, [idFile]);

    /**
     * Download
     */
    const download = () => {
        downloadFile(`/files/${file.id}.pdf`, 'application/pdf', null);
    };

    /**
     * See CSV file
     */
    const seeFileCSV = () => {
        history.push(getPath('fileCSV', { id: file.id }));
    };

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setFile({ ...file, [name]: value });
    };

    // Render
    return (
        <SttDialog
            title={cxt.t('File')}
            open={idFile != null}
            onClose={() => onClose()}
            content={
                <>
                    {file && (
                        <Grid container spacing={3}>
                            {file.isImage && (
                                <Grid item md={12}>
                                    <img
                                        src={file.url}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            )}

                            <SttFormText
                                grid
                                md={12}
                                name="name"
                                caption={cxt.t('Name')}
                                defVal={file.name}
                                onChange={handleForm}
                                disabled={
                                    !(
                                        cxt.logged &&
                                        cxt.user.role === 'SPORTCENTER'
                                    )
                                }
                            />

                            {cxt.logged && cxt.user.role === 'SPORTCENTER' && (
                                <SttFormTextarea
                                    grid
                                    md={12}
                                    name="description"
                                    caption={cxt.t('Description')}
                                    defVal={file.description}
                                    onChange={handleForm}
                                />
                            )}

                            {cxt.logged && cxt.user.role === 'SPORTCENTER' && (
                                <SttFormCheck
                                    grid
                                    md={12}
                                    name="public"
                                    caption={cxt.t('Public')}
                                    checked={file.public}
                                    onChange={handleForm}
                                />
                            )}
                        </Grid>
                    )}
                </>
            }
            buttons={[
                {
                    show: file && file.format.toLowerCase() == 'text/csv',
                    caption: cxt.t('See'),
                    icon: <VisibilityIcon />,
                    onClick: seeFileCSV,
                },
                {
                    show:
                        file && file.format.toLowerCase() == 'application/pdf',
                    caption: cxt.t('Download'),
                    onClick: download,
                },
                {
                    show: cxt.logged && cxt.user.role === 'SPORTCENTER',
                    type: 'delete',
                    onClick: delFile,
                },
                {
                    show: cxt.logged && cxt.user.role === 'SPORTCENTER',
                    type: 'save',
                    onClick: saveFile,
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
