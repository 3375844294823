import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AppContext, SttDialog } from '../../../sporttia/all';
import {
    formatPriceByLocale,
    isPaymentFormWithGateway,
} from '../../../lib/utils';
import useScsService from '../../../services/ScsService';
import constants from '../../../config/constants';

export default function BookingOperate({
    open = false,
    staticMsg,
    mship,
    fares,
    rateType,
    onClose,
    onBook,
    sc,
    finalPrice,
}) {
    const cxt = useContext(AppContext);
    const scsService = useScsService();

    const [boletos, setBoletos] = useState();
    const [pfsToShow, setPfsToShow] = useState([]);
    const [SCPrivileges, setSCPrivileges] = useState([]);
    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);

    const sportcenterPrivilegesQuery = scsService.useGetSportcenterPrivileges(
        sc?.id,
    );
    scsService.useGetScTpvConfs(
        sc?.id,
        {},
        {
            onSuccess(response) {
                const hasZitycard = response.rows.some(
                    (tpv) => tpv.bank === constants.tpvConf.banks.zitycard.name,
                );

                if (hasZitycard) {
                    setPfsToShow([
                        ...pfsToShow,
                        constants.payment.paymentForms.zitycard.name,
                    ]);
                }
            },
        },
    );

    const selectTpvMethod = (pf) => {
        if (sc?.termsAndConditions) {
            setOpenTermsAndConditions(true);
            setPaymentMethodSelected(pf.id);
        } else {
            onBook({ paymentForm: pf.id });
        }
    };

    /**
     * Get sport centers privileges data
     */
    useEffect(() => {
        if (
            cxt.user.role === cxt.constants.roleUser ||
            cxt.user.role === cxt.constants.roleTeacher
        ) {
            if (sc) {
                cxt.api('GET', `/scs/${sc.id}/me`, {
                    success: (r) => {
                        if (r.mship && r.mship.privileges)
                            setSCPrivileges(r.mship.privileges);
                    },
                });
            }
        } else {
            setSCPrivileges(
                sportcenterPrivilegesQuery?.data?.rows?.map(
                    (privilege) => privilege?.active && privilege?.id,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sc, sportcenterPrivilegesQuery?.data]);

    /**
     * DATOS:
     * - Privilegio 13: Permite pago online.
     * - Privilegio 6: PENDIENTE
     * - Privilegio 26: MONEDERO
     * - Privilegio 27: TPV
     */

    /**
     * Set payments to show
     */
    useEffect(() => {
        if (
            cxt.user.role === cxt.constants.roleUser ||
            cxt.user.role === cxt.constants.roleTeacher
        ) {
            const options = [];

            if (SCPrivileges.includes(6)) {
                options.push('');
            }
            if (SCPrivileges.includes(26)) {
                options.push('PURSE');
            }
            if (SCPrivileges.includes(27)) {
                options.push('TPV');
            }
            setPfsToShow([...pfsToShow, ...options]);
        } else if (cxt.user.role === cxt.constants.roleSC) {
            const options = ['', 'CASH', 'BANK', 'FREE'];
            if (cxt.sc?.dataphoneConnected) {
                options.push('DATAFONO_CONNECTED');
            } else {
                options.push('DATAFONO');
            }
            if (SCPrivileges?.includes(26)) {
                options.push('PURSE');
            }
            setPfsToShow([...pfsToShow, ...options]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        cxt.user.role,
        SCPrivileges,
        mship,
        sportcenterPrivilegesQuery.data,
        finalPrice,
    ]);

    /**
     * fares or rateType change -> set boletos
     */
    useEffect(() => {
        if (fares && rateType) {
            if (rateType === cxt.constants.RT_Complete) {
                setBoletos(fares.fullBonos);
            } else {
                setBoletos(fares.individualBonos);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fares, rateType]);

    // Render
    return (
        <>
            <SttDialog
                title={cxt.t('Book')}
                open={open}
                onClose={onClose}
                content={
                    <>
                        {staticMsg && (
                            <Box mb={3}>
                                <Alert variant="filled" severity="warning">
                                    {staticMsg}
                                </Alert>
                            </Box>
                        )}

                        <>
                            {cxt.constants.paymentForms
                                .filter((pf) => pfsToShow.includes(pf.id))
                                .map((pf) => (
                                    <Box key={pf?.id} my={1}>
                                        <Button
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                if (
                                                    isPaymentFormWithGateway(
                                                        pf.id,
                                                    )
                                                ) {
                                                    selectTpvMethod(pf);
                                                } else {
                                                    onBook({
                                                        paymentForm: pf.id,
                                                    });
                                                }
                                            }}
                                        >
                                            {pf.label}
                                            {mship && pf.id === 'PURSE' && (
                                                <span>
                                                    &nbsp;&nbsp;[{' '}
                                                    {formatPriceByLocale(
                                                        mship?.amount,
                                                        mship?.amountShape
                                                            ?.currency,
                                                        mship?.amountShape
                                                            ?.locale,
                                                    )}{' '}
                                                    ]{' '}
                                                </span>
                                            )}
                                        </Button>
                                    </Box>
                                ))}

                            {boletos && (
                                <Box mt={4}>
                                    {boletos.map((boleto) => (
                                        <Box key={boleto?.id} my={1}>
                                            <Button
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                onClick={() =>
                                                    onBook({
                                                        idBoleto: boleto.id,
                                                    })
                                                }
                                            >
                                                {boleto.bono.name} [{' '}
                                                {boleto.amount -
                                                    boleto.consumed}{' '}
                                                ]
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </>
                    </>
                }
                buttons={[
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />

            <SttDialog
                title={cxt.t('TermsAndConditions')}
                maxWidth="xl"
                open={openTermsAndConditions}
                onClose={() => setOpenTermsAndConditions(false)}
                content={
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: sc?.termsAndConditions,
                        }}
                    />
                }
                buttons={[
                    {
                        type: 'accept',
                        onClick: () =>
                            onBook({ paymentForm: paymentMethodSelected }),
                    },
                    {
                        type: 'close',
                        onClick: () => setOpenTermsAndConditions(false),
                    },
                ]}
            />
        </>
    );
}
