import React, { useState, useEffect, useContext, useRef } from 'react';
import { AttachFile, Check } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import {
    AppContext,
    SttButton,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useCrud, useInteractionsFiles } from '../../../lib/hooks';
import ModalFile from '../events/ModalFile';

export default function ActivityDocuments({ activity }) {
    const cxt = useContext(AppContext);
    const { uploadFile, downloadFile } = useInteractionsFiles();
    const inputFile = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [_activity, setActivity] = useState(activity);
    const [files, setFiles, , Get, Post, Put, Delete] = useCrud();
    const [file, setFile] = useState();
    const [forceUpdate, setforceUpdate] = useState(false);

    function loadFiles() {
        Get(`/activities/${_activity.id}/files`)
            .then((response) => {
                if (response.rows) {
                    setFiles(response);
                }
            })
            .catch(console.log);
    }

    function openSelectFile() {
        inputFile.current.click();
    }

    function onFileSelected(files) {
        if (files) {
            const signatureType = '';
            const file = files[0];

            setUploading(true);

            uploadFile(
                {
                    name: file.name,
                    type: 'ACTIVITY_DOC',
                    idActivity: _activity.id,
                },
                file,
            )
                .then(() => {
                    setUploading(false);
                    cxt.showMessage('S', cxt.t('Uploaded'));
                    loadFiles();
                })
                .catch(console.log);
        }
    }

    function saveFile(file) {
        const { name, description } = file;
        const isPublic = file.public;

        Put(`/files/${file.id}`, { name, description, public: isPublic })
            .then((response) => {
                if (response.file) {
                    setFiles({
                        ...files,
                        rows: files.rows.map((f) =>
                            f.id === file.id ? response.file : f,
                        ),
                    });
                    setFile(null);
                }
            })
            .catch(console.log);
    }

    function deleteFile(file) {
        Delete(`/files/${file.id}`).then((response) => {
            setFiles({
                count: files.count - 1,
                rows: files.rows.filter((f) => f.id !== file.id),
            });
            setFile(null);
        });
    }

    function downloadFiles(file) {
        const parts = file.name.split('.');
        const extension = parts[parts.length - 1];

        if (file.hasOwnProperty('url') && file.url != '') {
            downloadFile(file.url, file.format, {}, file.name);
        } else if (extension in ['jpg', 'jpeg', 'png']) {
            downloadFile(
                `/images/${file.id}.${extension}`,
                file.format,
                {},
                file.name,
            );
        } else {
            downloadFile(
                `/files/${file.id}.${extension}`,
                file.format,
                {},
                file.name,
            );
        }
    }

    function setPoster(file) {
        Put(`/activities/${_activity.id}`, { idPoster: file.id })
            .then((response) => {
                if (response.activity) {
                    setActivity(response.activity);
                    setforceUpdate(!forceUpdate);
                }
            })
            .catch(console.log);
    }

    return (
        <>
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(_event) => onFileSelected(_event.target.files)}
            />
            <SttTopControls
                mainAction={{
                    type: 'component',
                    component: (
                        <SttButton
                            caption={cxt.t('Add')}
                            variant="contained"
                            color="primary"
                            startIcon={<AttachFile />}
                            onClick={openSelectFile}
                        />
                    ),
                }}
            />
            <SttTable
                data={files}
                onFetch={loadFiles}
                forceUpdateSwitch={forceUpdate}
                columns={[
                    {
                        title: cxt.t('Created'),
                        type: 'dmy',
                        field: 'created',
                        align: 'left',
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        onClick: (row) => setFile(row),
                        align: 'left',
                    },
                    {
                        align: 'right',
                        value: (row) => {
                            if (
                                _activity.poster &&
                                _activity.poster.id === row.id
                            ) {
                                return (
                                    <Box
                                        display="inline-flex"
                                        alignItems="center"
                                    >
                                        <span style={{ marginRight: 8 }}>
                                            {cxt.t('pages.events.poster')}
                                        </span>
                                        <Check style={{ fontSize: 15 }} />
                                    </Box>
                                );
                            }
                            return (
                                <a href="#" onClick={() => setPoster(row)}>
                                    {cxt.t('pages.events.selectPoster')}
                                </a>
                            );
                        },
                    },
                ]}
            />
            {file && (
                <ModalFile
                    onClose={() => setFile(null)}
                    item={file}
                    onSave={saveFile}
                    onDelete={deleteFile}
                    onDownload={downloadFiles}
                />
            )}
        </>
    );
}
