import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { usePageHeader, useQueryFilters } from '../../../../lib/hooks';
import { SUPERADMIN_TPV_RECORDS } from '../../../../lib/queryKeys';
import SttCachedTable from '../../../../sporttia/SttCachedTable';
import useTpvsService from '../../../../services/TpvsService';
import { SttTopFilteringControls } from '../../../../sporttia/SttTopFilteringControls';
import { AppContext } from '../../../../sporttia/AppContext';
import translations from '../../../../translations';

/**
 * View where the sporttia superadmin can see the list of tpv records.
 * @returns {JSX.Element}
 */
function AdminTpvRecords() {
    usePageHeader('tpvRecords');
    const cxt = useContext(AppContext);
    const tpvsService = useTpvsService();
    const [filters, setFilters] = useQueryFilters({}, SUPERADMIN_TPV_RECORDS);

    /**
     * In this case, before making the request, if the value of paymentIni or paymentEnd is null,
     * it is removed from the filters and if it is not formatted.
     * @param updatedFilters Object with the parameters returned by the SttTopFilteringControls component.
     */
    const doFiltering = (updatedFilters) => {
        const formatedFilters = { ...updatedFilters };

        if (updatedFilters.paymentIni)
            formatedFilters.paymentIni = moment(
                updatedFilters.paymentIni,
            ).format('YYYY-MM-DD');
        else delete formatedFilters.paymentIni;

        if (updatedFilters.paymentEnd)
            formatedFilters.paymentEnd = moment(
                updatedFilters.paymentEnd,
            ).format('YYYY-MM-DD');
        else delete formatedFilters.paymentEnd;

        setFilters(formatedFilters);
    };

    return (
        <Paper>
            <SttTopFilteringControls
                p={2}
                extraFields={[
                    {
                        caption: 'ID',
                        name: 'idTPV',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Name'),
                        name: 'tpvName',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Login'),
                        name: 'login',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Amount'),
                        name: 'price',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('Status'),
                        name: 'status',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('dateIni'),
                        name: 'paymentIni',
                        type: 'date',
                        value: new Date(),
                    },
                    {
                        caption: cxt.t('dateEnd'),
                        name: 'paymentEnd',
                        type: 'date',
                        value: new Date(),
                    },
                    {
                        caption: cxt.t('tpvConfiguration'),
                        name: 'tpvConfName',
                        type: 'text',
                        value: '',
                    },
                    {
                        caption: cxt.t('SportCenter'),
                        name: 'scShort',
                        type: 'text',
                        value: '',
                    },
                ]}
                onFilter={doFiltering}
                trashAction
            />

            <SttCachedTable
                queryKey={SUPERADMIN_TPV_RECORDS}
                queryFn={(params) => tpvsService.getList(params)}
                queryParams={filters}
                columns={[
                    {
                        title: 'Id',
                        type: 'id',
                        field: 'id',
                    },
                    {
                        title: cxt.t(translations.generic.created),
                        type: 'dmyhm',
                        field: 'created',
                    },
                    {
                        title: cxt.t('Paid'),
                        value: (row) =>
                            moment(row?.payment?.paymentDate).format(
                                'D MMM YYYY HH:mm:ss',
                            ),
                    },
                    {
                        title: cxt.t('Name'),
                        field: 'displayName',
                    },
                    {
                        title: cxt.t('Sportcenter'),
                        value: (row) => row?.sc?.short,
                    },
                    {
                        title: cxt.t('Login'),
                        value: (row) => row?.user?.login,
                    },
                    {
                        title: cxt.t('Status'),
                        field: 'status',
                    },
                    {
                        title: cxt.t('Deleted'),
                        field: 'trash',
                        value: (row) =>
                            row?.trash ? (
                                <b style={{ color: 'red' }}>SI</b>
                            ) : (
                                <b style={{ color: 'green' }}>NO</b>
                            ),
                    },
                    {
                        title: cxt.t('tpvConfiguration'),
                        value: (row) => row?.tpvConf?.name,
                    },
                    {
                        title: cxt.t('Amount'),
                        field: 'price',
                    },
                ]}
                totals={[
                    {
                        index: 8,
                        name: 'price',
                        adornment: ' €',
                    },
                ]}
            />
        </Paper>
    );
}

export default AdminTpvRecords;
