import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SttSelectUser } from '../../../sporttia/selectors/SttSelectUser';
import { SttFormDate } from '../../../sporttia/forms/SttFormDate';
import { SttFormTextarea } from '../../../sporttia/forms/SttFormTextarea';
import { AppContext } from '../../../sporttia/AppContext';
import { SttDialog } from '../../../sporttia/dialogs/SttDialog';
import { SttSelectActivityRate } from '../../../sporttia/selectors/SttSelectActivityRate';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

/**
 * Dialog to recover classes.
 * @param open
 * @param onClose
 * @param activityId
 * @returns {JSX.Element}
 */

export default function RecoverAttendanceDialog({ open, onClose, activityId }) {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const [recovery, setRecovery] = useState({ notes: '', date: new Date() });
    const [student, setStudent] = useState(null);
    const [recoverableAssistences, setRecoverableAssistences] = useState();
    const [selectedRecoverableAssistence, setSelectedRecoverableAssistence] =
        useState();
    const [selectedActivityRateId, setSelectedActivityRateId] = useState();

    const loadAssistences = () => {
        cxt.api('GET', `/mships/${student.id}/assistences`, {
            params: {
                status: 'RECOVERABLE',
            },
            success: (r) => {
                setRecoverableAssistences(r);
                setSelectedRecoverableAssistence(null);
            },
        });
    };

    const recoverAssistence = () => {
        const finalParams = recovery;

        finalParams.idActivityRate = selectedActivityRateId;

        cxt.api(
            'POST',
            `/classes/assistences/${selectedRecoverableAssistence.id}/recoveries`,
            {
                params: {
                    ...finalParams,
                },
                success: () => {
                    closeDialog();
                },
            },
        );
    };

    const closeDialog = () => {
        setStudent(null);
        setRecoverableAssistences(null);
        setSelectedRecoverableAssistence(null);
        onClose();
    };

    useEffect(() => {
        if (student && student.id) loadAssistences();
    }, [student]);

    return (
        <SttDialog
            title={`${cxt.t('Recover')}`}
            open={open}
            onClose={closeDialog}
            maxWidth="sm"
            buttons={[
                {
                    type: 'save',
                    disabled: !selectedRecoverableAssistence,
                    onClick: () => recoverAssistence(),
                },
                {
                    type: 'close',
                    onClick: () => closeDialog(),
                },
            ]}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttSelectUser
                            caption={cxt.t('User')}
                            name="user"
                            user={student}
                            onSelect={(user) => setStudent(user)}
                            returnMship
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttFormDate
                            caption={cxt.t('Date')}
                            name="date"
                            defVal={recovery.date}
                            onChange={({ name, value }) =>
                                setRecovery({ ...recovery, [name]: value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttFormTextarea
                            name="notes"
                            rows={3}
                            caption={cxt.t('Notes')}
                            defVal={recovery.notes}
                            onChange={({ name, value }) =>
                                setRecovery({
                                    ...recovery,
                                    [name]: value,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <SttSelectActivityRate
                            activityId={activityId}
                            defVal={selectedActivityRateId}
                            caption={cxt.t('Rate')}
                            onChange={(value) => {
                                setSelectedActivityRateId(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {/* Aquí tengo que añadir las diferentes fechas posibles a recuperar del usuario. */}
                        <Typography variant="subtitle2" gutterBottom>
                            Recuperar{' '}
                            {selectedRecoverableAssistence && student
                                ? moment(
                                      selectedRecoverableAssistence.created,
                                  ).format('dddd, DD MMMM HH:mm')
                                : ''}
                        </Typography>

                        {student &&
                        !selectedRecoverableAssistence &&
                        recoverableAssistences &&
                        recoverableAssistences.count > 0 ? (
                            <List className={classes.root}>
                                {recoverableAssistences.rows.map(
                                    (assistence, key) => (
                                        <ListItem
                                            key={key}
                                            dense
                                            button
                                            onClick={() =>
                                                setSelectedRecoverableAssistence(
                                                    assistence,
                                                )
                                            }
                                        >
                                            <ListItemText
                                                id={`item-${key}`}
                                                primary={moment(
                                                    assistence.class.ini,
                                                ).format('dddd, DD MMMM HH:mm')}
                                            />
                                        </ListItem>
                                    ),
                                )}
                            </List>
                        ) : !selectedRecoverableAssistence && student ? (
                            'No hay resultados'
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>
            }
        />
    );
}
