import React, { useState, useEffect, useContext, useRef } from 'react';
import { AttachFile, Check } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import {
    AppContext,
    SttButton,
    SttTable,
    SttTopControls,
} from '../../../sporttia/all';
import { useCrud, useInteractionsFiles } from '../../../lib/hooks';
import ModalFile from './ModalFile';

export default function EventDocuments({ event }) {
    const cxt = useContext(AppContext);
    const { uploadFile, downloadFile, checkFileSignature } =
        useInteractionsFiles();
    const inputFile = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [_event, setEvent] = useState(event);
    const [files, setFiles, , Get, Post, Put, Delete] = useCrud();
    const [file, setFile] = useState();
    const [forceUpdate, setforceUpdate] = useState(false);

    useEffect(() => {
        console.log(_event.poster);
    }, [_event]);

    function loadFiles() {
        Get(`/events/${_event.id}/files`)
            .then((response) => {
                if (response.rows) {
                    setFiles(response);
                }
            })
            .catch(console.log);
    }

    function openSelectFile() {
        inputFile.current.click();
    }

    function onFileSelected(files) {
        if (files) {
            const fileSignature = '';
            const file = files[0];

            setUploading(true);

            uploadFile(
                {
                    name: file.name,
                    type: 'EVENT_DOC',
                    idEvent: _event.id,
                },
                file,
            )
                .then(() => {
                    setUploading(false);
                    cxt.showMessage('S', cxt.t('Uploaded'));
                    loadFiles();
                })
                .catch(console.log);
        }
    }

    function saveFile(file) {
        const { name, description } = file;
        const isPublic = file.public;

        Put(`/files/${file.id}`, { name, description, public: isPublic })
            .then((response) => {
                if (response.file) {
                    setFiles({
                        ...files,
                        rows: files.rows.map((f) =>
                            f.id === file.id ? response.file : f,
                        ),
                    });
                    setFile(null);
                }
            })
            .catch(console.log);
    }

    function deleteFile(file) {
        Delete(`/files/${file.id}`).then((response) => {
            setFiles({
                count: files.count - 1,
                rows: files.rows.filter((f) => f.id !== file.id),
            });
            setFile(null);
        });
    }

    function downloadFiles(file) {
        const parts = file.name.split('.');
        const extension = parts[parts.length - 1];
        let downloadPath = '';

        if (extension == 'jpg' || extension == 'png' || extension == 'jpeg') {
            downloadPath = '/images/';
        } else if (extension == 'pdf') {
            downloadPath = '/files/';
        }

        downloadFile(
            `${downloadPath}${file.id}.${extension}`,
            file.format,
            {},
            file.name,
        );
    }

    function setPoster(file) {
        Put(`/events/${_event.id}`, { idPoster: file.id })
            .then((response) => {
                if (response.event) {
                    setEvent(response.event);
                    setforceUpdate(!forceUpdate);
                }
            })
            .catch(console.log);
    }

    return (
        <>
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(_event) => onFileSelected(_event.target.files)}
            />
            <SttTopControls
                mainAction={{
                    type: 'component',
                    component: (
                        <SttButton
                            caption={cxt.t('Add')}
                            variant="contained"
                            color="primary"
                            startIcon={<AttachFile />}
                            onClick={openSelectFile}
                        />
                    ),
                }}
            />
            <SttTable
                data={files}
                onFetch={loadFiles}
                forceUpdateSwitch={forceUpdate}
                columns={[
                    {
                        title: cxt.t('Created'),
                        type: 'dmy',
                        field: 'created',
                        align: 'left',
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        onClick: (row) => setFile(row),
                        align: 'left',
                    },
                    {
                        align: 'right',
                        value: (row) => {
                            if (_event.poster && _event.poster.id === row.id) {
                                return (
                                    <Box
                                        display="inline-flex"
                                        alignItems="center"
                                    >
                                        <span style={{ marginRight: 8 }}>
                                            {cxt.t('pages.events.poster')}
                                        </span>
                                        <Check style={{ fontSize: 15 }} />
                                    </Box>
                                );
                            }
                            return (
                                <a href="#" onClick={() => setPoster(row)}>
                                    {cxt.t('pages.events.selectPoster')}
                                </a>
                            );
                        },
                    },
                ]}
            />
            {file && (
                <ModalFile
                    onClose={() => setFile(null)}
                    item={file}
                    onSave={saveFile}
                    onDelete={deleteFile}
                    onDownload={downloadFiles}
                />
            )}
        </>
    );
}
