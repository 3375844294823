import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import {
    AppContext,
    SttTopControls,
    SttTable,
    SttButtonFab,
} from '../../../sporttia/all';
import {
    useCrud,
    usePageHeader,
    useToggle,
    useTranslations,
    useTuples,
} from '../../../lib/hooks';
import FacilitySet from './FacilitySet';

export default function FacilitySets() {
    usePageHeader('FacilityGroups');
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const [facilitySets, setFacilitySets, , Get] = useCrud();
    const [facilitySetIdAndName, setFacilitySetIdAndName] = useState(null);

    const [filters, setFilter] = useTuples({
        status: 'ALL',
    });

    // Trash hook
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadFacilitySets();
    });

    /**
     * Get facility sets
     */
    function loadFacilitySets(params = { page: 1, rows: 20 }) {
        Get(`/scs/${cxt.sc.id}/facilitysets`, {
            name: filters.name,
            status: filters.status === 'ALL' ? '' : filters.status,
            ...params,
            trash,
        }).then((response) => {
            setFacilitySets(response);
        });
    }

    function goCreateNew() {
        setFacilitySetIdAndName({ id: 'create', name: translate('NewGroup') });
    }

    return (
        <>
            <Paper>
                <SttTopControls
                    p={2}
                    fields={[
                        {
                            name: 'name',
                            type: 'text',
                            value: filters.name,
                            caption: cxt.t('Name'),
                            onEnterKey: loadFacilitySets,
                        },
                    ]}
                    extraFields={[
                        {
                            xs: 12,
                            sm: 6,
                            md: 3,
                            name: 'status',
                            type: 'select',
                            value: filters.status,
                            options: [
                                {
                                    caption: cxt.t('All'),
                                    value: 'ALL',
                                },
                                {
                                    caption: cxt.t('Actives'),
                                    value: 'ACTIVE',
                                },
                            ],
                        },
                    ]}
                    onChange={setFilter}
                    onFilter={() => loadFacilitySets()}
                    onToggleTrash={toggleTrash}
                />

                <SttTable
                    data={facilitySets}
                    loading={false}
                    onClickRow={(row) => {
                        setFacilitySetIdAndName({ id: row.id, name: row.name });
                    }}
                    columns={[
                        {
                            field: 'name',
                            title: cxt.t('Name'),
                            align: 'left',
                        },
                        {
                            field: 'position',
                            type: 'position',
                            title: cxt.t('Position'),
                        },
                        {
                            field: 'created',
                            type: 'dmy',
                            title: cxt.t('Created'),
                        },
                        {
                            field: 'status',
                            type: 'active',
                            title: cxt.t('Active'),
                            value: (row) => row.status === 'ACTIVE',
                        },
                    ]}
                    onFetch={loadFacilitySets}
                />
                <SttButtonFab onClick={goCreateNew} />
            </Paper>

            {facilitySetIdAndName && (
                <FacilitySet
                    facilitySetId={facilitySetIdAndName.id}
                    onClose={() => {
                        setFacilitySetIdAndName(null);
                    }}
                    title={facilitySetIdAndName.name}
                />
            )}
        </>
    );
}
