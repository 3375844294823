import React, { useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { AppContext } from '../../sporttia/all';
import { PaymentForm } from '../../types/payment';
import translations from '../../translations';

type PaymentFormDialogProps = {
    pfs: PaymentForm[];
    onClose: () => void;
    open: boolean;
    onSelect: (paymentForm: PaymentForm) => void;
};

export default function PaymentFormDialog({
    pfs = [],
    onClose,
    open,
    onSelect,
}: PaymentFormDialogProps) {
    const cxt = useContext(AppContext)!;

    const paymentForms = {
        CASH: cxt.t(translations.payment.paymentFormCash),
        PURSE: cxt.t(translations.payment.paymentFormPurse),
        BANK: cxt.t(translations.payment.paymentFormBank),
        DATAFONO: cxt.t(translations.payment.paymentFormDataphone),
        DATAFONO_CONNECTED: cxt.t(
            translations.payment.paymentFormDataphoneConnected,
        ),
        TPV: cxt.t(translations.payment.paymentFormTpv),
        RECEIPT: cxt.t(translations.payment.paymentFormReceipt),
        FREE: cxt.t(translations.payment.paymentFormFree),
        LATER: cxt.t(translations.payment.paymentFormLater),
        ZITYCARD: cxt.t(translations.payment.paymentFormZitycard),
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{cxt.t(translations.payment.paymentForm)}</DialogTitle>
            <List>
                {pfs.map((paymentForm) => (
                    <ListItem
                        button
                        onClick={() => onSelect(paymentForm)}
                        key={paymentForm}
                    >
                        <ListItemText primary={paymentForms[paymentForm]} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
