import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    Grid,
    Typography,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { AppContext } from '../../../sporttia/AppContext';
import userImage from '../../../images/user.svg';
import { SttUploadDialog } from '../../../sporttia/dialogs/SttUploadDialog';
import { SttFormText } from '../../../sporttia/forms/SttFormText';
import { SttFormDate } from '../../../sporttia/forms/SttFormDate';
import { SttSelectGender } from '../../../sporttia/selectors/SttSelectGender';
import { useTuples } from '../../../lib/hooks';
import { SttGoogleMap } from '../../../sporttia/SttGoogleMap';
import { SttButtonSave } from '../../../sporttia/buttons/SttButtonSave';
import { SttButtonDelete } from '../../../sporttia/buttons/SttButtonDelete';
import { getPath } from '../../Pages';
import { SttFormSelect } from '../../../sporttia/forms/SttFormSelect';
import { SttSelectCity } from '../../../sporttia/selectors/SttSelectCity';

export default function UserData({ userData }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [user, setUser] = useState(userData);
    const [userPassword, setUserPassword] = useTuples({
        password: '',
    });
    const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        setUser(userData);

        if (cxt.config && cxt.config.languages) {
            const languages = [];
            cxt.config.languages.map((lg) =>
                languages.push({
                    key: lg.key,
                    caption: lg.caption,
                    value: lg.key,
                }),
            );
            setLanguages(languages);
        }
    }, [userData]);

    const handleForm = (p) => {
        setUser({ ...user, [p.name]: p.value });
    };

    const onChangeLatLng = ({ lat, lng }) => {
        setUser({ ...user, x: lat, y: lng });
    };

    const getParamsByUser = (user) => ({
        login: user.login,
        name: user.name,
        surname1: user.surname1,
        surname2: user.surname2,
        email: user.email,
        mobile: user.mobile,
        birthday: user.birthday,
        gender: user.gender,
        x: user.x,
        y: user.y,
        password: userPassword.password,
        passwordExpirationInterval:
            user.passwordExpirationInterval === 'NEVER'
                ? null
                : user.passwordExpirationInterval,
        commActive: user.commActive,
        identificationType: user.identificationType,
        dni: user.dni,
        address: user.address,
        postalCode: user.postalCode,
        idCity: user.city?.id,
        addrVia: user.addrVia,
        addrType: user.addrType,
        addrNumber: user.addrNumber,
        addrLetter: user.addrLetter,
        iban: user.iban,
        lang: user.lang,
    });

    const logout = () => {
        cxt.setLoggedUser(false);
        cxt.setHeader(null);
        history.push(getPath('login'));
    };

    const saveUser = () => {
        cxt.setLang(languages.find((lg) => lg.key === user.lang));
        cxt.api('PUT', '/me', {
            params: getParamsByUser(user),
            success: (r) => {
                if (user.login !== cxt.user.login) {
                    cxt.showMessage('S', cxt.t('user.change.username'));
                    logout();
                } else {
                    cxt.showMessage('S', cxt.t('Modified'));
                }
            },
        });
    };

    const deleteUser = () => {
        cxt.api('DELETE', '/me', {
            confirmation: true,
            success: () => {
                logout();
            },
        });
    };

    let lastVisit = null;
    if (cxt?.user?.lastVisit) {
        lastVisit = moment(cxt?.user?.lastVisit).format('D MMM YYYY - HH:mm');
    }

    // -----| Render |-----
    return (
        <>
            {user && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Card>
                            {user.img ? (
                                <img
                                    style={{ width: '100%' }}
                                    src={cxt.getResource(
                                        `/images/${user.img.id}.jpg`,
                                    )}
                                />
                            ) : (
                                <Box p={2}>
                                    <img
                                        style={{ width: '100%' }}
                                        src={userImage}
                                    />
                                </Box>
                            )}
                            <CardActions>
                                <Button
                                    fullWidth
                                    size="small"
                                    color="primary"
                                    startIcon={<PublishIcon />}
                                    onClick={() => setOpenUploadPhoto(true)}
                                >
                                    {cxt.t('Upload')}
                                </Button>
                            </CardActions>
                            {lastVisit && (
                                <Box p={1} mt={2}>
                                    <Typography variant="body1">
                                        Última visita:
                                    </Typography>
                                    <Typography variant="body2">
                                        {lastVisit}
                                    </Typography>
                                </Box>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                            <SttFormText
                                grid
                                md={4}
                                sm={4}
                                xs={12}
                                name="login"
                                caption={cxt.t('Username')}
                                defVal={user.login || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={8}
                                sm={8}
                                xs={12}
                                name="name"
                                caption={cxt.t('Name')}
                                defVal={user.name || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={6}
                                sm={6}
                                xs={12}
                                name="surname1"
                                caption={cxt.t('surname1')}
                                defVal={user.surname1 || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={6}
                                sm={6}
                                xs={12}
                                name="surname2"
                                caption={cxt.t('surname2')}
                                defVal={user.surname2 || ''}
                                onChange={handleForm}
                            />

                            <SttFormDate
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="birthday"
                                caption={cxt.t('Birthday')}
                                defVal={user.birthday}
                                onChange={handleForm}
                            />

                            <SttSelectGender
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="gender"
                                defVal={user.gender || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="mobile"
                                caption={cxt.t('Mobile')}
                                defVal={user.mobile || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="email"
                                caption={cxt.t('Email')}
                                defVal={user.email || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="password"
                                password
                                caption={cxt.t('Password')}
                                defVal={userPassword.password}
                                onChange={setUserPassword}
                            />

                            <SttFormSelect
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="passwordExpirationInterval"
                                caption={cxt.t(
                                    'page.user.configuration.passwordExpiration',
                                )}
                                /* usamos 'NEVER' para esquivar el bug de material-ui con los select y las ids vacías */
                                defVal={
                                    user.passwordExpirationInterval || 'NEVER'
                                }
                                onChange={handleForm}
                                options={
                                    cxt.constants.passwordExpirationIntervals
                                }
                            />

                            <SttFormSelect
                                grid
                                xs={12}
                                sm={6}
                                md={6}
                                name="identificationType"
                                caption={cxt.t('identificationType')}
                                defVal={user.identificationType || 'derp'}
                                onChange={handleForm}
                                options={[
                                    { caption: 'DNI', value: 'DNI' },
                                    { caption: 'NIF', value: 'NIF' },
                                ]}
                            />

                            <SttFormText
                                grid
                                xs={12}
                                md={6}
                                name="dni"
                                caption={cxt.t('DNI')}
                                defVal={user.dni || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={6}
                                xs={12}
                                name="address"
                                caption={cxt.t('Address')}
                                defVal={user.address || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={6}
                                xs={12}
                                name="postalCode"
                                caption={cxt.t('postalCode')}
                                defVal={user.postalCode || ''}
                                onChange={handleForm}
                            />

                            <SttSelectCity
                                grid
                                md={6}
                                xs={12}
                                name="city"
                                caption={cxt.t('City')}
                                defVal={(user.city && user.city.name) || ''}
                                onSelect={handleForm}
                            />

                            <SttFormSelect
                                grid
                                md={6}
                                sm={6}
                                xs={12}
                                name="addrVia"
                                caption={cxt.t('Via')}
                                defVal={user.addrVia || ''}
                                onChange={handleForm}
                                options={[
                                    {
                                        caption: cxt.t('VT.STREET'),
                                        value: 'STREET',
                                    },
                                    {
                                        caption: cxt.t('VT.AVENUE'),
                                        value: 'AVENUE',
                                    },
                                    {
                                        caption: cxt.t('VT.SQUARE'),
                                        value: 'SQUARE',
                                    },
                                ]}
                            />

                            <SttFormSelect
                                grid
                                md={4}
                                sm={6}
                                xs={12}
                                name="addrType"
                                caption={cxt.t('BuildingType')}
                                defVal={user.addrType || ''}
                                onChange={handleForm}
                                options={[
                                    {
                                        caption: cxt.t('BT.FLAT'),
                                        value: 'FLAT',
                                    },
                                    {
                                        caption: cxt.t('BT.HOUSE'),
                                        value: 'HOUSE',
                                    },
                                    {
                                        caption: cxt.t('BT.BLOCK'),
                                        value: 'BLOCK',
                                    },
                                ]}
                            />

                            <SttFormText
                                grid
                                md={4}
                                xs={12}
                                name="addrNumber"
                                caption={cxt.t('Number')}
                                defVal={user.addrNumber || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={4}
                                xs={12}
                                name="addrLetter"
                                caption={cxt.t('Letter')}
                                defVal={user.addrLetter || ''}
                                onChange={handleForm}
                            />

                            <SttFormText
                                grid
                                md={8}
                                name="iban"
                                caption={cxt.t('iban')}
                                defVal={user.iban || ''}
                                onChange={handleForm}
                            />

                            <SttFormSelect
                                grid
                                md={4}
                                sm={6}
                                name="lang"
                                caption={cxt.t('Language')}
                                defVal={user.lang || ''}
                                onChange={handleForm}
                                options={languages}
                                translate={false}
                            />

                            <Grid item xs={12}>
                                <SttGoogleMap
                                    width="100%"
                                    height={400}
                                    apiKey="AIzaSyBy9IBfS1reqmVgvMaEL1xeUUavAYA_KTc"
                                    latLng={{ lat: user.x, lng: user.y }}
                                    searchEnabled
                                    searchValue=""
                                    searchPosition="top"
                                    onChange={onChangeLatLng}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={4} xs={5}>
                                <SttButtonSave onClick={saveUser} fullWidth />
                            </Grid>
                            <Grid item md={4} xs={7}>
                                <SttButtonDelete
                                    caption={cxt.t('Unsubscribe')}
                                    onClick={deleteUser}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <SttUploadDialog
                open={openUploadPhoto}
                types={['USER_PHOTO']}
                onClose={() => setOpenUploadPhoto(false)}
                uploadParams={{
                    idUser: user && user.id,
                }}
                onUploaded={(r) => {
                    const { file } = r;
                    setOpenUploadPhoto(false);
                    setUser({
                        ...user,
                        img: {
                            id: file.id,
                        },
                    });
                }}
            />
        </>
    );
}
