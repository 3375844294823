import { Environment } from '../types/config';

let environment: Environment = 'PRO';
if (window.runtimeConfig.apiEnv.environment === 'PRE') {
    environment = 'PRE';
} else if (
    window.runtimeConfig.apiEnv.environment === 'DEV' ||
    process.env.NODE_ENV === 'development'
) {
    environment = 'DEV';
}

const generalConfig = {
    environment,
    version: 'v0.65.0',
    translationConfKey: 'appSC',
    languages: [
        {
            key: 'es',
            caption: 'Español',
        },
        {
            key: 'ca',
            caption: 'Catalán',
        },
        {
            key: 'cl',
            caption: 'Español - Chile',
        },
        {
            key: 'en',
            caption: 'English',
        },
        {
            key: 'fr',
            caption: 'Français',
        },
    ],
    sessionTimeout: {
        // Valor por defecto activado. Cambiar la env para activar o desactivar la desconexión automática después de un tiempo de inactividad.
        isEnabled:
            process.env.REACT_APP_LOGOUT_TIMEOUT_ENABLED === 'true' ||
            process.env.REACT_APP_LOGOUT_TIMEOUT_ENABLED === undefined,
        timeoutMs: 300000,
    },
    testStripeTerminal: {
        // Activar para hacer pruebas con Stripe terminal sin una tarjeta real.
        isEnabled: process.env.REACT_APP_TEST_STRIPE_TERMINAL === 'true',
        // Código de la tarjetas de prueba dada por Stripe.
        testCard: process.env.REACT_APP_POS_STRIPE_SIMULATED_TEST_CARD,
    },
} as const;

export default generalConfig;
