type Time = {
    id: number;
    name: string;
    short: string | number;
    tiny: string | number;
};

/**
 * @deprecated Añadir constantes a src/config/constants.ts
 */
export const loadConstants = (trans: (key: string) => string) => {
    // Months
    const months: Time[] = [];
    for (let i = 1; i < 13; i += 1) {
        const t = trans(`month.${i}`);
        months.push({
            id: i,
            name: t,
            short: t ? t.substring(0, 3) : i,
            tiny: t ? t.substring(0, 1) : i,
        });
    }

    // Weekdays
    const weekdays: Time[] = [];
    [1, 2, 3, 4, 5, 6, 0].map((day, dayIndex) =>
        weekdays.push({
            id: dayIndex,
            name: trans(`day.${day}.mini`),
            short: trans(`day.${day}.mini`)
                ? trans(`day.${day}.mini`).substring(0, 3)
                : dayIndex,
            tiny: trans(`day.${day}.mini`)
                ? trans(`day.${day}.mini`).substring(0, 1)
                : dayIndex,
        }),
    );

    return {
        testing: trans('Booking'),

        googleMapsApiKey: 'AIzaSyBy9IBfS1reqmVgvMaEL1xeUUavAYA_KTc',

        // Roles
        roleSC: 'SPORTCENTER',
        roleUser: 'USER',
        roleAdmin: 'ADMIN',
        roleTeacher: 'TEACHER',

        numFieldsToUserImport: 16,

        // Active / inactive
        active: 'ACTIVE',
        inactive: 'INACTIVE',

        // Rate type
        RT_Individual: 1,
        RT_Complete: 2,

        contactPhone: '+34 854 56 20 32',
        contactWhatsApp: '+34 610 63 91 64',
        contactEmail: 'info@sporttia.com',
        landingPage: 'https://sporttia.com',

        appPlayStore:
            'https://play.google.com/store/apps/details?id=es.sporttia.sporttia',
        appAppleStore: 'https://apps.apple.com/app/sporttia/id1093457829',

        /* --- ENUMS --- */

        months,
        weekdays,

        sessionTimeoutMs: 300000,

        services: [
            { id: 'CF', label: trans('SVC.CF') },
            { id: 'RS', label: trans('SVC.RS') },
            { id: 'PK', label: trans('SVC.PK') },
            { id: 'LR', label: trans('SVC.LR') },
            { id: 'GY', label: trans('SVC.GY') },
            { id: 'SPA', label: trans('SVC.SPA') },
            { id: 'NS', label: trans('SVC.NS') },
            { id: 'PO', label: trans('SVC.PO') },
            { id: 'LK', label: trans('SVC.LK') },
            { id: 'GF', label: trans('SVC.GF') },
            { id: 'SH', label: trans('SVC.SH') },
            { id: 'SW', label: trans('SVC.SW') },
        ],

        modules: [
            { id: 'RENTING', label: trans('M.RENTING') },
            { id: 'EVENT', label: trans('M.EVENT') },
            { id: 'CLASS', label: trans('M.CLASS') },
            { id: 'GAME', label: trans('M.GAME') },
            { id: 'ABONADOS', label: trans('M.ABONADOS') },
            { id: 'PURSE', label: trans('M.PURSE') },
        ],

        paymentForms: [
            { id: '', label: trans('PF.LATER') },
            { id: 'CASH', label: trans('PF.CASH') },
            { id: 'PURSE', label: trans('PF.PURSE') },
            { id: 'DATAFONO', label: trans('PF.DATAFONO') },
            { id: 'DATAFONO_CONNECTED', label: trans('PF.DATAFONO_CONNECTED') },
            { id: 'TPV', label: trans('PF.TPV') },
            { id: 'BANK', label: trans('PF.BANK') },
            { id: 'FREE', label: trans('PF.FREE') },
            { id: 'RECEIPT', label: trans('PF.RECEIPT') },
            { id: 'ZITYCARD', label: trans('PF.ZITYCARD') },
        ],

        paymentStatuses: [
            { id: 'PAID', label: trans('Paid') },
            { id: 'NOT_PAID', label: trans('Pending') },
        ],

        bookingSources: [
            { id: 'USER', label: trans('User') },
            { id: 'SC', label: trans('Sportcenter') },
        ],

        scheduleStatuses: [
            { id: 'ACTIVE', label: trans('Active') },
            { id: 'INACTIVE', label: trans('Inactive') },
        ],

        freeLayers: [
            { id: 0, label: trans('Basic') },
            { id: 1, label: `${trans('Layer')} 1` },
            { id: 2, label: `${trans('Layer')} 2` },
            { id: 3, label: `${trans('Layer')} 3` },
        ],

        remittenceStatus: [
            { id: 'NOT_SENT', label: trans('NoSent') },
            { id: 'SENT', label: trans('Sent') },
        ],

        unlockerTypes: [
            { id: 'PIN', label: trans('UT.PIN') },
            { id: 'CARD', label: trans('UT.CARD') },
            { id: 'QR', label: trans('UT.QR') },
            { id: 'TICKET_CARD', label: trans('UT.TICKET_CARD') },
            { id: 'TICKET_ONE_USE', label: trans('UT.TICKET_ONE_USE') },
        ],

        fileTypes: [
            {
                id: 'REMITTENCE_REJECTIONS',
                label: trans('FT.REMITTENCE_REJECTIONS'),
                color: '#3982d0',
            },
            { id: 'EVENT_DOC', label: trans('FT.EVENT_DOC'), color: '#3982d0' },
            { id: 'SC', label: trans('FT.SC'), color: '#39a061' },
            {
                id: 'ACTIVITY_DOC',
                label: trans('FT.ACTIVITY'),
                color: '#3982d0',
            },
            { id: 'SC_LOGO', label: trans('FT.SC_LOGO'), color: '#39d0a1' },
            {
                id: 'USER_PHOTO',
                label: trans('FT.USER_PHOTO'),
                color: '#30c7c2',
            },
            { id: 'FACILITY', label: trans('FT.FACILITY'), color: '#b339d0' },
            { id: 'CONSENT', label: trans('FT.CONSENT'), color: '#533950' },
            { id: 'SIGNATURE', label: trans('FT.SIGNATURE'), color: '#c051c7' },
            {
                id: 'USER_IMPORT',
                label: trans('FT.USER_IMPORT'),
                color: '#a091c7',
            },
            { id: 'USER_DOC', label: trans('FT.USER_DOC'), color: '#b091c7' },
        ],

        fileFormats: [
            { id: 'image/jpeg', label: 'JPEG', color: '#3982d0' },
            { id: 'image/png', label: 'PNG', color: '#1922d0' },
            { id: 'text/plain', label: 'TXT', color: '#39a061' },
            { id: 'application/pdf', label: 'PDF', color: '#39d0a1' },
            { id: 'text/csv', label: 'CSV', color: '#30c7c2' },
            { id: 'text/xml', label: 'XML', color: '#b339d0' },
        ],

        bookingInAdvanceIniIntervals: [
            { id: '0000-00-00', label: trans('sc.schedule.rentingIni.anyIni') },
            {
                id: '0000-00-01',
                label: trans('sc.schedule.rentingIni.ini1Day'),
            },
            {
                id: '0000-00-02',
                label: trans('sc.schedule.rentingIni.ini2Day'),
            },
            {
                id: '0000-00-03',
                label: trans('sc.schedule.rentingIni.ini3Day'),
            },
            {
                id: '0000-00-04',
                label: trans('sc.schedule.rentingIni.ini4Day'),
            },
            {
                id: '0000-00-05',
                label: trans('sc.schedule.rentingIni.ini5Day'),
            },
            {
                id: '0000-00-06',
                label: trans('sc.schedule.rentingIni.ini6Day'),
            },
            {
                id: '0000-00-07',
                label: trans('sc.schedule.rentingIni.ini7Day'),
            },
            {
                id: '0000-00-08',
                label: trans('sc.schedule.rentingIni.ini8Day'),
            },
            {
                id: '0000-00-09',
                label: trans('sc.schedule.rentingIni.ini9Day'),
            },
            {
                id: '0000-00-10',
                label: trans('sc.schedule.rentingIni.ini10Day'),
            },
            {
                id: '0000-00-11',
                label: trans('sc.schedule.rentingIni.ini11Day'),
            },
            {
                id: '0000-00-12',
                label: trans('sc.schedule.rentingIni.ini12Day'),
            },
            {
                id: '0000-00-14',
                label: trans('sc.schedule.rentingIni.ini2Weeks'),
            },
            {
                id: '0000-00-15',
                label: trans('sc.schedule.rentingIni.ini15Day'),
            },
            {
                id: '0000-00-28',
                label: trans('sc.schedule.rentingIni.ini28Day'),
            },
            {
                id: '0000-01-00',
                label: trans('sc.schedule.rentingIni.ini1Month'),
            },
        ],

        bookingInAdvanceEndIntervals: [
            {
                id: '0000-00-00T00:00:00',
                label: trans('sc.schedule.rentingIni.endRentingIni'),
            },
            {
                id: '0000-00-00T01:00:00',
                label: trans('sc.schedule.rentingIni.end1Hour'),
            },
            {
                id: '0000-00-00T02:00:00',
                label: trans('sc.schedule.rentingIni.end2Hour'),
            },
            {
                id: '0000-00-00T03:00:00',
                label: trans('sc.schedule.rentingIni.end3Hour'),
            },
            {
                id: '0000-00-00T04:00:00',
                label: trans('sc.schedule.rentingIni.end4Hour'),
            },
            {
                id: '0000-00-00T05:00:00',
                label: trans('sc.schedule.rentingIni.end5Hour'),
            },
            {
                id: '0000-00-00T06:00:00',
                label: trans('sc.schedule.rentingIni.end6Hour'),
            },
            {
                id: '0000-00-00T07:00:00',
                label: trans('sc.schedule.rentingIni.end7Hour'),
            },
            {
                id: '0000-00-00T08:00:00',
                label: trans('sc.schedule.rentingIni.end8Hour'),
            },
            {
                id: '0000-00-00T12:00:00',
                label: trans('sc.schedule.rentingIni.end12Hour'),
            },
            {
                id: '0000-00-01T00:00:00',
                label: trans('sc.schedule.rentingIni.end1Day'),
            },
            {
                id: '0000-00-02T00:00:00',
                label: trans('sc.schedule.rentingIni.end2Day'),
            },
        ],

        bonoTypes: [
            { id: 'SINGLE', label: trans('BT.SINGLE') },
            { id: 'COMPLETE', label: trans('BT.COMPLETE') },
        ],

        restrictionTypes: [
            {
                id: 'BLACK_LIST',
                label: trans('RT.BLACK_LIST'),
                short: 'BK',
                color: '#666666',
            },
            {
                id: 'NUM_BOOKINGS',
                label: trans('RT.NUM_BOOKINGS'),
                short: 'NU',
                color: '#337788',
            },
            {
                id: 'NUM_ACTIVE_BOOKINGS',
                label: trans('RT.NUM_ACTIVE_BOOKINGS'),
                short: 'AC',
                color: '#A94AAF',
            },
            {
                id: 'DAYS',
                label: trans('RT.DAYS'),
                short: 'DS',
                color: '#A9aA5F',
            },
            {
                id: 'WINDOW',
                label: trans('RT.WINDOW'),
                short: 'WD',
                color: '#A93A53',
            },
        ],

        restrictionSpecificDays: [
            { id: 'EVEN', label: trans('EvenDays') },
            { id: 'ODD', label: trans('OddDays') },
        ],

        eventKinds: [
            {
                id: 'RACE',
                label: trans('EK.RACE'),
            },
            {
                id: 'TOURNAMENT',
                label: trans('EK.TOURNAMENT'),
            },
            {
                id: 'HANGOUT',
                label: trans('EK.HANGOUT'),
            },
        ],

        eventDifficulties: [
            {
                id: 'NO_DIFFICULTY',
                label: trans('ED.NO_DIFFICULTY'),
            },
            {
                id: 'VERY_EASY',
                label: trans('ED.VERY_EASY'),
            },
            {
                id: 'EASY',
                label: trans('ED.EASY'),
            },
            {
                id: 'NORMAL',
                label: trans('ED.NORMAL'),
            },
            {
                id: 'MODERATE',
                label: trans('ED.MODERATE'),
            },
            {
                id: 'HARD',
                label: trans('ED.HARD'),
            },
            {
                id: 'VERY_HARD',
                label: trans('ED.VERY_HARD'),
            },
        ],

        testTypes: [
            {
                id: 'UNIT',
                label: 'Hart',
            },
            {
                id: 'REST',
                label: 'APIs',
            },
            {
                id: 'MANUAL',
                label: 'Manual',
            },
        ],

        testStatuses: [
            {
                id: 'PASSED',
                label: 'Passed',
            },
            {
                id: 'ERROR',
                label: 'Error',
            },
            {
                id: 'SKIP',
                label: 'Skipped',
            },
            {
                id: 'NO_PASSED',
                label: 'No passed',
            },
        ],

        passwordExpirationIntervals: [
            {
                id: 'NEVER' /* usamos 'NEVER' para esquivar el bug de material-ui con los select y las ids vacías */,
                label: trans('page.user.configuration.passwordExpiresNever'),
            },
            {
                id: '0000-00-01 00:00:00',
                label: trans('0000-00-01 00:00:00'),
            },
            {
                id: '0000-00-07 00:00:00',
                label: trans('0000-00-07 00:00:00'),
            },
            {
                id: '0000-01-00 00:00:00',
                label: trans('0000-01-00 00:00:00'),
            },
            {
                id: '0000-06-00 00:00:00',
                label: trans('0000-06-00 00:00:00'),
            },
            {
                id: '0001-00-00 00:00:00',
                label: trans('0001-00-00 00:00:00'),
            },
        ],

        apiLock: 'LOCK',
    };
};
