import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { AppContext } from '../../../sporttia/AppContext';
import { SttChipUnlocker, SttTable } from '../../../sporttia/all';
import SttUserUnlockerDialog from '../../../sporttia/dialogs/SttUserUnlockerDialog';

/**
 * View to see the list of pupils of a user.
 * @returns {JSX.Element}
 */
function UserPupils({}) {
    const cxt = useContext(AppContext);
    const [pupils, setPupils] = useState({});
    const [openUnlocker, setOpenUnlocker] = useState(null);
    const [selectedPupil, setSelectedPupil] = useState(null);

    /**
     * Load user pupils.
     * @param p params with rows and page requested.
     */
    const loadUserPupils = (p = { rows: 20, page: 1 }) => {
        cxt.api('GET', `/my/pupils`, {
            params: {
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setPupils(r);
            },
        });
    };

    return (
        <>
            <Container maxWidth="md">
                <Paper>
                    <SttTable
                        data={pupils}
                        onFetch={loadUserPupils}
                        autoload
                        columns={[
                            {
                                title: cxt.t('NameAndSurname'),
                                value: (row) => row?.user?.fullName,
                            },
                            {
                                title: cxt.t('Sportcenter'),
                                value: (row) => row?.sc?.name,
                            },
                            {
                                title: cxt.t('Unlocker'),
                                value: (row) =>
                                    row?.unlockers &&
                                    row?.unlockers.map((unlocker, idx) => (
                                        <SttChipUnlocker
                                            key={idx}
                                            unlocker={unlocker}
                                            onClick={() => {
                                                (unlocker.type === 'PIN' ||
                                                    unlocker.type === 'QR') &&
                                                    setOpenUnlocker(unlocker);
                                                setSelectedPupil(row);
                                            }}
                                        />
                                    )),
                            },
                        ]}
                    />
                </Paper>
            </Container>

            <SttUserUnlockerDialog
                unlocker={openUnlocker}
                title={`${selectedPupil?.user?.fullName}`}
                subtitle={`${selectedPupil?.sc?.name}`}
                onClose={() => {
                    setOpenUnlocker(null);
                    setSelectedPupil(null);
                }}
            />
        </>
    );
}

export default UserPupils;
