import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper, Grid, Divider } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
    AppContext,
    SttButtonCancel,
    SttButtonDelete,
    SttButtonRecover,
    SttButtonSave,
    SttFacilityList,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttLabelForm,
    SttBusySlotList,
    SttFullDialog,
} from '../../../sporttia/all';
import { BusySlotDialog } from './BusySlotDialog';
import ErrorsDialog from './ErrorsDialog';
import { useTranslations } from '../../../lib/hooks';

export default function BusySchedule({
    idBusySchedule,
    trash,
    onClose,
    match,
}) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const [busySchedule, setBusySchedule] = useState(null);
    const [viewSlot, setViewSlot] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        if (!idBusySchedule) {
            idBusySchedule = match.params.id;
            trash = new URLSearchParams(search).get('trash') || false;
        }

        if (idBusySchedule === 'create') {
            setBusySchedule({
                name: '',
                status: 'ACTIVE',
                ini: moment().format('YYYY-MM-DD HH:mm:ss'),
                end: moment().format('YYYY-12-31 23:59:59'),
                facilities: null,
                slots: null,
            });
            setTitle(translate('New'));
        } else {
            loadBusySchedule();
        }
    }, [idBusySchedule]);

    function loadBusySchedule() {
        cxt.api('GET', `/busies/schedules/${idBusySchedule}`, {
            params: {
                trash,
            },
            success: (response) => {
                if (response.busySchedule) {
                    setBusySchedule(response.busySchedule);
                    setTitle(response.busySchedule.name);
                }
            },
        });
    }

    if (!busySchedule) {
        return null;
    }

    function handleChange({ name, value }) {
        setBusySchedule({ ...busySchedule, [name]: value });
    }

    function addFacility(facility) {
        cxt.api(
            'POST',
            `/busies/schedules/${busySchedule.id}/facilities/${facility.id}`,
            {
                success: () => {
                    setBusySchedule({
                        ...busySchedule,
                        facilities: busySchedule.facilities.concat([facility]),
                    });
                },
            },
        );
    }

    function deleteFacility(facility) {
        cxt.api(
            'DELETE',
            `/busies/schedules/${busySchedule.id}/facilities/${facility.id}`,
            {
                confirmation: true,
                success: () => {
                    setBusySchedule({
                        ...busySchedule,
                        facilities: busySchedule.facilities.reduce(
                            (result, item) =>
                                item.id !== facility.id
                                    ? result.concat([item])
                                    : result,
                            [],
                        ),
                    });
                },
            },
        );
    }

    function saveSlot(slot) {
        if (slot.id) {
            setLoading(true);

            cxt.api('PUT', `/busies/schedules/slots/${slot.id}`, {
                params: {
                    ini: slot.ini,
                    end: slot.end,
                    weekdays: slot.weekdays,
                },
                success: (response) => {
                    if (response.busyScheduleSlot) {
                        cxt.showMessage('S', cxt.t('OperationSuccessful'));
                        setBusySchedule({
                            ...busySchedule,
                            slots: busySchedule.slots.map((slt) =>
                                slt.id === response.busyScheduleSlot.id
                                    ? {
                                          ...response.busyScheduleSlot,
                                          ini: moment(
                                              response.busyScheduleSlot.ini,
                                          ).format('HH:mm'),
                                          end: moment(
                                              response.busyScheduleSlot.end,
                                          ).format('HH:mm'),
                                      }
                                    : slt,
                            ),
                        });
                        setViewSlot(null);
                    }
                    setLoading(false);
                },
                errors: () => {
                    setLoading(false);
                },
            });
        } else {
            setLoading(true);

            cxt.api('POST', `/busies/schedules/${busySchedule.id}/slots`, {
                params: slot,
                success: (response) => {
                    if (response.busyScheduleSlot) {
                        const addedSlot = {
                            ...response.busyScheduleSlot,
                            ini: moment(response.busyScheduleSlot.ini).format(
                                'HH:mm',
                            ),
                            end: moment(response.busyScheduleSlot.end).format(
                                'HH:mm',
                            ),
                        };
                        const slots = busySchedule.slots
                            ? busySchedule.slots.concat([addedSlot])
                            : [addedSlot];

                        setBusySchedule({ ...busySchedule, slots });

                        cxt.showMessage('S', cxt.t('OperationSuccessful'));
                        setViewSlot(null);
                    }

                    if (response.fails) {
                        setErrors(response.fails);
                    }

                    setLoading(false);
                },
                errors: () => {
                    setLoading(false);
                },
            });
        }
    }

    function deleteSlot(slot) {
        cxt.api('DELETE', `/busies/schedules/slots/${slot.id}`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Deleted'));
                setViewSlot(null);
                setBusySchedule({
                    ...busySchedule,
                    slots: busySchedule.slots.filter(
                        (slt) => slt.id !== slot.id,
                    ),
                });
            },
        });
    }

    function saveBusySchedule() {
        if (busySchedule.id) {
            const { name, ini, end, status } = busySchedule;
            cxt.api('PUT', `/busies/schedules/${busySchedule.id}`, {
                params: { name, ini, end, status },
                success: () => {
                    cxt.showMessage('S', cxt.t('OperationSuccessful'));
                    setTitle(name);
                },
            });
        } else {
            const { name, ini, end, status } = busySchedule;
            cxt.api('POST', `/scs/${cxt.sc.id}/busies/schedules`, {
                params: {
                    name,
                    ini: moment(ini).format('YYYY-MM-DD'),
                    end: moment(end).format('YYYY-MM-DD'),
                    status,
                },
                success: (response) => {
                    if (response.busySchedule) {
                        setBusySchedule(response.busySchedule);
                        cxt.showMessage('S', cxt.t('OperationSuccessful'));
                        setTitle(busySchedule.name);
                    }
                },
            });
        }
    }

    function deleteBusySchedule() {
        if (!busySchedule.id) {
            return;
        }

        cxt.api('DELETE', `/busies/schedules/${busySchedule.id}`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Deleted'));
                if (onClose) {
                    onClose();
                } else {
                    history.goBack();
                }
            },
        });
    }

    function recoverBusySchedule() {
        cxt.api('PUT', `/busies/schedules/${busySchedule.id}/recover`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Recovered'));
                loadBusySchedule();
            },
        });
    }

    function openCreateSlot() {
        setViewSlot({
            ini: '09:00',
            end: '23:00',
            weekdays: [],
        });
    }

    return (
        <SttFullDialog
            open
            onClose={onClose || (() => history.goBack())}
            title={title}
        >
            <Box>
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            {busySchedule.id && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <SttLabelForm
                                            title={cxt.t('Created')}
                                            text={moment(
                                                busySchedule.created,
                                            ).format('D-MM-YYYY, HH:mm')}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
									<SttLabelForm
										title={cxt.t('CreatedBy')}
										text={busySchedule.createdBy}
									/>
								</Grid> */}
                                    <Grid item xs={12}>
                                        <Box my={1}>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={busySchedule.id ? 8 : 12}>
                                <SttFormText
                                    caption={cxt.t('NameAndDescription')}
                                    name="name"
                                    defVal={busySchedule.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SttFormSelect
                                    caption={cxt.t('Status')}
                                    name="status"
                                    defVal={busySchedule.status}
                                    options={[
                                        {
                                            caption: cxt.t('Active'),
                                            value: 'ACTIVE',
                                        },
                                        {
                                            caption: cxt.t('Inactive'),
                                            value: 'INACTIVE',
                                        },
                                    ]}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SttFormDate
                                    caption={cxt.t('DateIni')}
                                    name="ini"
                                    defVal={busySchedule.ini}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SttFormDate
                                    caption={cxt.t('DateEnd')}
                                    name="end"
                                    defVal={busySchedule.end}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>

                {busySchedule.id && !busySchedule.trash && (
                    <Box mt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Paper>
                                    <SttFacilityList
                                        facilities={busySchedule.facilities}
                                        onAdd={(facility) =>
                                            addFacility(facility)
                                        }
                                        onDelete={(facility) =>
                                            deleteFacility(facility)
                                        }
                                        trash={trash}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Paper>
                                    <SttBusySlotList
                                        slots={busySchedule.slots}
                                        onAdd={openCreateSlot}
                                        onSelect={(slot) => setViewSlot(slot)}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box mt={3}>
                    <Grid container spacing={3}>
                        {!busySchedule.trash && (
                            <Grid item md={3}>
                                <SttButtonSave
                                    fullWidth
                                    onClick={saveBusySchedule}
                                />
                            </Grid>
                        )}

                        {!busySchedule.id && (
                            <Grid item md={3}>
                                <SttButtonCancel
                                    fullWidth
                                    onClick={() => onClose()}
                                />
                            </Grid>
                        )}

                        {busySchedule.id && !busySchedule.trash && (
                            <Grid item md={3}>
                                <SttButtonDelete
                                    fullWidth
                                    onClick={deleteBusySchedule}
                                />
                            </Grid>
                        )}

                        {busySchedule.trash && (
                            <Grid item md={3}>
                                <SttButtonRecover
                                    fullWidth
                                    onClick={recoverBusySchedule}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>

                <BusySlotDialog
                    item={viewSlot}
                    onSave={(slot) => saveSlot(slot)}
                    onDelete={(slot) => deleteSlot(slot)}
                    onClose={() => setViewSlot(null)}
                    loading={loading}
                />

                <ErrorsDialog errors={errors} onClose={() => setErrors(null)} />
            </Box>
        </SttFullDialog>
    );
}
