import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Box,
    Paper,
    Divider,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import BuildIcon from '@material-ui/icons/Build';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import { getPath } from '../../Pages';
import RemittenceLineDialog from './RemittenceLineDialog';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import {
    subsetObject,
    updateElementInArray,
    formatPriceByLocale,
} from '../../../lib/utils';
import {
    AppContext,
    SttLabelForm,
    SttFormText,
    SttFormSelect,
    SttFormDate,
    SttTable,
    SttButtonSave,
    SttButtonDelete,
    SttButtonRecover,
    SttTopControls,
    SttButton,
    SttUploadDialog,
    SttFullDialog,
} from '../../../sporttia/all';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import { useInteractionsFiles } from '../../../lib/hooks';
import translations from '../../../translations';

export default function Remittence(props) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const history = useHistory();
    const [creating, setCreating] = useState(false);
    const [remittence, setRemittence] = useState();
    const [lines, setLines] = useState();
    const [idPaymentModal, setIdPaymentModal] = useState(null);
    const [filters, setFilters] = useState({
        name: '',
        status: null, // ERROR, RETURNED
    });
    const [openRemittenceLine, setOpenRemittenceLine] = useState(null);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [mshipChosen, setMshipChosen] = useState(null);
    const [selectRows, setSelectRows] = useState([]);
    const [fillBics, setFillBics] = useState(false);

    const loadLines = (p = { rows: 20, page: 1 }) => {
        cxt.api('GET', `/remittences/${props.match.params.id}/lines`, {
            params: {
                ...{
                    ...filters,
                    name: filters?.name ? filters?.name : undefined,
                },
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setLines(r);
            },
        });
    };

    const loadRemittence = () => {
        cxt.api('GET', `/remittences/${props.match.params.id}`, {
            params: {
                trash: true,
            },
            success: (r) => {
                setRemittence(r.remittence);
                loadLines();

                setCreating(false);
            },
        });
    };

    const downloadCSVLines = () => {
        const params = {};

        if (selectRows.length > 0) {
            const newLines = [];

            selectRows.forEach((row) => newLines.push(row.id));

            params.idLines = newLines;
        }

        downloadFile(
            `/remittences/${remittence.id}.csv`,
            'text/csv',
            params,
            'remesa.csv',
        );
    };

    const saveRemittence = () => {
        const params = subsetObject(remittence, [
            'name',
            'status',
            'issueDate',
        ]);

        // Create
        if (creating) {
            cxt.api('POST', `/scs/${cxt.sc.id}/remittences`, {
                params,
                success: (r) => {
                    cxt.showMessage('S', cxt.t(translations.generic.created));
                    history.push(`/remittences/${r.remittence.id}`);
                },
            });

            // Update
        } else {
            cxt.api('PUT', `/remittences/${remittence.id}`, {
                params,
                success: () => {
                    cxt.showMessage('S', cxt.t('Saved'));
                },
            });
        }
    };

    const delRemittence = () => {
        cxt.api('DELETE', `/remittences/${remittence.id}`, {
            confirmation: true,
            success: () => {
                history.push('/scs/remittences');
                cxt.showMessage('S', cxt.t('Deleted'));
            },
        });
    };

    const recoverRemittence = () => {
        cxt.api('PUT', `/remittences/${remittence.id}/recover`, {
            confirmation: true,
            success: () => {
                cxt.showMessage('S', cxt.t('Recovered'));
                loadRemittence();
            },
        });
    };

    const handleForm = ({ name, value }) => {
        setRemittence({ ...remittence, [name]: value });
    };

    const downloadQ19 = () => {
        /* !!ATENCION!! Añadido parche para que en el centro de Puerto Real (3129) se envíe el parámetro fillBICs: true. */
        downloadFile(
            `/remittences/${remittence.id}.q19`,
            'text/xml',
            fillBics ? { fillBICs: true } : null,
            'remesaSEPA.xml',
        );
    };

    const checkRemittence = (fix = false) => {
        cxt.api('PUT', `/remittences/${remittence.id}/check`, {
            confirmation: fix,
            params: {
                fix,
            },
            success: () => {
                cxt.showMessage('S', cxt.t('Saved'));
                loadRemittence();
                if (fix) checkRemittence();
            },
        });
    };

    const updateRemittenceLine = (remittenceLineUpdated) => {
        setLines({
            ...lines,
            rows: updateElementInArray(lines.rows, remittenceLineUpdated),
        });
        setOpenRemittenceLine(null);
    };

    /**
     * Filter changes
     */
    useEffect(() => {
        loadLines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.status]);

    const rejectSelectedLines = (selectedLines) => {
        cxt.api('POST', '/batch', {
            confirmation: true,
            params: {
                calls: selectedLines.map((line) => ({
                    method: 'PUT',
                    endpoint: `/remittences/lines/${line.id}/reject`,
                })),
            },
            success: () => {
                loadLines();
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        const idRemittence = props.match.params.id;
        if (idRemittence) {
            if (idRemittence === 'create') {
                // Init data to create
                setRemittence({
                    name: '',
                });

                setCreating(true);
            } else {
                loadRemittence();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    return (
        <SttFullDialog
            open={!!remittence}
            onClose={() => history.push(getPath('remittences'))}
            title={remittence ? remittence.name : 'Report'}
        >
            <Box>
                <Paper>
                    <Box p={3}>
                        {remittence && (
                            <>
                                {!creating && (
                                    <>
                                        <Grid container spacing={3}>
                                            <SttLabelForm
                                                grid
                                                md={2}
                                                title={cxt.t('Created')}
                                                text={moment(
                                                    remittence.created,
                                                ).format('D-MM-YYYY, HH:mm')}
                                            />
                                        </Grid>

                                        <Box my={4}>
                                            <Divider />
                                        </Box>
                                    </>
                                )}

                                <Grid container spacing={3}>
                                    <SttFormText
                                        grid
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        name="name"
                                        caption={cxt.t('Name')}
                                        defVal={remittence.name}
                                        onChange={handleForm}
                                    />

                                    <SttFormSelect
                                        grid
                                        md={3}
                                        sm={6}
                                        xs={6}
                                        name="status"
                                        caption={cxt.t('Status')}
                                        defVal={remittence.status}
                                        onChange={handleForm}
                                        options={cxt.constants.remittenceStatus}
                                    />

                                    <SttFormDate
                                        grid
                                        md={3}
                                        sm={6}
                                        xs={6}
                                        name="issueDate"
                                        caption={cxt.t('Issued')}
                                        defVal={remittence.issueDate}
                                        onChange={handleForm}
                                    />
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={fillBics}
                                                    onChange={() =>
                                                        setFillBics(!fillBics)
                                                    }
                                                />
                                            }
                                            label="Rellenar BICs al descargar SEPA"
                                        />
                                    </Grid>
                                </Grid>

                                <Box mt={3}>
                                    <Grid container spacing={3}>
                                        {remittence.trash && (
                                            <Grid item md={3} sm={12} xs={12}>
                                                <SttButtonRecover
                                                    fullWidth
                                                    onClick={() =>
                                                        recoverRemittence()
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        {!remittence.trash && (
                                            <Grid item md={3} sm={12} xs={12}>
                                                <SttButtonSave
                                                    fullWidth
                                                    onClick={() =>
                                                        saveRemittence()
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        {!creating && !remittence.trash && (
                                            <>
                                                <Grid
                                                    item
                                                    md={3}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <SttButtonDelete
                                                        fullWidth
                                                        onClick={() =>
                                                            delRemittence()
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={3}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <SttButton
                                                        fullWidth
                                                        caption={`${cxt.t(
                                                            translations.generic
                                                                .download,
                                                        )} SEPA`}
                                                        icon=<GetAppIcon />
                                                        onClick={() =>
                                                            downloadQ19()
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <SttButton
                                                        fullWidth
                                                        caption={cxt.t(
                                                            'RefundsRemittences',
                                                        )}
                                                        icon=<PublishIcon />
                                                        onClick={() =>
                                                            setOpenUploadFile(
                                                                true,
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Box>
                </Paper>

                {remittence && !remittence.trash && !creating && lines && (
                    <Box mt={3}>
                        <Paper>
                            <SttTopControls
                                p={2}
                                fields={[
                                    {
                                        caption: cxt.t('Name'),
                                        name: 'name',
                                        type: 'text',
                                        value: filters.name,
                                        onEnterKey: loadLines,
                                    },
                                ]}
                                onChange={({ name, value }) =>
                                    setFilters({ ...filters, [name]: value })
                                }
                                onFilter={loadLines}
                                downloadButton={{
                                    onClick: () => downloadCSVLines(),
                                }}
                                menu={[
                                    {
                                        caption: cxt.t('Check'),
                                        icon: <CheckCircleOutlineIcon />,
                                        onClick: () => checkRemittence(false),
                                    },
                                    {
                                        caption: cxt.t('Fix'),
                                        icon: <BuildIcon />,
                                        onClick: () => checkRemittence(true),
                                    },
                                    {
                                        divider: true,
                                        caption: cxt.t('All'),
                                        icon: filters.status ? null : (
                                            <DoneIcon />
                                        ),
                                        onClick: () =>
                                            setFilters({
                                                ...filters,
                                                status: null,
                                            }),
                                    },
                                    {
                                        caption: cxt.t(
                                            'sc.remittence.withErrors',
                                        ),
                                        icon:
                                            filters.status === 'ERROR' ? (
                                                <DoneIcon />
                                            ) : null,
                                        onClick: () =>
                                            setFilters({
                                                ...filters,
                                                status: 'ERROR',
                                            }),
                                    },
                                    {
                                        caption: cxt.t(
                                            'sc.remittence.onlyReturn',
                                        ),
                                        icon:
                                            filters.status === 'RETURNED' ? (
                                                <DoneIcon />
                                            ) : null,
                                        onClick: () =>
                                            setFilters({
                                                ...filters,
                                                status: 'RETURNED',
                                            }),
                                    },
                                ]}
                            />

                            <SttTable
                                data={lines}
                                loading={false}
                                selectRows={selectRows}
                                setSelectRows={setSelectRows}
                                columns={[
                                    {
                                        title: cxt.t('Concept'),
                                        align: 'left',
                                        value: (row) => (
                                            <Box>
                                                <Link
                                                    onClick={() =>
                                                        setOpenRemittenceLine(
                                                            row,
                                                        )
                                                    }
                                                >
                                                    {row.description}
                                                </Link>
                                                <Box>
                                                    <Typography variant="overline">
                                                        <Link
                                                            onClick={() =>
                                                                setMshipChosen(
                                                                    row.payment
                                                                        ?.user
                                                                        ?.mship
                                                                        .id,
                                                                )
                                                            }
                                                        >
                                                            {row.name}
                                                        </Link>
                                                    </Typography>
                                                </Box>

                                                {row.errors &&
                                                    row.errors.map((error) => (
                                                        <Box
                                                            mt={1}
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            - {error}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ),
                                    },
                                    {
                                        type: 'dmy',
                                        title: cxt.t('Returned'),
                                        field: 'rejected',
                                    },
                                    {
                                        type: 'counter',
                                        title: cxt.t('Errors'),
                                        value: (row) =>
                                            row.errors && row.errors.length,
                                    },
                                    {
                                        type: 'price',
                                        title: cxt.t('Price'),
                                        field: 'price',
                                        value: (row) => (
                                            <Link
                                                onClick={() =>
                                                    setIdPaymentModal(
                                                        row.payment.id,
                                                    )
                                                }
                                            >
                                                {formatPriceByLocale(
                                                    row.price,
                                                    row.priceShape?.currency,
                                                    row.priceShape?.locale,
                                                )}
                                            </Link>
                                        ),
                                    },
                                ]}
                                onFetch={loadLines}
                                selection={{
                                    actions: [
                                        {
                                            caption: cxt.t('Refund'),
                                            onClick: rejectSelectedLines,
                                        },
                                    ],
                                }}
                            />
                        </Paper>
                    </Box>
                )}

                {idPaymentModal && (
                    <PaymentModal
                        idPayment={idPaymentModal}
                        onClose={() => setIdPaymentModal(null)}
                    />
                )}

                <RemittenceLineDialog
                    remittenceLine={openRemittenceLine}
                    onClose={() => setOpenRemittenceLine(null)}
                    onUpdate={updateRemittenceLine}
                />

                <SttUploadDialog
                    open={openUploadFile}
                    types={['REMITTENCE_REJECTIONS']}
                    onClose={() => setOpenUploadFile(false)}
                    uploadParams={{ idRemittence: remittence && remittence.id }}
                    onUploaded={(r) => {
                        const { response } = r;
                        if (
                            response &&
                            response.data &&
                            response.data.remittenceLines &&
                            response.data.remittenceLines.rows
                        )
                            setSelectRows(response.data.remittenceLines.rows);
                        setOpenUploadFile(false);
                        loadLines();
                    }}
                />

                <SttMshipDialog
                    open={mshipChosen !== null}
                    mshipId={mshipChosen}
                    onClose={() => {
                        setMshipChosen(null);
                        loadLines();
                    }}
                />
            </Box>
        </SttFullDialog>
    );
}
