import translations from '../translations';

const constants = {
    payment: {
        privileges: {
            pay: 'pay',
            recover: 'recover',
            delete: 'delete',
            refund: 'refund',
            refundTPV: 'refundTPV',
            modify: 'modify',
        },
        paymentStatus: [
            { caption: 'ERROR', value: 'ERROR' },
            { caption: 'CREATING', value: 'CREATING' },
            { caption: 'PAID', value: 'PAID' },
            { caption: 'PAYING', value: 'PAYING' },
            { caption: 'PENDING', value: 'NOT_PAID' },
            { caption: 'ALL', value: 'ALL' },
        ],
        pendingPaymentForm: '', // Debido al cambio reciente en backend, ahora pendiente no es 'LATER', es '', pero '' no
        // puede indexar por tipo en el resto de la plataforma, donde se ha tenido en cuenta el cambio 'LATER' -> ''
        paymentForms: {
            dataphone: {
                name: 'DATAFONO',
                translationCode: translations.payment.paymentFormDataphone,
            },
            dataphoneConnected: {
                name: 'DATAFONO_CONNECTED',
                translationCode:
                    translations.payment.paymentFormDataphoneConnected,
            },
            cash: {
                name: 'CASH',
                translationCode: translations.payment.paymentFormCash,
            },
            purse: {
                name: 'PURSE',
                translationCode: translations.payment.paymentFormPurse,
            },
            tpv: {
                name: 'TPV',
                translationCode: translations.payment.paymentFormTpv,
            },
            later: {
                name: 'LATER',
                translationCode: translations.payment.paymentFormLater,
            },
            bank: {
                name: 'BANK',
                translationCode: translations.payment.paymentFormBank,
            },
            free: {
                name: 'FREE',
                translationCode: translations.payment.paymentFormFree,
            },
            receipt: {
                name: 'RECEIPT',
                translationCode: translations.payment.paymentFormReceipt,
            },
            zitycard: {
                name: 'ZITYCARD',
                translationCode: translations.payment.paymentFormZitycard,
            },
        },
        status: {
            paid: {
                name: 'PAID',
                translationCode: translations.payment.statusPaid,
            },
            notPaid: {
                name: 'NOT_PAID',
            },
        },
        currency: {
            EUR: 'EUR',
        },
    },
    modules: {
        renting: {
            name: 'RENTING',
            translationCode: translations.module.renting,
        },
        event: {
            name: 'EVENT',
            translationCode: translations.module.event,
        },
        class: {
            name: 'CLASS',
            translationCode: translations.module.class,
        },
        game: {
            name: 'GAME',
            translationCode: translations.module.game,
        },
        abonados: {
            name: 'ABONADOS',
            translationCode: translations.module.abonados,
        },
        purse: {
            name: 'PURSE',
            translationCode: translations.module.purse,
        },
    },
    mship: {
        status: {
            active: 'active',
            inactive: 'inactive',
        },
    },
    sporttiaConfigurations: {
        logLevels: ['error', 'warn', 'info', 'http', 'debug'],
    },
    rate: {
        types: {
            individual: 1,
            complete: 2,
        },
    },
    roles: {
        sportcenter: 'SPORTCENTER',
        admin: 'ADMIN',
        user: 'USER',
        teacher: 'TEACHER',
    },
    regexp: {
        datetime: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
        datetimeISO8601: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/,
        date: /^\d{4}-\d{2}-\d{2}$/,
    },
    unlocker: {
        types: {
            card: 'CARD',
            PIN: 'PIN',
            QR: 'QR',
            noUnlocker: 'NULL',
        },
    },
    group: {
        types: {
            sport: {
                name: 'SPORT',
                translationCode: translations.group.typeSport,
            },
            normal: {
                name: 'NORMAL',
                translationCode: translations.group.typeNormal,
            },
            abonados: {
                name: 'ABONADOS',
                translationCode: translations.group.typeAbonados,
            },
            family: {
                name: 'FAMILY',
                translationCode: translations.group.typeFamily,
            },
        },
    },
    appStores: {
        play: {
            link: 'https://play.google.com/store/apps/details?id=es.sporttia.sporttia',
        },
        apple: {
            link: 'https://apps.apple.com/app/sporttia/id1093457829',
        },
    },
    tpvConf: {
        modes: {
            develop: {
                name: 'DEVELOP',
                translationCode: 'IT.DEVELOPMENT',
            },
            production: {
                name: 'PRODUCTION',
                translationCode: 'IT.PRODUCTION',
            },
        },
        banks: {
            stripe: {
                name: 'STRIPE',
            },
            zitycard: {
                name: 'ZITYCARD',
            },
            redsys: {
                name: 'REDSYS_HMAC256',
            },
            ccm: {
                name: 'CCM',
            },
            paycomet: {
                name: 'PAYCOMET',
            },
        },
        /** Relación centro -→ TpvConf (De momento solo en algunos tpv confs) */
        banksWithManyCenters: ['ZITYCARD'],
    },
    tpv: {
        results: {
            errorPurse: 'RESULT_ERROR_PURSE',
        },
    },
    events: {
        types: {
            tournament: 'TOURNAMENT',
            normal: 'NORMAL',
        },
    },
    gui: {
        objectState: {
            create: 'create',
        },
    },
    activity: {
        status: {
            closed: 'CLOSE',
        },
    },
    bono: {
        bonoExpirationDefault: '0000-00-00 00:00:00',
    },
    report: {
        status: {
            ACTIVE: 'ACTIVE',
            INACTIVE: 'INACTIVE',
        },
    },
} as const;

export default constants;
