import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Paper,
    Box,
    Link,
    Grid,
    LinearProgress,
} from '@material-ui/core';
import useKey from '@rooks/use-key';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import {
    AppContext,
    SttButton,
    SttFormPassword,
    SttFormText,
} from '../../sporttia/all';
import LogoHeading from '../../layout/LogoHeading';
import { getPath } from '../Pages';
import { useQuery } from '../../lib/hooks';

/**
 * Login page
 * ==========
 *
 * Query:
 *
 *	* token: if it's set, login automatically
 * 	* redirect: after loggin in, redirect
 */
export default function Login(props) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { token, redirect } = useQuery();
    const [loginLoading, setLoginLoading] = useState(false);

    /**
     * Press enter
     */
    useKey(['Enter'], () => {
        doLogin();
    });

    /**
     * Attempt to login
     */
    const doLogin = () => {
        setLoginLoading(true);
        cxt.api('POST', '/login', {
            params: {
                login,
                password,
            },
            error: (r) => {
                cxt.showMessage('E', r.error.msg);
                setLoginLoading(false);
            },
            success: (r) => {
                setLoginLoading(false);
                if (r.user) {
                    if (r.user.role === cxt.constants.roleAdmin) {
                        cxt.setLoggedUser(r.user); /* .then(() => {
							redirectUser();
						}); */
                        // redirectUser();
                    } else {
                        switch (r.user.status) {
                            case 'WAIT_EMAIL':
                                // Redirect to the "please verify your email" page with user obj as state param
                                history.push('/email/activation', {
                                    user: r.user,
                                });
                                break;
                            case 'NOT_CONFIRM':
                                // Redirect to TOS confirmation page
                                history.push(
                                    `/privacy/confirmation/${r.user.id}/${r.user.signature}`,
                                );
                                break;
                            case 'ACTIVE':
                                cxt.setLoggedUser(r.user); /* .then(() => {
									redirectUser();
								}); */
                                // redirectUser();
                                break;
                        }
                    }
                }
            },
        });
    };

    // const redirectUser = () => {
    // 	if (redirect) {
    // 		history.push(decodeURIComponent(redirect))
    // 	} else if (props.location.state && props.location.state.from && props.location.state.from.pathname) {
    // 		history.push(props.location.state.from.pathname);
    // 	} else {
    // 		history.push('/');
    // 	}
    // }

    /**
     * Cuando el usuario se loguea se redirige a la pagina inicial dado su rol o a la página que anteriormente
     * consultó pero que necesitaba estar logueado para entrar.
     */
    useEffect(() => {
        if (cxt.user && cxt.user.id) {
            if (redirect) {
                history.push(decodeURIComponent(redirect));
            } else if (
                props.location.state &&
                props.location.state.from &&
                props.location.state.from.pathname
            ) {
                history.push(props.location.state.from.pathname);
            } else {
                history.push('/');
            }
        }
    }, [cxt.user]);

    // /**
    //  * Trying to automatically log in and redirect
    //  */
    // useEffect(() => {
    //
    // 	if (token && redirect) {
    //
    // 		// login with token and redirect
    // 		cxt.api('POST', '/login', {
    // 			params: {
    // 				token: decodeURIComponent(token)
    // 			},
    // 			success: (r) => {
    //
    // 				cxt.setLoggedUser(r.user);
    //
    // 				// JPB, 30 jun 2020. This is a patch, there must be a better way
    // 				// to do this but I'm so rush
    // 				setTimeout(() => {
    // 					history.push(decodeURIComponent(redirect))
    // 				}, 3000)
    // 			}
    // 		});
    // 	}
    //
    // }, [token, redirect])

    // Render
    return (
        <Container maxWidth="xs">
            <Box mt={9}>
                <Paper>
                    <Box p={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LogoHeading
                                    caption={cxt.t('SignInOnSporttia')}
                                />
                            </Grid>

                            {/* --- Login & password --- */}
                            <Grid item xs={12}>
                                <SttFormText
                                    autoFocus
                                    name="login"
                                    caption={cxt.t('page.login.loginOrEmail')}
                                    defVal={login}
                                    onChange={({ value }) => setLogin(value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SttFormPassword
                                    name="password"
                                    caption={cxt.t('Password')}
                                    defVal={password}
                                    onChange={({ value }) => setPassword(value)}
                                />
                            </Grid>

                            {/* --- Button login --- */}
                            <Grid item xs={12}>
                                <SttButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SentimentSatisfiedAltIcon />}
                                    disabled={loginLoading}
                                    loading={loginLoading}
                                    onClick={() => doLogin()}
                                >
                                    {cxt.t('page.login.Enter')}
                                </SttButton>
                            </Grid>

                            {/* --- Recover password --- */}
                            <Grid item xs={12}>
                                <Box>
                                    <Link href="/recover" variant="body2">
                                        {cxt.t('RecoverPassword')}
                                    </Link>
                                </Box>
                            </Grid>

                            {/* --- Sign up --- */}
                            <Grid item xs={12}>
                                <Box>
                                    <Link
                                        href={`${getPath(
                                            'signup',
                                        )}?redirect=${redirect}`}
                                        variant="body2"
                                    >
                                        {`${cxt.t(
                                            'app.login.noAccount',
                                        )} ${cxt.t('RegisterNow')}`}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}
