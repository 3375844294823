import React, { useState, useContext } from 'react';
import { Box, Chip, Avatar } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttTable,
    SttChipUser,
    SttTopControls,
} from '../../../sporttia/all';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import PaymentCreationDialog from '../../../components/dialogs/PaymentCreationDialog';
import { useToggle } from '../../../lib/hooks';
import { commaSeparated } from '../../../lib/utils';
import PayMultiplePaymentsDialog from '../../../components/dialogs/PayMultiplePaymentsDialog';
import { getPaymentForm } from '../../../lib/paymentHelper';

export default function MshipPayments({ mship }) {
    const cxt = useContext(AppContext);
    const [payments, setPayments] = useState();
    const [idPayModal, setIdPayModal] = useState(null);
    const [creatingPayment, setCreatingPayment] = useState(false);
    const [filters, setFilters] = useState({
        concept: '',
        status: '',
        subpayments: false,
        useIni: moment().format('YYYY-MM-01 00:00:00'),
        useEnd: moment().format('YYYY-12-31 23:59:59'),
        paidIni: moment().format('YYYY-MM-01 00:00:00'),
        paidEnd: moment().format('YYYY-12-31 23:59:59'),
        paymentForms: [],
        modules: [],
    });
    const [filterPayDateChecked, setFilterPayDateChecked] = useState(false);
    const [filterUseDateChecked, setFilterUseDateChecked] = useState(false);
    const [selectRows, setSelectRows] = useState([]);
    const [paymentsIds, setPaymentsIds] = useState(null);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadPayments();
    });

    const loadPayments = (p = { page: 1, rows: 25 }) => {
        const paramsFilters = {
            concept: filters.concept,
            status: filters.status,
            subpayments: +filters.subpayments,
            ...(filters.paymentForms.length > 0 && {
                paymentForms: commaSeparated(filters.paymentForms),
            }),
            ...(filters.modules.length > 0 && {
                modules: commaSeparated(filters.modules),
            }),
            ...(filterPayDateChecked && {
                paidIni: filters.paidIni,
                paidEnd: filters.paidEnd,
            }),
            ...(filterUseDateChecked && {
                useIni: filters.useIni,
                useEnd: filters.useEnd,
            }),
        };

        const params = {
            page: p.page,
            rows: p.rows,
            ...paramsFilters,
            trash,
        };

        cxt.api('GET', `/mships/${mship.id}/payments`, {
            params,
            success: (r) => {
                setPayments(r);
            },
        });
    };

    const openPayment = (pay) => {
        setIdPayModal(pay.id);
    };

    const onPaymentSuccess = () => {
        setCreatingPayment(false);
        loadPayments();
    };

    return (
        <>
            <SttTopControls
                mb={2}
                mainAction={{
                    type: 'create',
                    onClick: () => setCreatingPayment(true),
                }}
                extraFields={[
                    {
                        xs: 12,
                        sm: 6,
                        type: 'text',
                        caption: cxt.t('Concept'),
                        name: 'concept',
                        value: filters.concept,
                        onEnterKey: loadPayments,
                    },
                    {
                        xs: 12,
                        sm: 6,
                        type: 'select',
                        caption: cxt.t('Status'),
                        name: 'status',
                        value: filters.status,
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: '',
                            },
                            {
                                caption: cxt.t('Paid'),
                                value: 'PAID',
                            },
                            {
                                caption: cxt.t('Pending'),
                                value: 'NOT_PAID',
                            },
                        ],
                    },
                    {
                        xs: 12,
                        md: 6,
                        type: 'period',
                        caption: cxt.t('DateOfUse'),
                        enabled: filterUseDateChecked,
                        nameIni: 'useIni',
                        valIni: filters.useIni,
                        nameEnd: 'useEnd',
                        valEnd: filters.useEnd,
                        onToggle: setFilterUseDateChecked,
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: moment(value).format(
                                    `YYYY-MM-DD ${
                                        name === 'useIni'
                                            ? '00:00:00'
                                            : '23:59:59'
                                    }`,
                                ),
                            }),
                    },
                    {
                        xs: 12,
                        md: 6,
                        type: 'period',
                        caption: cxt.t('DateOfPayment'),
                        enabled: filterPayDateChecked,
                        nameIni: 'paidIni',
                        valIni: filters.paidIni,
                        nameEnd: 'paidEnd',
                        valEnd: filters.paidEnd,
                        onToggle: setFilterPayDateChecked,
                        onChange: ({ name, value }) =>
                            setFilters({
                                ...filters,
                                [name]: moment(value).format(
                                    `YYYY-MM-DD ${
                                        name === 'paidIni'
                                            ? '00:00:00'
                                            : '23:59:59'
                                    }`,
                                ),
                            }),
                    },
                    {
                        xs: 12,
                        name: 'paymentForms',
                        type: 'selectablechips',
                        caption: cxt.t('paymentForm'),
                        items: cxt.constants.paymentForms.map((pf) => ({
                            label: pf.label,
                            value: pf.id,
                        })),
                        selected: filters.paymentForms,
                    },
                    {
                        xs: 12,
                        name: 'modules',
                        type: 'selectablechips',
                        caption: cxt.t('Module'),
                        items: cxt.constants.modules.map((m) => ({
                            label: m.label,
                            value: m.id,
                        })),
                        selected: filters.modules,
                    },
                    {
                        xs: 12,
                        type: 'checkmulti',
                        caption: cxt.t('OtherOptions'),
                        children: [
                            {
                                name: 'subpayments',
                                caption: cxt.t('ChildPayments'),
                                checked: filters.subpayments,
                                onChange: ({ value }) =>
                                    setFilters({
                                        ...filters,
                                        subpayments: value,
                                    }),
                            },
                        ],
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onToggleTrash={toggleTrash}
                onFilter={loadPayments}
            />

            <SttTable
                data={payments}
                onClickRow={(pay) => {
                    openPayment(pay);
                }}
                selectRows={selectRows}
                setSelectRows={setSelectRows}
                columns={[
                    {
                        field: 'created',
                        title: cxt.t('Created'),
                        width: 120,
                        value: (r) => (
                            <>
                                <SttChipUser user={r.creator} />
                                <Box mt={1}>
                                    {moment(r.created).format('D MMM YYYY')}
                                </Box>
                            </>
                        ),
                    },
                    {
                        field: 'module',
                        title: cxt.t('Module'),
                        type: 'module',
                    },
                    {
                        field: 'concept',
                        title: cxt.t('Concept'),
                        align: 'left',
                        value: (r) => (
                            <>
                                {r.concept}

                                {r.children && (
                                    <Box mt={1}>
                                        <Chip
                                            size="small"
                                            avatar={
                                                <Avatar>
                                                    {r.children.count}
                                                </Avatar>
                                            }
                                            label={cxt.t('Children')}
                                        />
                                    </Box>
                                )}

                                {r.parent && (
                                    <Box mt={1}>
                                        <Chip
                                            size="small"
                                            label={cxt.t('Father')}
                                        />
                                    </Box>
                                )}
                            </>
                        ),
                    },
                    {
                        field: 'period',
                        title: cxt.t('Period'),
                        value: (r) => ({ ini: r.ini, end: r.end }),
                        type: 'period',
                    },
                    {
                        field: 'collector',
                        title: cxt.t('Paid'),
                        width: 120,
                        value: (r) =>
                            r.paymentDate && (
                                <>
                                    <SttChipUser user={r.collector} />
                                    <Box mt={1}>
                                        {moment(r.paymentDate).format(
                                            'D MMM YYYY',
                                        )}
                                    </Box>
                                </>
                            ),
                    },
                    {
                        field: 'paymentForm',
                        title: cxt.t('PForm'),
                        type: 'pf',
                        value: (row) =>
                            getPaymentForm({
                                trash: row.trash,
                                paymentForm: row.paymentForm,
                            }),
                    },
                    {
                        field: 'price',
                        title: cxt.t('Price'),
                        type: 'price',
                    },
                ]}
                onFetch={loadPayments}
                selection={{
                    actions: [
                        {
                            caption: cxt.t('Pay'),
                            onClick: () =>
                                setPaymentsIds(
                                    selectRows.map((payment) => payment?.id),
                                ),
                        },
                    ],
                }}
            />

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => {
                        loadPayments();
                        setIdPayModal(null);
                    }}
                />
            )}

            <PaymentCreationDialog
                open={creatingPayment}
                mship={mship}
                onClose={() => setCreatingPayment(false)}
                onPaymentSuccess={onPaymentSuccess}
            />

            <PayMultiplePaymentsDialog
                paymentsIds={paymentsIds}
                userID={mship.user.id}
                onClose={() => setPaymentsIds(null)}
                onCreate={(response) => {
                    setIdPayModal(response?.payment?.id);
                    setPaymentsIds(null);
                }}
            />
        </>
    );
}
