import React, { useState, useEffect, useContext } from 'react';
import { Container, Box } from '@material-ui/core';
import {
    AppContext,
    SttLabelPeriod,
    SttLabelDate,
    SttPage,
} from '../../../sporttia/all';
import { formatPriceByLocale } from '../../../lib/utils';

export default function UserAbono(props) {
    const cxt = useContext(AppContext);
    const [groupMember, setGroupMember] = useState();

    /**
     * Load abono
     */
    const loadGroupMember = () => {
        cxt.api('GET', `/groups/members/${props.match.params.id}`, {
            success: (r) => {
                setGroupMember(r.groupMember);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        loadGroupMember();
    }, []);

    // -----| Render |-----
    return (
        <Container maxWidth="md">
            <SttPage innerTitle={cxt.t('Abono')}>
                {groupMember && (
                    <Box mt={2}>
                        <Box>
                            {cxt.t('BuyedDay')}:{' '}
                            <SttLabelDate date={groupMember.created} />
                        </Box>

                        {groupMember.enrollmentPrice && (
                            <Box>
                                {cxt.t('Price')}:{' '}
                                <b>
                                    {formatPriceByLocale(
                                        groupMember.enrollmentPrice,
                                        groupMember?.enrollmentPriceShape
                                            ?.currency,
                                        groupMember?.enrollmentPriceShape
                                            ?.locale,
                                    )}
                                </b>
                            </Box>
                        )}

                        <Box>
                            {cxt.t('Period')}:
                            <SttLabelPeriod
                                ini={groupMember.ini}
                                end={groupMember.end}
                            />
                        </Box>
                    </Box>
                )}
            </SttPage>
        </Container>
    );
}
