import React, { useState } from 'react';
import { Box, Grid, Chip, CircularProgress } from '@material-ui/core';
import {
    SttDialog,
    SttList,
    SttFormSearch,
    SttPagination,
    SttLabelPeriod,
    SttNoResults,
} from '../all';
import useGroupsService from '../../services/GroupsService';
import { isDefined } from '../../lib/utils';
import SttError from '../../components/error/SttError';
import { Group } from '../../types/api/models';

type SttSelectGroupDialogProps = {
    open?: boolean;
    onClose: () => void;
    onSelect: (group: Group) => void;
    types?: string;
    onlyActive?: boolean;
};

/**
 * Select group dialog
 */
export function SttSelectGroupDialog({
    open = false,
    onClose,
    onSelect,
    types = '',
    onlyActive = false,
}: SttSelectGroupDialogProps) {
    const [searchForm, setSearchForm] = useState('');

    const [getGroupsPage, setGetGroupsPage] = useState(1);
    const [getGroupsFilters, setGetGroupsFilters] = useState({
        name: '',
    });

    const groupsService = useGroupsService();
    const getGroupsQuery = groupsService.useGetGroups({
        types,
        page: getGroupsPage,
        rows: 7,
        includeChildren: true,
        ...(onlyActive ? { onlyActive } : { inTime: true, inFuture: true }),
        ...getGroupsFilters,
    });

    // Handler to ensure page is reset to the first page when the filter is changed
    const handleOnFilter = ({
        name,
        value,
    }: {
        name: string;
        value: string;
    }) => {
        setGetGroupsPage(1);
        setGetGroupsFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    if (!open) {
        return null;
    }

    if (getGroupsQuery.isError) {
        return (
            <SttDialog
                open
                // @ts-expect-error Migrate SttDialog
                minHeight="60%"
                // @ts-expect-error Migrate SttDialog
                maxHeight="60%"
                onClose={onClose}
                maxWidth="md"
                content={
                    <Box paddingY={12}>
                        <SttError />
                    </Box>
                }
            />
        );
    }

    return (
        <SttDialog
            open
            // @ts-expect-error Migrate SttDialog
            minHeight="60%"
            // @ts-expect-error Migrate SttDialog
            maxHeight="60%"
            onClose={onClose}
            maxWidth="md"
            content={
                <>
                    <SttFormSearch
                        defVal={searchForm}
                        onClean={() => {
                            setSearchForm('');
                            handleOnFilter({ name: 'name', value: '' });
                        }}
                        onChange={({ value }) => {
                            setSearchForm(value);
                        }}
                        onClickSearch={() => {
                            handleOnFilter({
                                name: 'name',
                                value: searchForm,
                            });
                        }}
                    />

                    {getGroupsQuery.isLoading && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={25}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {getGroupsQuery.isSuccess &&
                        getGroupsQuery.data.count > 0 && (
                            // @ts-expect-error SttList
                            <SttList
                                onSelect={(group: Group) => onSelect(group)}
                                data={getGroupsQuery.data.rows.map((group) => ({
                                    caption: (
                                        <Grid container>
                                            <Grid item md={8}>
                                                {group.name}
                                                {isDefined(
                                                    group.numActiveUsers,
                                                ) && (
                                                    <Box
                                                        ml={2}
                                                        display="inline"
                                                    >
                                                        <Chip
                                                            label={
                                                                group.numActiveUsers
                                                            }
                                                            size="small"
                                                        />
                                                    </Box>
                                                )}
                                            </Grid>
                                            <Grid item md={3}>
                                                <SttLabelPeriod
                                                    ini={group.ini}
                                                    end={group.end}
                                                />
                                            </Grid>
                                        </Grid>
                                    ),
                                    text: group.description,
                                    objToSelect: group,
                                }))}
                            />
                        )}

                    {getGroupsQuery.data?.count === 0 && (
                        <Box paddingY={12}>
                            <SttNoResults />
                        </Box>
                    )}
                </>
            }
            buttons={
                getGroupsQuery.isSuccess &&
                getGroupsQuery.data.count > 0 && [
                    {
                        type: 'component',
                        show: true,
                        component: (
                            <Box m="auto">
                                <SttPagination
                                    page={getGroupsPage}
                                    pages={getGroupsQuery.data.pages}
                                    count={getGroupsQuery.data.count}
                                    onChangePage={(page) => {
                                        setGetGroupsPage(page);
                                    }}
                                />
                            </Box>
                        ),
                    },
                ]
            }
        />
    );
}
