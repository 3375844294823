import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Switch, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import PaymentIcon from '@material-ui/icons/Payment';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AdjustIcon from '@material-ui/icons/Adjust';
import {
    AppContext,
    SttLabelDate,
    SttList,
    SttPaymentList,
} from '../../../sporttia/all';
import { useToggle } from '../../../lib/hooks';
import PaymentCreationDialog from '../PaymentCreationDialog';
import { SttButton } from '../../../sporttia/buttons/SttButton';
import UseBonoDialog from '../UseBonoDialog';
import BoletoDialog from '../BoletoDialog';

export default function BookingDialogPayments({ booking, onUpdate }) {
    const cxt = useContext(AppContext);
    const [payments, setPayments] = useState();
    const [bonoMovs, setBonoMovs] = useState();
    const [creatingPayment, setCreatingPayment] = useState(false);
    const [useBoleto, setUseBoleto] = useState(false);
    const [openedBoleto, setOpenedBoleto] = useState();

    /**
     * Booking changes
     */
    useEffect(() => {
        if (booking && booking.id) {
            // eslint-disable-next-line no-use-before-define
            loadPayments();
            // eslint-disable-next-line no-use-before-define
            loadBonoMovs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking]);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadPayments();
        // eslint-disable-next-line no-use-before-define
        loadBonoMovs();
    });

    /**
     * Load payments
     */
    const loadPayments = () => {
        cxt.api('GET', `/bookings/${booking.id}/payments`, {
            params: { page: 1, rows: 100, trash },
            success: (r) => {
                setPayments(r);
            },
        });
    };

    /**
     * Load bono movs
     */
    const loadBonoMovs = () => {
        cxt.api('GET', `/bookings/${booking.id}/bonos/movs`, {
            params: { page: 1, rows: 100, trash },
            success: (r) => {
                setBonoMovs(r);
            },
        });
    };

    /**
     * Close booking
     */
    const closeBooking = () => {
        cxt.api('PUT', `/bookings/${booking.id}/close`, {
            confirmation: true,
            success: (r) => {
                onUpdate(r.booking);
            },
        });
    };

    // -----| Render |-----
    return (
        <>
            <Box
                mb={3}
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <Box display="flex">
                    <Box mr={1}>
                        <SttButton
                            color="primary"
                            variant="contained"
                            icon={<PaymentIcon />}
                            caption={cxt.t('AddPayment')}
                            onClick={() => setCreatingPayment(true)}
                        />
                    </Box>
                    <Box mr={1}>
                        <SttButton
                            color="primary"
                            variant="contained"
                            icon={<ConfirmationNumberIcon />}
                            caption={cxt.t('UseFare')}
                            onClick={() => setUseBoleto(true)}
                        />
                    </Box>
                    <Box mr={1}>
                        <SttButton
                            color="primary"
                            variant="contained"
                            icon={<AdjustIcon />}
                            caption={cxt.t('Close')}
                            onClick={() => closeBooking()}
                        />
                    </Box>
                </Box>
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                undefined /* We don't care about this value because the switch has its own state and this will always be false first */
                            }
                            onChange={(ev) => toggleTrash(ev.target.checked)}
                        />
                    }
                    label={cxt.t('Trash')}
                />
            </Box>

            {/* --- Payments --- */}
            <Box>
                {payments && payments.rows && (
                    <SttPaymentList
                        clickable
                        paper={false}
                        payments={payments.rows}
                        onClose={loadPayments}
                    />
                )}
            </Box>

            {/* --- Bonos --- */}
            {bonoMovs && bonoMovs.rows && (
                <Box mt={2}>
                    <Box mt={2}>
                        <SttList
                            paper={false}
                            title={cxt.t('Bonos')}
                            onSelect={(bonoMov) =>
                                setOpenedBoleto(bonoMov.boleto)
                            }
                            data={bonoMovs.rows.map((bonoMov) => ({
                                caption: (
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            {bonoMov.boleto.user.name}
                                            <Box
                                                style={{
                                                    fontSize: 13,
                                                    color: '#aaa',
                                                }}
                                            >
                                                <b>
                                                    <SttLabelDate
                                                        date={bonoMov.created}
                                                    />
                                                </b>
                                                , {bonoMov.concept},{' '}
                                                {bonoMov.boleto.bono.name},{' '}
                                                {moment(bonoMov.created).format(
                                                    'DD-MM-YYYY HH:mm',
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ),
                                objToSelect: bonoMov,
                            }))}
                        />
                    </Box>
                </Box>
            )}

            <PaymentCreationDialog
                open={creatingPayment}
                // En este caso particular fullName viene en 'user' en vez de en 'mship' (que es donde nos hace falta)
                // Además, extrañamente necesitaremos la id del user en un objeto mship.user...
                mship={
                    booking.user && {
                        ...booking.user.mship,
                        fullName: booking.user.fullName,
                        user: { id: booking.user.id },
                    }
                }
                // TODO: mover 'fullName' al mship, como en otras partes (requiere backend)
                targetAPI={`/bookings/${booking.id}/payments`}
                onPaymentSuccess={() => {
                    loadPayments();
                    setCreatingPayment(false);
                }}
                onClose={() => setCreatingPayment(false)}
            />

            <UseBonoDialog
                open={useBoleto}
                defaultMship={booking.user}
                bookingId={booking.id}
                onClose={() => {
                    setUseBoleto(false);
                    loadBonoMovs();
                }}
            />

            {openedBoleto && (
                <BoletoDialog
                    item={openedBoleto}
                    onClose={() => setOpenedBoleto(false)}
                />
            )}
        </>
    );
}
