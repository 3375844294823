import React, { useState, useContext } from 'react';
import moment from 'moment';
import { CloudDownload } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Link } from '@material-ui/core';
import SttMshipDialog from '../../../sporttia/dialogs/SttMshipDialog';
import { fullName } from '../../../lib/utils';
import BoletoDialog from '../../../components/dialogs/BoletoDialog';
import {
    useToggle,
    useTuples,
    useCrud,
    useInteractionsFiles,
} from '../../../lib/hooks';
import {
    AppContext,
    SttTable,
    SttTopControls,
    SttLabelPayment,
} from '../../../sporttia/all';
import { getPaymentForm } from '../../../lib/paymentHelper';

const useStyles = makeStyles({
    infoButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function BonoBoletos({ item }) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const classes = useStyles();

    const [boletos, setBoletos, , Get] = useCrud();
    const [viewBoleto, setViewBoleto] = useState();
    const [filters, setFilter] = useTuples({
        userName: '',
    });
    const [selectedMshipId, setSelectedMshipId] = useState(null);

    // On trash toggle, load list of boletos
    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadBoletos();
    });

    // On active/all toggle, load list of boletos
    const [isActive, toggleActive] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadBoletos();
    }, false);

    // load boletos from API
    function loadBoletos(params = { page: 1, rows: 20 }) {
        if (item) {
            Get(`/bonos/${item.id}/boletos`, {
                ...params,
                ...filters,
                trash,
                isActive,
            }).then(setBoletos);
        }
    }

    // Download list of boletos as PDF
    function downloadPdf() {
        downloadFile(
            `/bonos/${item.id}/boletos.pdf`,
            'application/pdf',
            { ...filters, trash, isActive },
            `${item.name}_${cxt.t('Users')}_${moment().format(
                'DD-MM-YYYY',
            )}.pdf`,
        );
    }

    // Same, but as CSV
    function downloadCsv() {
        downloadFile(
            `/bonos/${item.id}/boletos.csv`,
            'text/csv',
            { ...filters, trash, isActive },
            `${item.name}_${cxt.t('Users')}_${moment().format(
                'DD-MM-YYYY',
            )}.csv`,
        );
    }

    function openViewNewBoleto() {
        setViewBoleto(item);
    }

    return (
        <>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        type: 'search',
                        name: 'userName',
                        value: filters.userName,
                        onEnterKey: loadBoletos,
                    },
                    {
                        caption: cxt.t('Type'),
                        type: 'select',
                        name: 'isActive',
                        value: (+isActive).toString(),
                        options: [
                            {
                                caption: cxt.t('All'),
                                value: '0',
                            },
                            {
                                caption: cxt.t('Active'),
                                value: '1',
                            },
                        ],
                        onChange: ({ value }) =>
                            toggleActive(!!parseInt(value)),
                    },
                ]}
                menu={[
                    {
                        caption: `${cxt.t('Download')} PDF`,
                        onClick: downloadPdf,
                    },
                    {
                        caption: `${cxt.t('Download')} CSV`,
                        onClick: downloadCsv,
                    },
                ]}
                menuIcon={<CloudDownload />}
                mainAction={{
                    type: 'create',
                    onClick: openViewNewBoleto,
                }}
                onToggleTrash={toggleTrash}
                onChange={setFilter}
                onFilter={loadBoletos}
            />
            <SttTable
                onFetch={loadBoletos}
                data={boletos}
                columns={[
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        value: (row) => (
                            <Link
                                onClick={() => setViewBoleto(row)}
                                variant="body2"
                            >
                                {row.user ? fullName(row.user) : 'Sin usuario'}
                            </Link>
                        ),
                    },
                    {
                        title: cxt.t('Active'),
                        type: 'binaryStatus',
                        value: (row) =>
                            row.consumed < row.amount &&
                            row.amount + row.consumed > 0 &&
                            (!row.expiration ||
                                moment().isBefore(
                                    moment(
                                        `${moment(row.expiration).format(
                                            'YYYY-MM-DD',
                                        )} ${item?.timeend}`,
                                    ),
                                )) &&
                            moment().isBefore(
                                moment(`${row.bono.dateend} ${item?.timeend}`),
                            ),
                    },
                    {
                        title: cxt.t('Consumed'),
                        type: 'text',
                        field: 'consumed',
                    },
                    {
                        title: cxt.t('Total'),
                        type: 'text',
                        field: 'amount',
                    },
                    {
                        title: cxt.t('Expiration'),
                        type: 'dmy',
                        field: 'expiration',
                    },
                    {
                        title: cxt.t('PaymentForm'),
                        type: 'pf',
                        value: (row) => getPaymentForm(row.payment),
                    },
                    {
                        title: cxt.t('Price'),
                        align: 'right',
                        type: 'price',
                        value: (row) => (
                            <SttLabelPayment
                                paymentDialogActive
                                payment={row.payment}
                                onClose={() => {
                                    loadBoletos();
                                }}
                            />
                        ),
                    },
                    {
                        title: '',
                        value: (row) => (
                            <PersonOutlineOutlinedIcon
                                fontSize="small"
                                className={classes.infoButton}
                                onClick={() =>
                                    setSelectedMshipId(row.user.mship.id)
                                }
                            />
                        ),
                    },
                ]}
            />

            {viewBoleto && (
                <BoletoDialog
                    item={viewBoleto}
                    onSave={loadBoletos}
                    onClose={() => {
                        setViewBoleto(null);
                        loadBoletos();
                    }}
                />
            )}

            <SttMshipDialog
                open={selectedMshipId !== null}
                mshipId={selectedMshipId}
                onClose={() => {
                    setSelectedMshipId(null);
                    loadBoletos();
                }}
            />
        </>
    );
}
