import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { SttTopControls } from '../../../sporttia/SttTopControls';
import Group from '../../sc/groups/Group';
import { copyToClipboard, formatPriceByLocale } from '../../../lib/utils';
import { SttChipGroup } from '../../../sporttia/chips/SttChipGroup';
import { AppContext, SttTable } from '../../../sporttia/all';
import { useToggle, useTuples } from '../../../lib/hooks';
import { getPath } from '../../Pages';
/**
 * Table with "Abonados" or "Normal" groups.
 * @param type [ABONADOS or NORMAL] type.
 * @returns {JSX.Element}
 */

function getFormattedPriceFromAbono(row) {
    if (row.enrollment) {
        return formatPriceByLocale(
            row.enrollment.price,
            row.enrollment.priceShape?.currency,
            row.enrollment.priceShape?.locale,
        );
    }
    if (row.group?.fee) {
        return formatPriceByLocale(
            row.group.fee,
            row.group.feeShape?.currency,
            row.group.feeShape?.locale,
        );
    }

    return null;
}

export default function UserAbonosTable({ type }) {
    const cxt = useContext(AppContext);
    const history = useHistory();

    const [groups, setGroups] = useState();

    /**
     * Load groups data.
     * @param p {{page: number, rows: number}}
     */
    const loadGroups = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', '/my/groups/members', {
            params: {
                ...p,
                type,
                onlyActive: true,
                onTime: true,
            },
            success: (r) => {
                setGroups(r);
            },
        });
    };

    return (
        <SttTable
            data={groups}
            loading={false}
            onClickRow={(groupMember) =>
                history.push(getPath('userAbono', { id: groupMember.id }))
            }
            columns={[
                {
                    title: cxt.t('Name'),
                    field: 'groupName',
                    align: 'left',
                    value: (row) => row.group.name,
                },
                {
                    title: cxt.t('Period'),
                    type: 'period',
                    value: (row) => ({ ini: row.ini, end: row.end }),
                },
                {
                    title: cxt.t('Inscription'),
                    type: 'price',
                    value: (row) => getFormattedPriceFromAbono(row),
                },
            ]}
            onFetch={loadGroups}
        />
    );
}
