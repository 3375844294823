import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Paper,
    Typography,
    Box,
    Grid,
    Button,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import {
    AppContext,
    SttFormCheck,
    SttFormPassword,
    SttFormText,
    SttFormSelect,
} from '../../sporttia/all';
import LogoHeading from '../../layout/LogoHeading';
import { useQuery, useLoader } from '../../lib/hooks';

const useStyles = makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const lang = navigator.language || navigator.userLanguage;
let previousLang = '';

// Pantalla de registro
export default function Register() {
    const cxt = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const { redirect } = useQuery();
    const [registerStatus, setRegisterStatus] = useState('idle');
    const [, loader] = useLoader(registerStatus);

    const userLang = cxt?.lang ? cxt.lang.key : lang;

    // Params that will be sent to the API
    const [params, setParams] = useState({
        name: '',
        surname1: '',
        surname2: '',
        email: '',
        password: '',
        gender: 'UNKNOWN',
    });

    // Checkboxes for "I accept stuff" and "I'm older than 14"
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [olderThan14, setOlderThan14] = useState(false);

    // Set the the param 'name' to 'value'
    function handleChange({ name, value }) {
        setParams({ ...params, [name]: value });
    }

    useEffect(() => {
        if (cxt.config && cxt.config.languages) {
            if (lang && lang !== previousLang) {
                const langCode = lang.split('-');

                const currentLang = cxt.config.languages.find(
                    (langKey) => langKey.key === langCode[0],
                );

                if (currentLang) {
                    cxt.setLang(currentLang);
                }

                previousLang = lang;
            } else {
                localStorage.removeItem('currentLanguage');
            }
        }
    }, [cxt]);

    // Do call the API. Must've checked both checkboxes
    function register() {
        if (!privacyChecked || !olderThan14) {
            cxt.showMessage('E', cxt.t('app.register.butThouMust'));
        } else {
            setRegisterStatus('loading');
            cxt.api('POST', `/users`, {
                params: {
                    ...params,
                    language: userLang,
                },
                success: (response) => {
                    setRegisterStatus('idle');
                    if (response.user) {
                        // On success, we move to the 'email confirmation' screen, passing state: {user}
                        history.replace(`/email/activation`, {
                            user: response.user,
                        });
                    }
                },
                error: (response) => {
                    setRegisterStatus('idle');
                    if (response.error) {
                        cxt.showMessage('E', response.error.msg);
                    }
                },
            });
        }
    }
    function onLanguangeChange(langOnChange) {
        const currentLang = cxt.config.languages.find(
            (post) => post.key === langOnChange.value,
        );

        cxt.setLang(currentLang);
    }
    /**
     * Logged user changes
     */
    useEffect(() => {
        if (cxt.user && cxt.user.id && redirect) {
            history.push(decodeURIComponent(redirect));
        }
    }, [cxt.user, history, redirect]);

    // Render
    return (
        <>
            {loader}
            <Container maxWidth="sm">
                <Box mt={8}>
                    <Paper>
                        <Box p={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <LogoHeading
                                        caption={cxt
                                            .t('app.register.title')
                                            .toUpperCase()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormSelect
                                        name="Language"
                                        caption={cxt.t('Language')}
                                        defVal={userLang}
                                        options={
                                            cxt?.config?.languages
                                                ? cxt?.config?.languages
                                                : []
                                        }
                                        onChange={(event) =>
                                            onLanguangeChange(event)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormText
                                        name="name"
                                        caption={cxt.t('Name')}
                                        defVal={params.name}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormText
                                        name="surname1"
                                        caption={cxt.t('surname1')}
                                        defVal={params.surname1}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormText
                                        name="surname2"
                                        caption={cxt.t('surname2')}
                                        defVal={params.surname2}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormSelect
                                        name="gender"
                                        caption={cxt.t('Gender')}
                                        defVal={params.gender}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                        options={[
                                            {
                                                caption: cxt.t('PT.SELECT'),
                                                value: '',
                                            },
                                            {
                                                caption: cxt.t('MALE'),
                                                value: 'MALE',
                                            },
                                            {
                                                caption: cxt.t('FEMALE'),
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormText
                                        name="email"
                                        caption={cxt.t('Email')}
                                        defVal={params.email}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SttFormPassword
                                        name="password"
                                        caption={cxt.t('Password')}
                                        defVal={params.password}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SttFormCheck
                                        caption={
                                            <span>
                                                {cxt.t(
                                                    'app.register.acceptTerms1',
                                                )}{' '}
                                                <Link href="/privacy">
                                                    {cxt.t(
                                                        'app.register.acceptTerms3',
                                                    )}
                                                </Link>
                                            </span>
                                        }
                                        checked={privacyChecked}
                                        onChange={({ value }) =>
                                            setPrivacyChecked(value)
                                        }
                                    />
                                    <SttFormCheck
                                        caption={cxt.t(
                                            'view.signup.olderThan14',
                                        )}
                                        checked={olderThan14}
                                        onChange={({ value }) =>
                                            setOlderThan14(value)
                                        }
                                    />
                                    <Box>
                                        <Typography variant="body2">
                                            {cxt.t(
                                                'view.signup.olderThan14.desc',
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        startIcon={<HowToRegIcon />}
                                        onClick={(event) => register(event)}
                                    >
                                        {cxt.t('sc.register.title')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        pr={2}
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Link
                                            href="/login"
                                            variant="body2"
                                        >{`${cxt.t(
                                            'register.alreadyHaveOne',
                                        )} ${cxt.t(
                                            'register.loginHere',
                                        )}`}</Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </>
    );
}
