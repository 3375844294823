import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {
    AppContext,
    SttTable,
    SttTopControls,
    SttChipPurseMov,
    SttChipUser,
} from '../../../sporttia/all';
import PaymentModal from '../../../components/paymentModal/PaymentModal';
import ChargePurseDialog from '../../../components/dialogs/ChargePurseDialog';

export default function MshipPurseMovs({ mship }) {
    const cxt = useContext(AppContext);
    const [movs, setMovs] = useState({});
    const [idPayModal, setIdPayModal] = useState(null);
    const [chargingPurseMship, setChargingPurseMship] = useState(null);
    const [searchingkeyword, setSearchingKeyword] = useState('');
    const defaultNumRows = 20;

    useEffect(() => {
        loadMovs();
    }, [mship]);

    const loadMovs = (
        p = { page: 1, numRows: defaultNumRows, concept: '' },
    ) => {
        cxt.api('GET', `/mships/${mship.id}/purses/movs`, {
            params: {
                page: p.page,
                rows: p.numRows,
                concept: searchingkeyword,
            },
            success: (r) => {
                setMovs(r);
            },
        });
    };

    const handleSearch = ({ searchText }) => {
        loadMovs({ page: 1, numRows: defaultNumRows, concept: searchText });
    };

    const openPayment = (pay) => {
        setIdPayModal(pay.id);
    };

    return (
        <>
            <SttTopControls
                mb={2}
                fields={[
                    {
                        type: 'text',
                        name: 'searchingkeyword',
                        caption: cxt.t('Search'),
                        value: searchingkeyword,
                        onChange: ({ value }) => setSearchingKeyword(value),
                    },
                ]}
                onFilter={handleSearch}
                mainAction={{
                    type: 'create',
                    onClick: () => setChargingPurseMship(mship),
                }}
            />

            <SttTable
                data={movs}
                onClickRow={(mov) => {
                    openPayment(mov.payment);
                }}
                columns={[
                    {
                        field: 'created',
                        title: cxt.t('Created'),
                        width: 140,
                        value: (r) => (
                            <>
                                <SttChipUser user={r.creator} />
                                <Box mt={1}>
                                    {moment(r.created).format(
                                        'D MMM YYYY, HH:mm',
                                    )}
                                </Box>
                            </>
                        ),
                    },
                    {
                        field: 'type',
                        title: cxt.t('Type'),
                        value: (r) => <SttChipPurseMov type={r.type} />,
                        align: 'center',
                    },
                    {
                        field: 'concept',
                        title: cxt.t('Concept'),
                        align: 'left',
                    },
                    {
                        field: 'pf',
                        title: cxt.t('PaymentForm'),
                        value: (r) => r.payment.paymentForm,
                        type: 'pf',
                    },
                    { field: 'amount', title: cxt.t('Amount'), type: 'price' },
                    {
                        field: 'balance',
                        title: cxt.t('Balance'),
                        type: 'price',
                    },
                ]}
                onFetch={loadMovs}
            />

            {idPayModal && (
                <PaymentModal
                    idPayment={idPayModal}
                    onClose={() => setIdPayModal(null)}
                />
            )}

            {chargingPurseMship && (
                <ChargePurseDialog
                    mship={chargingPurseMship}
                    onClose={() => setChargingPurseMship(null)}
                    onSuccess={() => loadMovs()}
                />
            )}
        </>
    );
}
