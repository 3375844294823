import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    CameraAltOutlined,
    CheckCircleOutline,
    OpenInNewOutlined,
} from '@material-ui/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
    AppContext,
    SttFormDate,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttSportSelector,
    SttFormTime,
    SttFormPeriod,
    SttFormCheck,
    SttButtonSave,
    SttButtonDelete,
    SttButton,
    SttLabelForm,
    SttButtonRecover,
} from '../../../sporttia/all';
import { getPath } from '../../Pages';
import AttendancesDialog from './AttendancesDialog';
import { useCrud } from '../../../lib/hooks';
import SttTagsList from '../../../sporttia/lists/SttTagsList';
import { getObjectDiff } from '../../../lib/utils';

export default function ActivityDetails({
    item,
    teachers = [],
    onSave,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const [activity, setActivity, setProperty, , Post, Put, Delete] =
        useCrud(item);
    const [currentActivityState, setCurrentActivityState] = useState(activity);
    const [dialog, setDialog] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const history = useHistory();

    function setInscDate(name, date) {
        if (!moment(date).isValid()) {
            return;
        }

        const current = moment(activity[name]);

        setProperty({
            name,
            value: `${moment(date).format('YYYY-MM-DD')} ${current.format(
                'HH:mm:ss',
            )}`,
        });
    }

    function setInscTime(name, time) {
        const current = moment(activity[name]);

        setProperty({ name, value: `${current.format('YYYY-MM-DD')} ${time}` });
    }

    function setSport(sport) {
        setProperty({
            name: 'sport',
            value: { id: sport.id, name: sport.name },
        });
    }

    function setTeacher(teacherId) {
        if (!teacherId) {
            setProperty({
                name: 'teacher',
                value: undefined,
            });
        } else {
            const teacher = teachers.find((t) => t.id === teacherId);

            setProperty({
                name: 'teacher',
                value: { id: teacher.id, name: teacher.name },
            });
        }
    }

    function saveActivity() {
        setSaveLoading(true);
        if (activity.id) {
            const updatedActivityFields = getObjectDiff(
                activity,
                currentActivityState,
            );

            Put(`/activities/${activity.id}`, {
                name: updatedActivityFields.name,
                description: updatedActivityFields.description,
                status: updatedActivityFields.status,
                type: updatedActivityFields.type,
                idSport: updatedActivityFields.sport?.id,
                idTeacher: updatedActivityFields.teacher?.id,
                maxStudents: updatedActivityFields.maxStudents,
                inscIni: updatedActivityFields.inscIni,
                inscEnd: updatedActivityFields.inscEnd,
                ini: updatedActivityFields.ini,
                end: updatedActivityFields.end,
                showSignedUsers: updatedActivityFields.showSignedUsers,
            })
                .then((response) => {
                    if (response.activity) {
                        setActivity(response.activity);
                        setCurrentActivityState(response?.activity);

                        onSave(response.activity);
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setActivity(currentActivityState);
                    setSaveLoading(false);
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        } else {
            const newActivity = {
                name: activity.name,
                description: activity.description,
                status: activity.status,
                type: activity.type,
                idSport: activity.sport ? activity.sport.id : null,
                maxStudents: activity.maxStudents,
                inscIni: activity.inscIni,
                inscEnd: activity.inscEnd,
                ini: activity.ini,
                end: activity.end,
                showSignedUsers: activity.showSignedUsers,
            };

            if (activity?.teacher) {
                newActivity.idTeacher = activity.teacher.id;
            }

            Post(`/activities`, newActivity)
                .then((response) => {
                    if (response.activity) {
                        setActivity(response.activity);
                        onSave(response.activity);
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        }
    }

    function deleteActivity() {
        setDeleteLoading(true);
        Delete(`/activities/${activity.id}`)
            .then((response) => {
                if (response.activity) {
                    setActivity({ ...activity, trash: true });
                    onDelete(response.activity);
                }
                setDeleteLoading(false);
            })
            .catch(() => {
                setDeleteLoading(false);
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    }

    function duplicate() {
        Post(`/activities/${activity.id}/duplicate`)
            .then((response) => {
                if (response.activity) {
                    setActivity(response.activity);
                    onSave(response.activity);
                }
            })
            .catch(() => {})
            .finally(() => {});
    }

    function recoverActivity() {
        Put(`/activities/${activity.id}/recover`).then((response) => {
            if (response.activity) {
                setActivity(response.activity);
                onSave(response.activity);
            }
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <SttLabelForm
                    title={cxt.t('Created')}
                    text={moment(activity.created).format('DD MMMM YYYY')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SttSportSelector
                    name="sport"
                    fullWidth
                    defVal={activity.sport && activity.sport.name}
                    onChange={(sport) => setSport(sport)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SttFormSelect
                    name="status"
                    caption={cxt.t('Status')}
                    defVal={activity.status}
                    options={[
                        {
                            caption: cxt.t(`CS.OPEN`),
                            value: 'OPEN',
                        },
                        {
                            caption: cxt.t(`CS.CLOSE`),
                            value: 'CLOSE',
                        },
                    ]}
                    onChange={setProperty}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SttFormSelect
                    name="type"
                    caption={cxt.t('Type')}
                    defVal={activity.type}
                    options={[
                        {
                            caption: cxt.t(`CT.COURSE`),
                            value: 'COURSE',
                        },
                        {
                            caption: cxt.t(`CT.PUNCTUAL`),
                            value: 'PUNCTUAL',
                        },
                    ]}
                    onChange={setProperty}
                />
            </Grid>
            <Grid item xs={12}>
                <SttFormText
                    caption={cxt.t('Name')}
                    name="name"
                    defVal={activity.name}
                    onChange={setProperty}
                />
            </Grid>

            {/* --- Description --- */}
            <Grid item xs={12}>
                <SttFormTextarea
                    caption={cxt.t('Description')}
                    name="description"
                    defVal={activity.description}
                    rows={6}
                    onChange={setProperty}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
                <SttFormSelect
                    name="teacher"
                    caption={cxt.t('Teacher')}
                    defVal={activity.teacher ? activity.teacher.id : undefined}
                    options={teachers.map((teacher) => ({
                        caption: teacher.fullName,
                        value: teacher.id,
                    }))}
                    onChange={({ value }) => setTeacher(value)}
                    cleanable
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <SttFormText
                    name="maxStudents"
                    caption={cxt.t('sc.class.main.maxStudents')}
                    defVal={activity.maxStudents}
                    onChange={setProperty}
                />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
                <SttFormDate
                    caption={cxt.t('sc.class.main.signupIni')}
                    name="inscIni"
                    defVal={activity.inscIni}
                    onChange={({ name, value }) => setInscDate(name, value)}
                />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
                <SttFormTime
                    name="inscIni"
                    defVal={moment(activity.inscIni).format('HH:mm')}
                    min="00:00:00"
                    max="23:45"
                    interval={15}
                    onChange={({ name, value }) => setInscTime(name, value)}
                />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
                <SttFormDate
                    caption={cxt.t('sc.class.main.signupEnd')}
                    name="inscEnd"
                    defVal={activity.inscEnd}
                    onChange={({ name, value }) => setInscDate(name, value)}
                />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
                <SttFormTime
                    name="inscEnd"
                    defVal={moment(activity.inscEnd).format('HH:mm')}
                    min="00:00:00"
                    max="23:59:00"
                    interval={15}
                    onChange={({ name, value }) => setInscTime(name, value)}
                    timeRangeOptions={{ includeMaxTime: true }}
                />
            </Grid>
            <Grid item xs={12} md={12} xl={4}>
                <SttFormPeriod
                    captionIni={cxt.t('DurationFrom')}
                    nameIni="ini"
                    defValIni={activity.ini}
                    nameEnd="end"
                    defValEnd={activity.end}
                    onChange={setProperty}
                />
            </Grid>
            <Grid item xs={12}>
                <SttFormCheck
                    name="showSignedUsers"
                    caption={cxt.t('page.acitvity.showSignedUsers')}
                    checked={activity.showSignedUsers}
                    onChange={setProperty}
                />
            </Grid>
            {/* Desactivado componente hasta que se realicen los endpoints correspondientes. */}
            {activity.id && (
                <Grid item xs={12}>
                    <SttTagsList activity={activity} />
                </Grid>
            )}
            {!!activity.id && !activity.trash && (
                <>
                    <Grid item xs={12} md={4}>
                        <SttButton
                            fullWidth
                            caption={cxt.t('Duplicate')}
                            icon={<CameraAltOutlined fontSize="small" />}
                            onClick={duplicate}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SttButton
                            fullWidth
                            caption={cxt.t('Assistences')}
                            icon={<CheckCircleOutline fontSize="small" />}
                            onClick={() => setDialog(true)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SttButton
                            fullWidth
                            caption={cxt.t('GoToActivity')}
                            icon={<OpenInNewOutlined fontSize="small" />}
                            onClick={() =>
                                history.push(
                                    getPath('activityPublic', { id: item.id }),
                                )
                            }
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {!activity.trash && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SttButtonSave
                                loading={saveLoading}
                                fullWidth
                                onClick={saveActivity}
                            />
                        </Grid>
                    )}
                    {!!activity.id && !activity.trash && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SttButtonDelete
                                loading={deleteLoading}
                                fullWidth
                                onClick={deleteActivity}
                            />
                        </Grid>
                    )}
                    {!!activity.trash && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <SttButtonRecover
                                fullWidth
                                onClick={recoverActivity}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <AttendancesDialog
                activity={item}
                open={dialog}
                onClose={() => setDialog(false)}
            />
        </Grid>
    );
}
