import React, { useContext, useEffect, useState } from 'react';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import { PersonOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import { AppContext } from '../AppContext';
import { SttDialog, SttFormText, SttFormDate, SttFormSelect } from '../all';
import { subsetObject, formatPriceByLocale } from '../../lib/utils';
import Mship from '../../pages/sc/mships/Mship';

export default function SttMshipDialog({ open, mshipId, onClose, onSave }) {
    const cxt = useContext(AppContext);
    const [mship, setMship] = useState();
    const [fullProfileId, setFullProfileId] = useState(null);

    function loadMship(id) {
        cxt.api('GET', `/mships/${id}`, {
            success: (r) => {
                setMship(r.mship);
            },
        });
    }

    useEffect(() => {
        if (mshipId) {
            loadMship(mshipId);
        }
    }, [mshipId]);

    function handleChange({ name, value }) {
        setMship((prev) => ({ ...prev, [name]: value }));
    }

    function saveMship() {
        const params = subsetObject(
            mship,
            'name,address,dni,email,mobile,birthday,gender,iban',
        );

        cxt.api('PUT', `/mships/${mship.id}`, {
            params: {
                ...params,
            },
            success: (response) => {
                onClose();
                onSave?.(response.mship);
            },
        });
    }

    function openFullMship(id) {
        setFullProfileId(id);
    }

    // -----| Render |-----
    return (
        <>
            <SttDialog
                title={mship ? `@${mship.user.login} - ${mship.name}` : ''}
                open={open}
                onClose={onClose}
                content={
                    mship && (
                        <>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            {cxt.t('Created')}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {cxt.t('Purse')}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {cxt.t('Total')}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {cxt.t('Debt')}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="subtitle2"
                                                gutterBottom
                                            >
                                                {moment(mship.created).format(
                                                    'D MMM YYYY',
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="subtitle2"
                                                gutterBottom
                                            >
                                                {formatPriceByLocale(
                                                    mship.amount,
                                                    mship?.amountShape
                                                        ?.currency,
                                                    mship?.amountShape?.locale,
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="subtitle2"
                                                gutterBottom
                                            >
                                                {formatPriceByLocale(
                                                    mship.total,
                                                    mship?.totalShape?.currency,
                                                    mship?.totalShape?.locale,
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography
                                                variant="subtitle2"
                                                gutterBottom
                                            >
                                                {formatPriceByLocale(
                                                    mship.debt,
                                                    mship?.debtShape?.currency,
                                                    mship?.debtShape?.locale,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <Paper>
                                <Box p={2} mt={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <SttFormText
                                                name="name"
                                                caption={cxt.t('Name')}
                                                defVal={mship.name}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SttFormText
                                                name="address"
                                                caption={cxt.t('Address')}
                                                defVal={mship.address}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SttFormText
                                                name="dni"
                                                caption={cxt.t('DNI')}
                                                defVal={mship.dni}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SttFormText
                                                name="email"
                                                caption={cxt.t('Email')}
                                                defVal={mship.email}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SttFormText
                                                name="mobile"
                                                caption={cxt.t('Mobile')}
                                                defVal={mship.mobile}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SttFormDate
                                                name="birthday"
                                                caption={cxt.t('Birthday')}
                                                defVal={mship.birthday}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SttFormSelect
                                                name="gender"
                                                caption={cxt.t('Gender')}
                                                defVal={mship.gender}
                                                options={[
                                                    {
                                                        caption: cxt.t(`Male`),
                                                        value: 'MALE',
                                                    },
                                                    {
                                                        caption:
                                                            cxt.t(`Female`),
                                                        value: 'FEMALE',
                                                    },
                                                ]}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SttFormText
                                                name="iban"
                                                caption={cxt.t('IBAN')}
                                                defVal={mship.iban}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </>
                    )
                }
                buttons={[
                    {
                        type: 'save',
                        onClick: saveMship,
                    },
                    {
                        icon: <PersonOutlineOutlined />,
                        onClick: () => {
                            if (mship) {
                                openFullMship(mship.id);
                            }
                        },
                        caption: cxt.t('Profile'),
                    },
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />
            {fullProfileId && (
                <Mship
                    open={!!fullProfileId}
                    id={fullProfileId}
                    onClose={() => {
                        loadMship(fullProfileId);
                        setFullProfileId(null);
                    }}
                />
            )}
        </>
    );
}
