import React, { useState, useContext } from 'react';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import {
    AppContext,
    SttTopControls,
    SttTable,
    SttChipGroup,
    SttButtonFab,
} from '../../../sporttia/all';
import CreateGroupDialog from './CreateGroupDialog';
import {
    useCrud,
    usePageHeader,
    useToggle,
    useTuples,
} from '../../../lib/hooks';
import Group from './Group';
import { copyToClipboard } from '../../../lib/utils';
import DiscountTooltip from '../../../components/discounts/DiscountTooltip';

// Users & Groups screen
export default function Groups() {
    usePageHeader('GroupsAndAbonados');
    const cxt = useContext(AppContext);

    const [groups, setGroups, , Get] = useCrud([]);
    const [group, setGroup] = useState({ id: null, trash: false });
    const [filters, setFilter] = useTuples({
        name: '',
        year: moment().get('year'),
        inTime: true,
        types: 'NORMAL,ABONADOS,FAMILY',
    });

    const [dialog, setDialog] = useState(false);

    const [trash, toggleTrash] = useToggle(() => {
        // eslint-disable-next-line no-use-before-define
        loadGroups();
    });

    /**
     * Load groups
     */
    function loadGroups(params = { rows: 20, page: 1 }) {
        const { inTime, ...restFilters } = filters;
        const formatedFilters = inTime ? filters : restFilters;

        Get(`/scs/${cxt.sc.id}/groups`, {
            ...params,
            ...formatedFilters,
            trash: !!trash,
            includeChildren: true,
        }).then((response) => {
            if (response.rows) {
                setGroups(response);
            }
        });
    }

    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        type: 'text',
                        caption: cxt.t('Name'),
                        name: 'name',
                        value: filters.name,
                        onEnterKey: () => loadGroups(),
                    },
                ]}
                extraFields={[
                    {
                        type: 'year',
                        name: 'year',
                        caption: cxt.t('Year'),
                        value: filters.year,
                        startYear: moment().year(),
                        pastYears: 3,
                    },
                    {
                        type: 'switch',
                        name: 'inTime',
                        caption: cxt.t('InForce'),
                        checked: filters.inTime,
                    },
                    {
                        caption: cxt.t('Type'),
                        name: 'types',
                        type: 'selectMulti',
                        value: filters.types,
                        xs: 12,
                        sm: 6,
                        options: [
                            { label: cxt.t('GT.NORMAL'), value: 'NORMAL' },
                            { label: cxt.t('GT.SPORT'), value: 'SPORT' },
                            { label: cxt.t('GT.ABONADOS'), value: 'ABONADOS' },
                            { label: cxt.t('GT.FAMILY'), value: 'FAMILY' },
                        ],
                    },
                ]}
                menuIcon={<CloudDownloadIcon />}
                onChange={setFilter}
                onFilter={() => loadGroups()}
                // TODO: enable trash switch when API supports querying deleted groups (and its users etc)
                onToggleTrash={toggleTrash}
            />

            {/* --- LIST --- */}
            <SttTable
                childrenEnabled
                data={groups}
                loading={false}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Name'),
                        type: 'link',
                        field: 'name',
                        align: 'left',
                        width: 300,
                        to: '',
                        onClick: (row) =>
                            setGroup({ id: row.id, trash: row.trash }),
                    },
                    {
                        title: cxt.t('Type'),
                        width: 125,
                        value: (row) => <SttChipGroup type={row.type} />,
                    },
                    {
                        title: cxt.t('Created'),
                        type: 'dmy',
                        field: 'created',
                    },
                    {
                        title: cxt.t('Fee'),
                        type: 'enrollment',
                        value: (row) => ({
                            enrollment: row.enrollmentPrice,
                            fee: row.feePrice,
                            interval: row.feeInterval,
                        }),
                    },
                    {
                        title: '',
                        value: (row) =>
                            row.discounts && (
                                <DiscountTooltip discounts={row.discounts} />
                            ),
                    },
                    {
                        title: cxt.t('Period'),
                        type: 'period',
                        value: (row) => ({ ini: row.ini, end: row.end }),
                    },
                    {
                        title: cxt.t('Online'),
                        type: 'active',
                        field: 'purchaseOnline',
                    },
                    {
                        title: cxt.t('Active'),
                        field: 'numActiveUsers',
                    },
                    {
                        title: cxt.t('Max'),
                        field: 'maxMembers',
                        type: 'nonZero',
                    },
                ]}
                onFetch={loadGroups}
            />
            {group.id && (
                <Group
                    id={group.id}
                    trash={group.trash}
                    onChange={() => loadGroups()}
                    onClose={() => setGroup({ id: null, trash: false })}
                />
            )}
            <CreateGroupDialog
                open={dialog}
                onSave={() => loadGroups()}
                onClose={() => setDialog(false)}
            />
            <SttButtonFab onClick={() => setDialog(true)} />
        </Paper>
    );
}
