import React, { useState, useContext, useCallback } from 'react';
import {
    Box,
    Grid,
    Divider,
    Typography,
    makeStyles,
    Hidden,
    Link,
} from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
    AppContext,
    SttDialog,
    SttFormCheck,
    SttFormText,
    SttSelectPForm,
} from '../../../sporttia/all';
import {
    generateFormField,
    fullName,
    formatPriceByLocale,
    composeFormData,
    getErrorMessage,
} from '../../../lib/utils';
import { SttSelectOrAddUser } from '../../../sporttia/selectors/SttSelectOrAddUser';
import { getPath } from '../../Pages';
import { useInteractionsFiles } from '../../../lib/hooks';
import translations from '../../../translations';
import constants from '../../../config/constants';
import useActivitiesService from '../../../services/ActivitiesService';
import SttFullPageSpinner from '../../../sporttia/spinners/SttFullPageSpinner';

const useStyles = makeStyles(() => ({
    nonFree: {
        color: '#000',
    },
    free: {
        color: '#3c763d',
    },
    mandatory: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'right',
        color: 'red',
        marginTop: 8,
    },
    link: {
        display: 'flex',
        alignItems: 'center',
    },
}));

// Hay labels asociados a campos de formulario que son demasiado largos para que sean outlined
// 200204 -> Campo indicando enfermedades, alergias, antecedentes medicos, etc...
const formIDs = [200204];

export default function ModalSignUp({
    rate,
    form,
    privileges,
    onClose,
    paymentForms,
    idSC,
    idMship,
}) {
    const cxt = useContext(AppContext);
    const { openFile } = useInteractionsFiles();
    const history = useHistory();
    const classes = useStyles();

    const [user, setUser] = useState({
        id: cxt.user.role === 'SPORTCENTER' ? 0 : cxt.user.id,
        name: cxt.user.role === 'SPORTCENTER' ? '' : fullName(cxt.user),
    });

    const [enrollmentPForm, setEnrollmentPForm] = useState(
        (() => {
            if (rate.paymentForms?.[0]) {
                return rate.paymentForms[0];
            }
            if (paymentForms?.[0]) {
                return paymentForms[0];
            }
            return [];
        })(),
    );
    const [feesToReceipts, setFeesToReceipts] = useState(false);
    const [formValues, setFormValues] = useState(form);

    function clearForm() {
        setFormValues(
            formValues?.map(({ answer, ...restQuestion }) => restQuestion),
        );
    }

    const queryClient = useQueryClient();

    const activitiesService = useActivitiesService();
    const getActivityRateInscriptionsQuery =
        activitiesService.useGetActivityRateInscriptions(
            rate.id,
            {},
            {
                select: useCallback((data) => data.rows, []),
                onError: (error) => {
                    cxt.showMessage('E', getErrorMessage(error));
                    onClose();
                },
            },
        );
    const createActivityRateStudentMutation =
        activitiesService.useCreateActivityRateStudent({
            onSuccess: (response) => {
                if (response.tpv) {
                    // Redirect to the tpv container
                    history.push(
                        getPath('tpvSwitcher', { id: response.tpv.id }),
                    );
                } else if (response.student) {
                    cxt.showMessage('S', cxt.t('page.activity.inscriptionOk'));

                    queryClient.setQueryData(
                        getActivityRateInscriptionsQuery.queryKey,
                        (old) => ({
                            ...old,
                            rows: [...old.rows, response.student],
                        }),
                    );

                    if (!user.id) {
                        setUser({
                            ...user,
                            name: '',
                        });
                    }
                }

                clearForm();
            },
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        });

    function getTicket(inscription) {
        openFile(`/students/${inscription.id}.pdf`, 'application/pdf');
    }

    function handleFormFieldChange({ name, value }) {
        setFormValues(
            formValues.map((question) =>
                question.id === parseInt(name)
                    ? { ...question, answer: { value } }
                    : question,
            ),
        );
    }

    const inscribeOtherLink = (
        <Link onClick={() => setUser({ id: null, name: '' })}>
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t('page.activity.signUpAnotherPerson')}</span>
            </Typography>
        </Link>
    );

    // 'inscribirme a mi'
    const inscribeMeLink = (
        <Link
            onClick={() =>
                setUser({ id: cxt.user.id, name: fullName(cxt.user) })
            }
        >
            <Typography variant="body2" className={classes.link}>
                <Loop style={{ fontSize: 16 }} />
                <span>&nbsp;</span>
                <span>{cxt.t(translations.generic.signMeUp)}</span>
            </Typography>
        </Link>
    );

    if (
        getActivityRateInscriptionsQuery.isLoading ||
        createActivityRateStudentMutation.isLoading
    ) {
        return <SttFullPageSpinner />;
    }

    if (!getActivityRateInscriptionsQuery.isSuccess || !rate) {
        return null;
    }

    return (
        <SttDialog
            open
            onClose={onClose}
            title={cxt.t(translations.generic.inscription)}
            maxWidth={form ? 'md' : 'sm'}
            content={
                <Grid container spacing={3}>
                    <Grid item xs={12} md={form ? 6 : 12}>
                        <Typography variant="body2">
                            {cxt.t('page.activity.youAreGoingToSignUp')}
                        </Typography>

                        <Box
                            mt={3}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <div>
                                <Typography variant="caption">
                                    {cxt.t(translations.generic.rate)}
                                </Typography>
                                <Typography variant="body1">
                                    {rate.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="caption">
                                    {cxt.t(translations.generic.inscription)}
                                </Typography>
                                {rate.enrollment ? (
                                    <Box className={classes.nonFree}>
                                        <Typography variant="body1">
                                            {formatPriceByLocale(
                                                rate.enrollment,
                                                rate.enrollmentShape?.currency,
                                                rate.enrollmentShape?.locale,
                                            )}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box className={classes.free}>
                                        <Typography variant="body1">
                                            {cxt.t(translations.generic.free)}
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                            <div>
                                <Typography variant="caption">
                                    {cxt.t(translations.generic.fee)}
                                </Typography>
                                <Typography variant="body1">
                                    {rate.fee ? (
                                        <span
                                            className={classes.nonFree}
                                        >{`${formatPriceByLocale(
                                            rate.fee,
                                            rate.feeShape?.currency,
                                            rate.feeShape?.locale,
                                        )} / ${rate.periodLang}`}</span>
                                    ) : (
                                        <span className={classes.free}>
                                            {cxt.t(translations.generic.noFees)}
                                        </span>
                                    )}
                                </Typography>
                            </div>
                        </Box>

                        {getActivityRateInscriptionsQuery.data.length > 0 && (
                            <>
                                <Box my={3}>
                                    <Divider />
                                </Box>
                                <Box mb={3}>
                                    <Box
                                        mb={1}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body2">
                                            <b>
                                                {cxt.t(
                                                    'page.activity.signedUpsByYou',
                                                )}
                                                :
                                            </b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {getActivityRateInscriptionsQuery.data.map(
                                            (inscription) => (
                                                <Box
                                                    key={inscription.id}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <span>
                                                        {inscription.user
                                                            ?.fullName ||
                                                            inscription.name}
                                                    </span>
                                                    <span>&nbsp;</span>
                                                    <span>
                                                        (
                                                        <Link
                                                            onClick={() =>
                                                                getTicket(
                                                                    inscription,
                                                                )
                                                            }
                                                        >
                                                            {cxt.t(
                                                                translations
                                                                    .generic
                                                                    .ticket,
                                                            )}
                                                        </Link>
                                                        )
                                                    </span>
                                                </Box>
                                            ),
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}

                        <Box mb={3}>
                            <>
                                <Box my={3}>
                                    <Divider />
                                </Box>
                                <Box
                                    mb={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">
                                        <b>
                                            {cxt.t(
                                                'page.activity.personToSignUp',
                                            )}
                                            :
                                        </b>
                                    </Typography>
                                    {cxt.user.role !==
                                        constants.roles.sportcenter && (
                                        <Hidden xsDown>
                                            {user.id
                                                ? inscribeOtherLink
                                                : inscribeMeLink}
                                        </Hidden>
                                    )}
                                </Box>
                                {!user.id ||
                                cxt.user.role ===
                                    constants.roles.sportcenter ? (
                                    <SttSelectOrAddUser
                                        idSC={idSC}
                                        name="user"
                                        defVal={user}
                                        onChange={setUser}
                                        onEnterKey={() => {}}
                                        idMship={idMship}
                                    />
                                ) : (
                                    <SttFormText
                                        disabled
                                        name="person"
                                        defVal={user.fullName ?? user.name}
                                    />
                                )}
                                {cxt.user.role !==
                                    constants.roles.sportcenter && (
                                    <Hidden smUp>
                                        <Box mt={1}>
                                            {user.id
                                                ? inscribeOtherLink
                                                : inscribeMeLink}
                                        </Box>
                                    </Hidden>
                                )}
                            </>
                        </Box>

                        {rate.enrollment > 0 && (
                            <Box mb={3}>
                                <Box
                                    mb={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">
                                        <b>
                                            {cxt.t(
                                                translations.payment
                                                    .paymentForm,
                                            )}
                                        </b>
                                        <span>
                                            &nbsp;(
                                            {`${cxt.t(
                                                'page.activity.signUpPayment',
                                            )},
									${formatPriceByLocale(
                                        rate.enrollment,
                                        rate.enrollmentShape?.currency,
                                        rate.enrollmentShape?.locale,
                                    )}`}
                                            )
                                        </span>
                                    </Typography>
                                </Box>
                                <SttSelectPForm
                                    caption=""
                                    defVal={enrollmentPForm}
                                    activePFs={
                                        rate.paymentForms || paymentForms
                                    }
                                    onChange={({ value }) =>
                                        setEnrollmentPForm(value)
                                    }
                                />
                            </Box>
                        )}

                        {rate.fee > 0 && privileges?.includes(25) && (
                            <Box mb={3}>
                                <SttFormCheck
                                    caption={cxt.t('page.activity.toReceipt')}
                                    name="fees"
                                    checked={feesToReceipts}
                                    onChange={({ value }) =>
                                        setFeesToReceipts(value)
                                    }
                                />
                            </Box>
                        )}
                    </Grid>

                    {form && form.length > 0 && (
                        <Grid item xs={12} md={6}>
                            <Box
                                mb={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography variant="body2">
                                    <b>{cxt.t('page.homeA.ev.2')}:</b>
                                </Typography>
                            </Box>

                            {formValues.map((item) => (
                                <Box mb={3} key={item.it}>
                                    <>
                                        {generateFormField(
                                            item,
                                            handleFormFieldChange,
                                            !!formIDs.includes(item.id),
                                        )}
                                        {!!item.mandatory && (
                                            <span
                                                className={classes.mandatory}
                                            >{`(${cxt.t(
                                                translations.generic.mandatory,
                                            )})`}</span>
                                        )}
                                    </>
                                </Box>
                            ))}
                        </Grid>
                    )}
                </Grid>
            }
            buttons={[
                {
                    show: cxt.user.role !== 'SPORTCENTER',
                    type: 'accept',
                    onClick: () => {
                        createActivityRateStudentMutation.mutate({
                            idRate: rate.id,
                            params: {
                                id: rate.id,
                                idUser: user.id,
                                name: user.name,
                                surname1: user.surname1,
                                surname2: user.surname2,
                                enrollmentPaymentForm: enrollmentPForm,
                                feePaymentForm: feesToReceipts
                                    ? constants.payment.paymentForms.receipt
                                          .name
                                    : '',
                                form:
                                    form && form.length > 0
                                        ? composeFormData(formValues)
                                        : null,
                            },
                        });
                    },
                },
            ]}
        />
    );
}
