import {
    MutationFunction,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from 'react-query';
import { useContext } from 'react';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import {
    CreateBonoUser200,
    CreateBonoUserBody,
    GetBono200,
    GetBonoDevicesAuths200,
    GetBonoDevicesAuthsParams,
    GetBonoParams,
    UpdateBonoBoletos200,
} from '../types/api/paths/Bono';

const resource = '/bonos';

export const getGetBonoQueryKey = (id: number, params?: GetBonoParams) => [
    `${resource}/${id}`,
    ...(params ? [params] : []),
];

export const getGetBonoDevicesAuthsQueryKey = (
    id: number,
    params?: GetBonoDevicesAuthsParams,
) => [`${resource}/${id}/devices/auths`, ...(params ? [params] : [])];

/**
 * Hook to access 'bonos' resources.
 */
export default function useBonosService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);

    /**
     * GET /bonos/:id request.
     */
    function useGetBono<TData = GetBono200>(
        id: number,
        params?: GetBonoParams,
        queryOptions?: UseQueryOptions<GetBono200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ?? getGetBonoQueryKey(id, params);

        const queryFn = () =>
            request<GetBono200>('GET', `${resource}/${id}`, params);

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * GET /bonos/:id/devices/auths request.
     */
    function useGetBonoDevicesAuths<TData = GetBonoDevicesAuths200>(
        id: number,
        params?: GetBonoDevicesAuthsParams,
        queryOptions?: UseQueryOptions<GetBonoDevicesAuths200, unknown, TData>,
    ) {
        const queryKey =
            queryOptions?.queryKey ??
            getGetBonoDevicesAuthsQueryKey(id, params);

        const queryFn = () =>
            request<GetBonoDevicesAuths200>(
                'GET',
                `${resource}/${id}/devices/auths`,
                params,
            );

        const query = useQuery(queryKey, queryFn, queryOptions);

        return {
            ...query,
            queryKey,
        };
    }

    /**
     * PUT /bonos/boletos/:id request.
     */
    function useUpdateBonoBoleto(
        mutationOptions?: UseMutationOptions<
            UpdateBonoBoletos200,
            unknown,
            { id: number; params: UpdateBonoBoletos200 }
        >,
    ) {
        const mutationFn: MutationFunction<
            UpdateBonoBoletos200,
            { id: number; params: UpdateBonoBoletos200 }
        > = ({ id, params }) =>
            request('PUT', `${resource}/boletos/${id}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    /**
     * POST /bonos/:bonoId/users/:userId request.
     */
    function useCreateBonoUser(
        mutationOptions?: UseMutationOptions<
            CreateBonoUser200,
            unknown,
            { bonoId: number; userId: number; params: CreateBonoUserBody }
        >,
    ) {
        const mutationFn: MutationFunction<
            CreateBonoUser200,
            { bonoId: number; userId: number; params: CreateBonoUserBody }
        > = ({ bonoId, userId, params }) =>
            request('POST', `${resource}/${bonoId}/users/${userId}`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        useGetBono,
        useGetBonoDevicesAuths,
        useUpdateBonoBoleto,
        useCreateBonoUser,
    };
}
