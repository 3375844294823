import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import {
    AppContext,
    SttTable,
    SttLabelFee,
    SttChipPForm,
    SttLabelDate,
    SttTopControls,
} from '../../../sporttia/all';
import StudentDialog from '../../../components/dialogs/StudentDialog';
import { formatPriceByLocale } from '../../../lib/utils';

export default function MshipStudents({ mship }) {
    const cxt = useContext(AppContext);
    const [students, setStudents] = useState({});
    const [studentDialogId, setStudentDialogId] = useState(null);
    const [filter, setFilter] = useState({
        actName: '',
        onTime: 'Y',
    });

    const loadStudents = (p = { page: 1, numRows: 20 }) => {
        cxt.api('GET', `/mships/${mship.id}/students`, {
            params: {
                page: p.page,
                rows: p.numRows,
                actName: filter.actName,
                onTime: filter.onTime === 'Y',
            },
            success: (r) => {
                setStudents(r);
            },
        });
    };

    useEffect(() => {
        loadStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.onTime]);

    const handleFormSearch = ({ name, value }) => {
        setFilter({ ...filter, [name]: value });
    };

    return (
        <>
            <SttTopControls
                mb={2}
                fields={[
                    {
                        type: 'text',
                        name: 'actName',
                        caption: cxt.t('Activity'),
                        value: filter.actName,
                        onChange: handleFormSearch,
                        onEnterKey: loadStudents,
                    },
                    {
                        type: 'select',
                        name: 'onTime',
                        caption: cxt.t('Status'),
                        value: filter.onTime,
                        onChange: handleFormSearch,
                        options: [
                            { caption: cxt.t('All'), value: 'N' },
                            { caption: cxt.t('Active'), value: 'Y' },
                        ],
                    },
                ]}
                onFilter={loadStudents}
            />

            <SttTable
                data={students}
                autoload={false}
                onClickRow={(student) => setStudentDialogId(student.id)}
                columns={[
                    {
                        field: 'classRate',
                        align: 'left',
                        title: `${cxt.t('Activity')} / ${cxt.t('Rate')}`,
                        value: (r) => (
                            <>
                                <Box>
                                    <b>{r.rate.activity.name}</b>
                                </Box>
                                <Box>{r.rate.name}</Box>
                            </>
                        ),
                    },
                    {
                        field: 'period',
                        type: 'period',
                        title: cxt.t('Period'),
                        value: (r) => ({
                            ini: r.ini,
                            end: r.end,
                        }),
                    },
                    {
                        field: 'inscription',
                        title: cxt.t('Inscription'),
                        value: (r) => (
                            <>
                                <SttLabelDate date={r.created} />
                                <br />
                                {r.enrollment && r.enrollment.price
                                    ? `${formatPriceByLocale(
                                          r.enrollment.price,
                                          cxt.sc?.city?.country?.currency,
                                      )}`
                                    : null}
                            </>
                        ),
                    },
                    {
                        field: 'fee',
                        title: cxt.t('Fee'),
                        value: (r) => (
                            <>
                                <SttLabelFee
                                    interval={r.rate.period}
                                    price={r.rate.fee}
                                />
                                {r.rate.fee ? (
                                    <SttChipPForm pf={r.feePaymentForm} />
                                ) : null}
                            </>
                        ),
                    },
                ]}
                onFetch={loadStudents}
            />

            {studentDialogId && (
                <StudentDialog
                    open
                    idStudent={studentDialogId}
                    onClose={() => {
                        setStudentDialogId(null);
                    }}
                    onSave={loadStudents}
                />
            )}
        </>
    );
}
