import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
    Grid,
    Box,
    IconButton,
    Button,
    LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import {
    AppContext,
    SttDialog,
    SttFormSelect,
    SttFormTextarea,
    SttFormText,
    SttFormCheck,
} from '../../../sporttia/all';
import {
    deleteElementFromArray,
    updateElementInArray,
} from '../../../lib/utils';
import timeImg from '../../../images/time.svg';

export default function ImportUsersDialog({
    open = false,
    file,
    lines,
    onClose,
    onUpdate,
}) {
    const cxt = useContext(AppContext);
    const [importing, setImporting] = useState(false);
    const [notes, setNotes] = useState();
    const [numUsersToImport, setNumUsersToImport] = useState(1);
    const [modifyExisting, setModifyExisting] = useState(false);

    /**
     *	1 => login
     *
     *	menas that the column 1 of the list goes as "login" of user
     */
    const [conversions, setConversions] = useState([
        /*
		{
			origin: 1,
			destine: 'login'
		}
		*/
    ]);

    const fieldNumbers = [...Array(cxt.constants.numFieldsToUserImport).keys()];
    const fieldConversions = [
        { value: 'login', caption: 'Login' },
        { value: 'name', caption: cxt.t('Name') },
        { value: 'surname1', caption: `${cxt.t('Surname')} 1` },
        { value: 'surname2', caption: `${cxt.t('Surname')} 2` },
        { value: 'password', caption: cxt.t('Password') },
        { value: 'dni', caption: cxt.t('DNI') },
        { value: 'address', caption: cxt.t('Address') },
        { value: 'phone', caption: cxt.t('Mobile') },
        { value: 'email', caption: cxt.t('Email') },
        { value: 'iban', caption: cxt.t('IBAN') },
        { value: 'idCity', caption: `${cxt.t('City')} (id)` },
        { value: 'idGroup', caption: `${cxt.t('Group')} (id)` },
        { value: 'purseAmount', caption: cxt.t('purse') },
    ];

    /**
     * Do import
     */
    const doImport = () => {
        setImporting(true);
        cxt.api('POST', `/files/csvs/${file.id}/users`, {
            params: {
                conversions: conversions.map((conv) => ({
                    origin: conv.origin == 'B' ? 0 : conv.origin,
                    destine: conv.destine,
                })),
                notes,
                numUsersToImport,
                modifyExisting,
            },
            success: (r) => {
                cxt.showMessage('S', cxt.t('page.import.success'));
                onClose();
                onUpdate(r.fileCSV);
                setImporting(false);
            },
        });
    };

    /**
     * Add conversion
     */
    const addConversion = () => {
        setConversions([
            ...conversions,
            {
                id: conversions.length ? conversions.length : 'B',
                origin: conversions.length ? conversions.length : 'B',
                destine: fieldConversions[0].value,
            },
        ]);
    };

    /**
     * Remove conversion
     */
    const removeConversion = (conv) => {
        setConversions(deleteElementFromArray(conversions, conv));
    };

    /**
     * Update conversion
     */
    const updateConversion = (conversion) => {
        setConversions(updateElementInArray(conversions, conversion));
    };

    /**
     * File changes
     */
    useEffect(() => {
        setNotes(
            `${file && file.name} - Import ${moment().format(
                'YYYY-MM-DD HH:mm:ss',
            )}`,
        );
    }, [file]);

    /**
     * Init
     */
    useEffect(() => {
        addConversion();
    }, []);

    // -----| Render |-----
    return (
        <SttDialog
            maxWidth="lg"
            title={`${cxt.t('ImportUsers')}: ${lines && lines.count}`}
            open={open}
            onClose={onClose}
            content={
                <>
                    {importing ? (
                        <Box p={2} align="center">
                            <Box mb={2}>
                                <img src={timeImg} width={130} />
                            </Box>
                            <LinearProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item md={7}>
                                <Box mb={4}>{cxt.t('page.import.rules')}</Box>

                                {/* --- Convertion list --- */}
                                <Grid container spacing={3}>
                                    {conversions.map((conv, idx) => (
                                        <Fragment key={idx}>
                                            <SttFormSelect
                                                grid
                                                md={4}
                                                sm={4}
                                                xs={4}
                                                caption={cxt.t('Origin')}
                                                defVal={conv.origin}
                                                onChange={({ value }) =>
                                                    updateConversion({
                                                        id: conv.id,
                                                        origin: value || 'B',
                                                        destine: conv.destine,
                                                    })
                                                }
                                                options={fieldNumbers.map(
                                                    (num) => ({
                                                        caption: `#${num}`,
                                                        value: num || 'B',
                                                    }),
                                                )}
                                            />

                                            <SttFormSelect
                                                grid
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                caption={cxt.t('Destine')}
                                                defVal={conv.destine}
                                                onChange={({ value }) =>
                                                    updateConversion({
                                                        id: conv.id,
                                                        origin: conv.origin,
                                                        destine: value,
                                                    })
                                                }
                                                options={fieldConversions}
                                            />

                                            <Grid item md={2} sm={2} xs={2}>
                                                <IconButton
                                                    onClick={() =>
                                                        removeConversion(conv)
                                                    }
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Fragment>
                                    ))}

                                    {/* --- Add new convertion --- */}
                                    <Grid item md={12}>
                                        <Button
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={() => addConversion()}
                                        >
                                            {cxt.t('New')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={5}>
                                <Grid container spacing={3}>
                                    {/* --- Notes --- */}
                                    <SttFormTextarea
                                        grid
                                        md={12}
                                        name="notes"
                                        caption={cxt.t('Notes')}
                                        defVal={notes}
                                        onChange={({ value }) =>
                                            setNotes(value)
                                        }
                                    />

                                    {/* --- Num users to iportt --- */}
                                    <SttFormText
                                        grid
                                        md={6}
                                        name="numUsersToImport"
                                        caption={cxt.t('UsersToImport')}
                                        defVal={numUsersToImport}
                                        onChange={({ value }) =>
                                            setNumUsersToImport(value)
                                        }
                                    />

                                    {/* --- Modify existing while importing --- */}
                                    <SttFormCheck
                                        grid
                                        md={12}
                                        name="modifyExisting"
                                        caption={cxt.t(
                                            'importUsers.modifyExisting',
                                        )}
                                        checked={modifyExisting}
                                        onChange={({ value }) =>
                                            setModifyExisting(value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </>
            }
            buttons={[
                {
                    show: !importing,
                    type: 'accept',
                    onClick: () => doImport(),
                },
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}
