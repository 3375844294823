import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Box, Grid, Paper, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import IconButton from '@material-ui/core/IconButton';
import { AppContext, SttFullDialog, SttFormText } from '../../../sporttia/all';
import { getErrorMessage } from '../../../lib/utils';
import BookingOperateDialog from './BookingOperateDialog';
import { getPath } from '../../Pages';
import BookingSectionMessages from './BookingSectionMessages';
import BookingSectionMainData from './BookingSectionMainData';
import BookingSectionOccupants from './BookingSectionOccupants';
import BookingSectionPrice from './BookingSectionPrice';
import POSPaymentDialog from '../../../components/dialogs/POSPaymentDialog';
import POSStripePaymentDialog from '../../../components/stripe/POSStripePaymentDialog';
import constants from '../../../config/constants';
import translations from '../../../translations';
import useBookingsService from '../../../services/BookingsService';
import { useLoader } from '../../../lib/hooks';

export default function Booking({
    open = false,
    idSC,
    column,
    piece,
    ini,
    end,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const history = useHistory();
    const [notes, setNotes] = useState();
    const [sc, setSc] = useState();
    const [ImSCOwner, setImSCOwner] = useState(false);
    const [timeini, setTimeini] = useState();
    const [timeend, setTimeend] = useState();
    const [staticBookingMsg, setStaticBookingMsg] = useState();
    const [isReadyToBook, setIsReadyToBook] = useState(false);
    const [bookingTitle, setBookingTitle] = useState('');

    // Open popups
    const [openOperate, setOpenOperate] = useState(false);

    // Fares, rates & prices
    const [rateType, setRateType] = useState(constants.rate.types.complete);
    const [finalPrice, setFinalPrice] = useState();
    const [modifiedPrice, setModifiedPrice] = useState();

    // POS (datafono)
    const [posPaymentParams, setPosPaymentParams] = useState(null);

    const [occupants, setOccupants] = useState([]);
    const [alfaOccupant, setAlfaOccupant] = useState();

    const bookingPrivilege = true;
    const fares = undefined;

    const loadSC = () => {
        cxt.api('GET', `/scs/${idSC}`, {
            success: (response) => {
                setSc(response.sc);

                // Am I an admin of the sports center ?
                setImSCOwner(
                    cxt.user.role === constants.roles.sportcenter &&
                        cxt.sc?.id === response.sc.id,
                );
            },
        });
    };

    const onClickBook = () => {
        // If price is 0 we don't show the booking operating dialog
        if (finalPrice > 0) {
            setOpenOperate(true);
        } else {
            // eslint-disable-next-line no-use-before-define
            book();
        }
    };

    const bookingsService = useBookingsService();
    const createBookingMutation = bookingsService.useCreateBooking({
        onSuccess: (response) => {
            setOpenOperate(false);

            const { tpv, booking } = response;

            if (tpv) {
                // Pay with TPV

                if (
                    tpv?.payment?.paymentForm ===
                    constants.payment.paymentForms.dataphoneConnected.name
                ) {
                    // params for the POS dialog (datáfono físico)
                    setPosPaymentParams({
                        amount: tpv.price,
                        concept: tpv.concept,
                        idTpv: tpv.id,
                        returnParams: {
                            idBooking: booking?.id,
                        },
                        sc: {
                            id: tpv?.sc?.id,
                            scName: tpv?.sc?.short,
                            entityName: tpv?.sc?.customer?.name,
                            address: tpv?.sc?.address,
                            cif: tpv?.sc?.customer?.cif,
                            phone: tpv?.sc?.phonePublic,
                        },
                    });
                } else {
                    history.push(
                        getPath('tpvSwitcher', { id: response.tpv.id }),
                    );
                }
            } else if (booking) {
                history.push(getPath('booked', { id: response.booking.id }));
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
            setStaticBookingMsg(getErrorMessage(error));
        },
    });

    /**
     * Proceed to book
     */
    const book = (p = {}) => {
        // Patch: people is traying to book pushing many times to book
        // We avoid this not allowing to push the button every X seconds
        const lastBookingTime = localStorage.getItem('lastBookingTime');
        const secondsBetweenBooking = 15;
        if (lastBookingTime === undefined) {
            localStorage.setItem('lastBookingTime', moment().toISOString());
        } else {
            const m = moment(lastBookingTime);
            if (m.add(secondsBetweenBooking, 'seconds') > moment()) {
                cxt.showMessage('S', cxt.t('booking.notAllowedToBookToSoon'));
            } else {
                localStorage.setItem('lastBookingTime', moment().toISOString());
            }
        }

        // Build occupants for API call
        const occupantsParam = occupants?.map((occupant) => ({
            idUser: occupant.user.id,
            idBoleto: occupant.selectedBoleto && occupant.selectedBoleto.id,
            rate: occupant?.selectedRate?.prices?.map((price) => ({
                id: price.id,
                duration: price.duration,
            })),
            newUser: occupant.newUser,
        }));

        setStaticBookingMsg(null);

        const individual = rateType === constants.rate.types.individual;

        createBookingMutation.mutate({
            params: {
                idFacility: column.facility.id,
                ini: timeini,
                end: timeend,
                notes,
                price: modifiedPrice || undefined,
                individual,
                paymentForm: p.paymentForm,
                idUser: alfaOccupant.user.id,
                name: bookingTitle,
                occupants: occupantsParam,
            },
        });
    };

    /**
     * Occupants change
     */
    useEffect(() => {
        const alfaOcc = occupants && occupants.length > 0 ? occupants[0] : null;
        let allOccupantsWithRateOrBoleto = true;

        setAlfaOccupant(alfaOcc);

        // Calculate final price
        if (occupants) {
            let totalPrice = 0;
            occupants.forEach((occupant) => {
                if (occupant.selectedRate) {
                    totalPrice += occupant.selectedRate.total;
                }

                // All occupants with rate or boleto ???
                if (!occupant.selectedRate && !occupant.selectedBoleto)
                    allOccupantsWithRateOrBoleto = false;
            });
            setFinalPrice(totalPrice);
        }

        // Is it ready to book ???
        setIsReadyToBook(alfaOcc?.user && allOccupantsWithRateOrBoleto);

        // Set new booking title
        setBookingTitle(occupants[0]?.name ?? '');
    }, [occupants]);

    /**
     * Init: piece or sports center id input change
     */
    useEffect(() => {
        // Load all data from sports center
        if (idSC) {
            loadSC();
        }

        // Set time ini & end
        if (piece) {
            setTimeini(ini);
            setTimeend(end);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [piece, idSC]);

    const [isMutationsLoading, mutationsLoader] = useLoader([
        createBookingMutation.status,
    ]);

    return (
        <SttFullDialog
            open={open}
            title={cxt.t(translations.generic.booking)}
            onClose={onClose}
        >
            {mutationsLoader}

            {sc && piece && (
                <Container>
                    <Box mb={5}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item md={8}>
                                <BookingSectionMessages
                                    bookingPrivilege={bookingPrivilege}
                                    sc={sc}
                                />

                                <BookingSectionMainData
                                    ImSCOwner={ImSCOwner}
                                    sc={sc}
                                    piece={piece}
                                    column={column}
                                    timeini={timeini}
                                    timeend={timeend}
                                    onChangeTimeini={setTimeini}
                                    onChangeTimeend={setTimeend}
                                    notes={notes}
                                    onChangeNotes={setNotes}
                                />

                                {/* Booking headline box. */}
                                <Box mt={3}>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <SttFormText
                                                    grid
                                                    md={11}
                                                    sm={11}
                                                    xs={11}
                                                    disabled={
                                                        cxt.logged &&
                                                        cxt.user?.role !==
                                                            constants.roles
                                                                .sportcenter
                                                    }
                                                    caption={cxt.t(
                                                        'BookingHeadline',
                                                    )}
                                                    defVal={bookingTitle}
                                                    onChange={({ value }) =>
                                                        setBookingTitle(value)
                                                    }
                                                />
                                                <Grid item md={1} sm={1} xs={1}>
                                                    <IconButton
                                                        aria-label="delete"
                                                        disabled={
                                                            cxt.logged &&
                                                            cxt.user?.role !==
                                                                constants.roles
                                                                    .sportcenter
                                                        }
                                                        onClick={() =>
                                                            setBookingTitle(
                                                                (occupants[0] &&
                                                                    occupants[0]
                                                                        .name) ||
                                                                    '',
                                                            )
                                                        }
                                                    >
                                                        <SettingsBackupRestoreIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Box>

                                <BookingSectionOccupants
                                    timeini={timeini}
                                    timeend={timeend}
                                    piece={piece}
                                    column={column}
                                    sc={sc}
                                    rateType={rateType}
                                    setRateType={setRateType}
                                    ImSCOwner={ImSCOwner}
                                    occupants={occupants}
                                    setOccupants={setOccupants}
                                />

                                {isReadyToBook ? (
                                    <>
                                        {/* --- Final price --- */}
                                        {finalPrice != null && (
                                            <Box mt={2}>
                                                <BookingSectionPrice
                                                    price={finalPrice}
                                                    sc={sc}
                                                    modify={ImSCOwner}
                                                    onChangePrice={(price) => {
                                                        if (
                                                            Number(price) ||
                                                            price === ''
                                                        ) {
                                                            setFinalPrice(
                                                                price || 0,
                                                            );
                                                            setModifiedPrice(
                                                                Number(
                                                                    price || 0,
                                                                ),
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}

                                        {/* --- Button to book --- */}
                                        <Box mt={4} mb={2}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DoneOutlineIcon />}
                                                onClick={onClickBook}
                                            >
                                                {cxt.t('Book')}
                                            </Button>
                                        </Box>
                                    </>
                                ) : (
                                    <Box mt={3}>
                                        <Alert severity="error">
                                            {cxt.t('booking.isNotReadyToBook')}
                                        </Alert>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>

                    {!isMutationsLoading && (
                        <BookingOperateDialog
                            open={openOperate}
                            staticMsg={staticBookingMsg}
                            mship={alfaOccupant && alfaOccupant.mship}
                            rateType={rateType}
                            fares={fares}
                            onClose={() => setOpenOperate(false)}
                            onBook={book}
                            sc={sc}
                            finalPrice={finalPrice}
                        />
                    )}
                </Container>
            )}

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        if (posPaymentParams.returnParams.idBooking) {
                            history.push(
                                getPath('booked', {
                                    id: posPaymentParams.returnParams.idBooking,
                                }),
                            );
                        }
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={(ticket, returnParams) => {
                        if (returnParams.idBooking) {
                            history.push(
                                getPath('booked', {
                                    id: returnParams.idBooking,
                                }),
                            );
                        }
                    }}
                />
            )}
        </SttFullDialog>
    );
}
