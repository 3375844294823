import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Box,
    Paper,
    Tabs,
    Tab,
    Grid,
    Card,
    CardMedia,
    CardActions,
    Button,
} from '@material-ui/core';
import { AppContext, SttFormSelect } from '../all';

export function SttSelectGender({
    grid = false,
    xs = 12,
    md = 3,
    caption,
    name,
    defVal,
    onChange,
    ...rest
}) {
    const cxt = useContext(AppContext);

    const content = (
        <SttFormSelect
            {...rest}
            name={name}
            defVal={defVal}
            caption={cxt.t('Gender')}
            options={[
                // {caption:cxt.t('Select'), value:''},
                { caption: cxt.t('Male'), value: 'MALE' },
                { caption: cxt.t('Female'), value: 'FEMALE' },
            ]}
            onChange={onChange}
        />
    );

    return grid ? (
        <Grid item xs={xs} md={md} {...rest}>
            {content}
        </Grid>
    ) : (
        <>{content}</>
    );
}
