import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AppContext } from '../../../../sporttia/all';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import { useLoader } from '../../../../lib/hooks';
import useScsService from '../../../../services/ScsService';
import useCustomersService from '../../../../services/CustomersService';
import AdminCustomer from '../customers/AdminCustomer';
import { getErrorMessage } from '../../../../lib/utils';

/**
 * View to create and modify the details of a sports center.
 * @param sc Sport center object.
 * @param status API Request status.
 * @param onCreate Launched when a new customer is created.
 * @param onDelete Launched when a new customer is removed.
 * @param onUpdate Launched when a new customer is updated.
 * @returns {JSX.Element}
 */
export default function AdminSCDetail({
    sc,
    status,
    onCreate,
    onDelete,
    onUpdate,
}) {
    const cxt = useContext(AppContext);
    const form = useForm();
    const scsService = useScsService();
    const customersService = useCustomersService();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const deleteMutation = useMutation(
        (params) => scsService.remove(params?.id),
        {
            onSuccess: onDelete,
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const updateMutation = useMutation(
        (params) => scsService.update(params?.id, params?.params),
        {
            onSuccess: onUpdate,
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const createMutation = useMutation(
        (params) =>
            customersService.createCustomerScs(params?.id, params?.params),
        {
            onSuccess: (data) => onCreate(data?.sc?.id),
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const recoverMutation = useMutation(
        (params) => scsService.updateScsRecover(params?.id),
        {
            onSuccess: onUpdate,
            onError: (error) => {
                cxt.showMessage('E', getErrorMessage(error));
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
        recoverMutation.status,
    ]);

    const formatAdminScDetailsMutated = (
        formatedData,
        keys,
        defaultValue = null,
    ) => {
        const formatedDataFiltered = {};
        Object.keys(formatedData).forEach((key) => {
            if (keys.includes(key)) {
                formatedDataFiltered[`${key}`] =
                    formatedData[`${key}`] !== ''
                        ? formatedData[`${key}`]
                        : defaultValue;
            }
        });
        return { ...formatedData, ...formatedDataFiltered };
    };
    /**
     * Method executed when the user clicks on save or create sport center.
     */
    const mutateSportCenter = (formData) => {
        const formatedFormData = formatAdminScDetailsMutated({ ...formData }, [
            'description',
            'notes',
            'salesPolicy',
            'web',
            'address',
            'short',
            'domain',
            'emailPrivated',
            'emailPublic',
            'phonePrivated',
            'phonePublic',
        ]);
        delete formatedFormData.id;
        delete formatedFormData.city;
        delete formatedFormData.customer;
        delete formatedFormData.tpvConf;
        delete formatedFormData.tpvConfEvent;
        formatedFormData.idTPVConf = formData?.tpvConf?.id
            ? formData?.tpvConf?.id
            : '';
        formatedFormData.idTPVConfEvent = formData?.tpvConfEvent?.id
            ? formData?.tpvConfEvent?.id
            : '';
        formatedFormData.idCity = formData?.city?.id
            ? formData?.city?.id
            : null;
        formatedFormData.idCustomer = formData?.customer?.id
            ? formData?.customer?.id
            : null;

        if (sc?.id) {
            updateMutation.mutate({ id: sc?.id, params: formatedFormData });
        } else {
            delete formatedFormData.idCustomer;
            formatedFormData.mapX = 36.914968969634145;
            formatedFormData.mapY = -6.077978769314956;
            createMutation.mutate({
                id: formData?.customer?.id,
                params: formatedFormData,
            });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (status === 'success' && sc) {
            form.reset({
                id: sc?.id,
                name: sc?.name,
                customer: sc?.customer,
                status: sc?.status,
                description: sc?.description,
                notes: sc?.notes,
                salesPolicy: sc?.salesPolicy,
                short: sc?.short,
                web: sc?.web,
                address: sc?.address,
                city: sc?.city,
                domain: sc?.domain,
                tpvConf: sc?.tpvConf,
                tpvConfEvent: sc?.tpvConfEvent,
                emailPrivated: sc?.emailPrivated,
                emailPublic: sc?.emailPublic,
                phonePrivated: sc?.phonePrivated,
                phonePublic: sc?.phonePublic,
                mobileSMS: sc?.mobileSMS,
                maxFacilities: sc?.maxFacilities,
            });
        } else {
            form.reset({
                id: null,
                name: null,
                customer: null,
                status: 'ACTIVE',
                description: null,
                notes: null,
                salesPolicy: null,
                short: null,
                web: null,
                address: null,
                city: null,
                domain: null,
                tpvConf: null,
                tpvConfEvent: null,
                emailPrivated: null,
                emailPublic: null,
                phonePrivated: null,
                phonePublic: null,
                mobileSMS: null,
                maxFacilities: null,
            });
        }
    }, [form, sc, status]);

    return (
        <>
            {loader}
            <SttValidatedForm
                form={form}
                loadingData={status === 'loading'}
                fields={[
                    {
                        type: 'textInput',
                        name: 'id',
                        caption: 'ID',
                        disabled: true,
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'name',
                        caption: cxt.t('Name'),
                        rules: {
                            required:
                                'Es obligatorio añadir nombre del centro.',
                        },
                        sm: 4,
                        xs: 12,
                    },
                    {
                        type: 'customer',
                        name: 'customer',
                        caption: cxt.t('Customer'),
                        rules: {
                            required:
                                'Es obligatorio añadir el cliente del centro.',
                        },
                        sm: 4,
                        xs: 12,
                    },
                    {
                        type: 'select',
                        name: 'status',
                        caption: cxt.t('Status'),
                        options: [
                            { caption: 'Activo', value: 'ACTIVE' },
                            { caption: 'Inactivo', value: 'INACTIVE' },
                            { caption: 'Baja', value: 'UNSUSCRIBED' },
                        ],
                        disableClearable: true,
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textarea',
                        name: 'description',
                        caption: cxt.t('Description'),
                        rows: 5,
                    },
                    {
                        type: 'textarea',
                        name: 'notes',
                        caption: cxt.t('Notes'),
                        rows: 5,
                        sm: 6,
                        xs: 12,
                    },
                    {
                        type: 'textarea',
                        name: 'salesPolicy',
                        caption: cxt.t('SalesPolicy'),
                        rows: 5,
                        sm: 6,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'short',
                        caption: cxt.t('Short'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'web',
                        caption: cxt.t('Web'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'address',
                        caption: cxt.t('Address'),
                        sm: 6,
                        xs: 12,
                    },
                    {
                        type: 'city',
                        name: 'city',
                        caption: cxt.t('City'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'domain',
                        caption: cxt.t('Domain'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'tpvConf',
                        name: 'tpvConf',
                        caption: cxt.t('tpvConf'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'tpvConf',
                        name: 'tpvConfEvent',
                        caption: cxt.t('tpvConfEvent'),
                        sm: 3,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'emailPrivated',
                        caption: cxt.t('emailPrivated'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'emailPublic',
                        caption: cxt.t('emailPublic'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'phonePrivated',
                        caption: cxt.t('phonePrivated'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'phonePublic',
                        caption: cxt.t('phonePublic'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'mobileSMS',
                        caption: cxt.t('mobileSMS'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'maxFacilities',
                        caption: cxt.t('maxFacilities'),
                        sm: 2,
                        xs: 12,
                    },
                    {
                        type: 'textInput',
                        name: 'iban',
                        caption: cxt.t('IBAN'),
                        sm: 2,
                        xs: 12,
                    },
                ]}
                buttons={[
                    {
                        type: 'save',
                        onClick: form.handleSubmit((formData) =>
                            mutateSportCenter(formData),
                        ),
                    },
                    {
                        show: !sc?.trash,
                        type: 'delete',
                        onClick: () => deleteMutation.mutate({ id: sc?.id }),
                    },
                    {
                        show: !!sc?.trash,
                        type: 'recover',
                        onClick: () => recoverMutation.mutate({ id: sc?.id }),
                    },
                    {
                        show: sc?.id,
                        caption: 'Ir al cliente',
                        onClick: () => setSelectedCustomer(sc?.customer?.id),
                    },
                ]}
            />

            <AdminCustomer
                id={selectedCustomer}
                onClose={() => {
                    setSelectedCustomer(null);
                }}
            />
        </>
    );
}
